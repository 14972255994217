<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <h2 class="nk-block-title fw-normal">Exchange Rate</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      All Exchange Rate Currencies<br />
                      <button
                        id="submitBtn"
                        class="btn btn-sm btn-primary"
                        @click="syncAPI"
                      >
                        Sync External API</button
                      >&nbsp;&nbsp;
                    </p>
                  </div>
                </div>
              </div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Available Rate Currency</h4>
                    <p>
                      The following lists all the
                      <strong class="text-primary">currencies</strong> and their
                      associated exchange rate
                    </p>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <shimmer v-if="loading" class="paddingLoader"></shimmer>
                      <p class="makeSpace" v-else-if="!loading && error">
                        {{ error }}
                      </p>

                      <p
                        class="makeSpace"
                        v-else-if="
                          !loading &&
                          (!allExchanges || allExchanges.length === 0)
                        "
                      >
                        No Currency Found
                      </p>
                      <!-- class="datatable-init table" -->
                      <table class="table table-responsive table-bordered" v-else>
                        <thead>
                          <tr class="bg bg-secondary" style="color: white">
                            <th>ID</th>
                            <th>Last Update</th>
                            <th>Base</th>
                            <th>Target</th>
                            <th>Market Rate</th>
                            <th>Markup</th>
                            <th>Payhaven Rate</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="exchange in allExchanges"
                            :key="exchange.id"
                          >
                            <td>{{ exchange.id }}</td>
                            <td>{{ exchange.time_next_update_utc }}</td>
                            <td>{{ exchange.base_currency }}</td>
                            <td>{{ exchange.target_currency }}</td>
                            <td>{{ exchange.market_rate }}</td>
                            <td>{{ exchange.additional_rate }}</td>
                            <td>{{ exchange.total }}</td>
                            <td>
                              <span
                                :class="
                                  exchange.status == 'active'
                                    ? 'btn btn-xs btn-primary'
                                    : 'btn btn-xs btn-danger'
                                "
                                >{{
                                  exchange.status == "active"
                                    ? "Active"
                                    : "Inactive"
                                }}</span
                              >
                            </td>

                            <td>
                              <button>
                                <router-link
                                  :to="{
                                    name: 'exchange_areas',
                                    params: { id: exchange.rand_id },
                                  }"
                                >
                                  <i
                                    title="Edit"
                                    aria-placeholder="Edit"
                                    class="fa fa-edit"
                                    style="cursor: pointer"
                                  ></i
                                ></router-link></button
                              >&nbsp;&nbsp;
                              <button
                                @click="
                                  syncDelete(exchange.rand_id, exchange.rand_id)
                                "
                              >
                                <i
                                  class="fa fa-trash"
                                  title="Delete"
                                  style="cursor: pointer"
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      allExchanges: [],
      searchCurrencies: "",
      error: null,
    };
  },

  methods: {
    getExchangeRate() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "get_exchange_rates", this.$config)
        .then(({ data }) => {
          this.loading = false;
          this.allExchanges = this.$globeFunc.sortArray(data.data);
        })
        .catch((error) => {
         // console.log(error.response.data.message);
          if(error.response.data.message == "noaccess"){
            this.$swal(error.response.data.data);
            this.$router.push({ name: "dashboard" });
          }else{
            this.loading = false;
             this.error =
            "Failed to fetch data, please try again or check your internet";
           this.$swal(error);
          }
         // console.log(error.response.data.message);
        
        });
    },

    syncAPI() {
      this.$systoken.processing(
        "Syncing Data, Please wait...",
        "submitBtn",
        true
      );
      this.$http
        .get(this.$appUrl + "get_currency_exchange", this.$config)
        .then(({ data }) => {
          this.loading = false;
          if (data) {
            this.$systoken.processing("Sync External API", "submitBtn", false);
            this.$swal("API Successfully Syned");
            this.$router.push({ name: "exchange_area" });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Failed to fetch data, please try again or check your internet";
          //this.$swal(error);
        });
    },

    syncDelete(rand_id, status) {
      this.$http
        .post(
          this.$appUrl + "delete_currency",
          {
            rand_id: rand_id,
            status: status,
          },
          this.$config
        )
        .then((response) => {
          if (response) {
            this.$swal("Updated");
            this.$router.push({ name: "exchange_area" });
          }
        })
        .then((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    this.getExchangeRate();
  },
};
</script>

<style scoped>
</style>