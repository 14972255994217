import axios from 'axios'
import initialLinks from '../../../constant';

export default {
   
  login(context, payload, config) {
        // axios.get("http://localhost:8000/sanctum/csrf-cookie")
        // .then((response) => {
        return new Promise((resolve, reject) => {
          axios.post(`${initialLinks[0].sourceLink}` + "auth/login", payload, config)
            .then((response) => {
                if (response.data.status == 200) {

                    localStorage.setItem('AuthToken', response.data.token);
                    localStorage.setItem('user', JSON.stringify(response.data.user));

                    context.commit('setUser', {
                        user: response.data.user,
                        token: response.data.token
                      });
                      resolve(response);
                }
            })
            .catch((err) => {
                const errors = err.response.data.error
                reject(errors);
                //throw errors;
            })
        });
    },



    register(context, payload, config) {
        // axios.get("http://localhost:8000/sanctum/csrf-cookie")
        // .then((response) => {
        return new Promise((resolve, reject) => {
          axios.post(`${initialLinks[0].sourceLink}`+"signup", payload, config)
            .then((response) => {
                if (response.data.status == 200) {

                    context.commit('regUser', {
                        user: response.data.email
                      });
                      resolve(response);
                }
            })
            .catch((err) => {
                //const errors = err.response.data.error
                const errors = err.response.data.errors;
                reject(errors);
                //throw errors;
            })
        });
    },

    checkLogin(context) {

        const token = localStorage.getItem('AuthToken');
        const user = localStorage.getItem('user');

        if(token && user){
            context.commit('setUser', {
                user: user,
                token: token
            });
           }
          
    },

    logout(context){

        localStorage.removeItem('AuthToken');
        localStorage.removeItem('user');
  
        context.commit('setUser', {
            token: null,
            user: null
        });
      },
}

