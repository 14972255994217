export default {
    dashboard(state) {
        return state.dashboard;
    },

    sell_currency(state) {
        return state.sell_currency;
    },

    bought_currency(state){
        return state.bought_currency
    }

}