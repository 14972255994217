<template>
    <main-sidebar></main-sidebar>
  
    <div class="nk-wrap">
      <top-header></top-header>
  
      <div class="nk-content">
        <div class="container-fluid">
          <div class="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Money Post - Exchange Area</h3>
                    <div class="nk-block-des text-soft">
                      <p>Your currency exchange area</p>
                    </div>
                  </div>
                  <!-- .nk-block-head-content -->
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div class="toggle-expand-content" data-content="pageMenu">
                        Your Plan
                        <ul class="nk-block-tools g-3">
                          <li class="nk-block-tools-opt">
                            
                            <a href="#" class="btn btn-secondary"  
                              ><em class="icon ni ni-toolbar-fill"></em
                              ><span> {{getDashboardValues.userplan }} Plan</span></a
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <router-link to="buyplan" class="btn btn-danger">
                              <em class="icon ni ni-reports"></em>
                              {{ getDashboardValues.userplan != 'Platinum' ? 'Upgrade' : ''}} Plan
                            </router-link>
  
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- .nk-block-head-content -->
                </div>
                <!-- .nk-block-between -->
              </div>
              <!-- .nk-block-head -->
              <div class="nk-block">
                <div class="row g-gs">
                 
  
                  <div class="col-xxl-12">
                    <div class="card card-bordered card-full">
                        <div class="card-inner">
                          <div class="card-title-group align-start gx-3 mb-3">
                          <div class="card-title">
                            <h6 class="title">Buy/Sell Currency</h6>
                            <p>
                              As a BDC you can buy or sell currency on our platform
                            </p>
                          </div>
                          <div class="card-tools">
                            <div class="dropdown">
                              <select class="form-control">
                                <option value="1">Select Currency</option>
                                <option value="2">NGN</option>
                                <option value="3">EUR</option>
                                <option value="4">USD</option>
                              </select>
                            </div>
                          </div>
                        </div>
                           <div class="grid-container">
                             
                            <div class="grid-flex" v-for="curr in getCurrency.data" :key="curr.div">
                                    <div class="g-card-body">
                                              <span class="badge badge-danger"> OV</span>
                                              <h5 class="card-title">{{curr.user_id}}</h5>
                                              <div class="card-text">
                                                <div>{{curr.amount}} | {{curr.i_have}} </div>
                                                <em class="icon ni ni-arrow-to-right"></em>
                                                <div><span class="small" title="Rate">R</span>{{curr.my_rate}} | {{curr.i_need}} </div>
                                              </div>
                                                <span class="date-posted">DP: {{curr.time_ago}}</span><hr/>
                                                <button type="button" class="show_modal btn btn-xs btn-payhaven">View</button>&nbsp;&nbsp;&nbsp;
                                                <button v-if="curr.status === '2'" type="button" class="btn btn-xs btn-default">Booked</button>
                                                <button v-if="curr.status === '1'" type="button" class="btn btn-xs btn-primary" @click="openModel(curr.id, curr.user)">Buy</button>
                                      </div>
                                </div>
  
                            </div> 
                        </div>
                    </div>
                    <!-- .card -->
                  </div>
  
  
  
  
                 
  
                  <!-- .col -->
                </div>
                <!-- .row -->
              </div>
              <!-- .nk-block -->
            </div>
          </div>
        </div>
      </div>


       <!--------------------- REPEATED CODE WE NEED TO WORK ON THISMODAL START ---------------------------->
    <div v-if="myModal">
        <transition name="model">
            <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Buy Currency</h4>
                        <button @click="closeModal" type="button" class="close">
                          <span area-hidden="true" class="">&times;</span>
                        </button>
                      </div>
                      
                     

                      <div class="modal-body">
                        <div class="form-group">
                          <label>Enter Amount </label>
                          <input type="number" v-model="amount" class="form-control" placeholder="Amount" />
                          <label>Add Comment </label>
                          <textarea class="form-control" v-model="comment"  rows="3"></textarea>
                          <hr/>
                          <input type="button" class="btn btn-success btn-sm" value="Process" @click="SubmitRequest" />
                        </div>
                      </div> 


                    </div>
                  </div>

                </div>
            </div>
        </transition>
      </div>
    <!------------------- MODAL END ---------------------------->

  
      <main-footer></main-footer>
    </div>
  </template>
  
  
  <script>
  //import { mapState, mapGetters } from "vuex";
  
  export default {
    data() {
      return {
        tx: 0,
        summaries: [],
        error: null,
        myModal: false,
      dynamicID: null,
      userId :  null,
      comment: '',
      amount: '',
      };
    },
  
    computed: {
       filteredTransactions() {
         return this.$store.getters['trans/transactions']
       },
  
       filteredPayment() {
         return this.$store.getters['pay/payment'];
       },
  
       getDashboardValues(){
        return this.$store.getters['dash/dashboard'];
       },
  
       getCurrency(){
        console.log(this.$store);
        return this.$store.getters['dash/sell_currency'];
       },

       getBoughtCurrency(){
      return this.$store.getters['dash/bought_currency'];
     }
  
    },
  
    mounted() {
      this.loadPayments();
      this.loadTransactions();
      this.loadDashboard();
      this.loadCurrencies();
      this.boughtCurrencies();
    },
  
    methods: {
      async loadTransactions(){
        try{
          await this.$store.dispatch('trans/getTransactions');
        }catch(err){
          this.error = err.message || 'Something went wrong';
        }
      },
  
      async loadDashboard(){
        try{
          await this.$store.dispatch('dash/getDashboardStats');
        }catch(err){
          this.error = err.message || 'Something went wrong';
        }
      },
  
      async loadPayments(){
        try{
          await this.$store.dispatch('pay/getPayments');
        }catch(err){
          this.error = err.message || 'Something went wrong';
        }
      },
  
      async loadCurrencies(){
        try{
          console.log("dashbaord currency loading..")
          await this.$store.dispatch('dash/getActiveCurrency');
        }catch(err){
          this.error = err.message || 'Something went wrong';
        }
      },

      async boughtCurrencies(){
      try{
        console.log("dashbaord currency bought currency..")
        await this.$store.dispatch('dash/getBoughtCurrency');
      }catch(err){
        this.error = err.message || 'Something went wrong';
      }
    },

    openModel(exchangeID, userId){
      this.myModal = true;
      this.actionButton = 'Insert';
      this.dynamicTitle = 'Add Comment';
      this.dynamicID = exchangeID;
      this.userId = userId
    },

    closeModal(){
      this.myModal = false;
    }, 



    SubmitRequest() {
      if(this.dynamicID == "" || this.userId == null){
        alert("Important variable to process page missing, contact admin or try later");
        return;
      }else if(this.comment == ""){
        alert("Please add comment");
        return;
      }else{
        this.$http
          .post(
            this.$appUrl + "buy_currency",
            {
              seller_id: this.userId,
              exchange_id: this.dynamicID,
              comment: this.comment,
              amount: this.amount
            },
            this.$config
          )
          .then((response) => {  
            if (response.data.status == 200){
              this.comment = "";
              this.$swal("Purchase Successfully Initiated. You will be contacted by seller in the next 20mins");
              this.myModal = false;
            }else if(response.data.success == false){
              this.$swal(response.data);
            }
          })
          .catch((err) => { 
            this.myModal = false;
            console.log(err.message)
            this.$swal("Error Processing Currency");
            //console.log(err.message);
          });
      }
    }

      
  
    },
  };
  </script>
  
  <style scoped>
  </style>