<template>
  <!-- <sidebar-section
    style="visibility: hidden"
    v-show="$route.path === '/login' || $route.path === '/' ? false : true"
  ></sidebar-section> -->

  <div>
    <!-- <header-section></header-section> -->

    <router-view />

    <!-- <footer-section></footer-section> -->
  </div>
</template>

<script>
// import Sidebar from "./components/sidebar/sidebare.vue";
// import Header from "./components/header/header.vue";

// import Footer from "./components/footer/footer.vue";

export default {
  // components: {
  //   "sidebar-section": Sidebar,
  //   "header-section": Header,
  //   "footer-section": Footer,
  // },
  name: "App",
   created(){
    this.$store.dispatch('checkLogin');
  },
};
</script>

<style>
.makeSpace {
  padding: 20px;
}
.paddingLoader {
  padding: 5px;
}
</style>
