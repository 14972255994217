export default {
    setUser(state, payload){
        state.user = payload.user,
        state.token = payload.token
    },

    regUser(state, payload){
        state.email = payload.email
    },

}

