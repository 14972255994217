<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm"></div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <inner-slot>
                      <template #header> Sell Currency </template>

                      <template #tagline>
                        The following lists all the your
                        <strong class="text-primary">ACTIVE CURRENCIES</strong>
                        that has been posted
                      </template>
                    </inner-slot>

                    <button
                      @click="setSelectedTab('my-currencies')"
                      class="btn btn-sm btn-secondary"
                    >
                      All Currency
                    </button>

                    &nbsp;&nbsp;&nbsp;

                    <button
                      @click="setSelectedTab('post-currency')"
                      class="btn btn-sm btn-danger"
                    >
                      Post Currency
                    </button>

                    &nbsp;&nbsp;&nbsp;
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <!-- class="datatable-init table" -->

                      <keep-alive>
                        <component :is="selectedTab"></component>
                      </keep-alive>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
import myCurrencies from "./my_currencies.vue";
import postCurrency from "./post_currency.vue";

export default {
  components: {
    myCurrencies,
    postCurrency,
  },

  data() {
    return {
      selectedTab: "my-currencies",
    };
  },

  methods: {
    setSelectedTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style scoped>
</style>