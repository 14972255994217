<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Overview</h3>
                  <div class="nk-block-des text-soft">
                    <p>Welcome to Payhaven</p>
                  </div>
                </div>
                <!-- .nk-block-head-content -->
                <div class="nk-block-head-content">
                  <div class="toggle-wrap nk-block-tools-toggle">
                    <a
                      href="#"
                      class="btn btn-icon btn-trigger toggle-expand mr-n1"
                      data-target="pageMenu"
                      ><em class="icon ni ni-more-v"></em
                    ></a>
                    <div class="toggle-expand-content" data-content="pageMenu">
                      Your Plan
                      <ul class="nk-block-tools g-3">
                        <li class="nk-block-tools-opt">
                          
                          <a href="#" class="btn btn-secondary"  
                            ><em class="icon ni ni-toolbar-fill"></em
                            ><span> {{getDashboardValues.userplan }} Plan</span></a
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <router-link to="buyplan" class="btn btn-danger">
                            <em class="icon ni ni-reports"></em>
                            {{ getDashboardValues.userplan != 'Platinum' ? 'Upgrade' : ''}} Plan
                          </router-link>

                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- .nk-block-head-content -->
              </div>
              <!-- .nk-block-between -->
            </div>
            <!-- .nk-block-head -->
            <div class="nk-block">
              <div class="row g-gs">
                <div class="col-xxl-6">
                  <div class="row g-gs">
                    <div class="col-lg-6 col-xxl-12">
                      <div class="card card-bordered">
                        <div class="card-inner">
                          <div class="card-title-group align-start mb-2">
                            <div class="card-title">
                              <h6 class="title">Total Transaction</h6>
                              <p>
                                All transactions you have done on the platform.
                              </p>
                            </div>
                            <div class="card-tools">
                              <em
                                class="card-hint icon ni ni-help-fill"
                                data-toggle="tooltip"
                                data-placement="left"
                                title="Revenue from subscription"
                              ></em>
                            </div>
                          </div>
                          <div
                            class="
                              align-end
                              gy-3
                              gx-5
                              flex-wrap
                              flex-md-nowrap
                              flex-lg-wrap
                              flex-xxl-nowrap
                            "
                          >
                            <div class="nk-sale-data-group flex-md-nowrap g-4">

                          
                             
                              <div class="nk-sale-data">
                                <span class="amount">{{ getDashboardValues.transactions }}</span>
                                <span class="sub-title">Transaction</span>
                              </div>

                              <div class="nk-sale-data">
                                <span class="amount">{{ getDashboardValues.exchanges  }}</span>
                                <span class="sub-title">Exchanges</span>
                              </div>

                              <div class="nk-sale-data">
                                <span class="amount">{{
                                  getDashboardValues.payments
                                }}</span>
                                <span class="sub-title">Payment</span>
                              </div>
                              <div class="nk-sale-data">
                                <span class="amount">0</span>
                                <span class="sub-title">Confirmations</span>
                              </div>
                              <div class="nk-sale-data">
                                <span class="amount">{{
                                  getDashboardValues.contact
                                }}</span>
                                <span class="sub-title">Complaint</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- .col -->

                    <div class="col-lg-6 col-xxl-12">
                      <div class="row g-gs">
                        <div class="col-sm-6 col-lg-12 col-xxl-6">
                          <div class="card card-bordered">
                            <div class="card-inner">
                              <div class="card-title-group align-start mb-2">
                                <div class="card-title">
                                  <h6 class="title">Completed Purchases</h6>
                                </div>
                                <div
                                  class="card-tools"
                                  style="padding-bottom: 14px"
                                >
                                  <em
                                    class="card-hint icon ni ni-help-fill"
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title="Total active subscription"
                                  ></em>
                                </div>
                              </div>
                              <div
                                class="
                                  align-end
                                  flex-sm-wrap
                                  g-4
                                  flex-md-nowrap
                                "
                              >
                                <div class="nk-sale-data">
                                  <span class="amount">{{ getDashboardValues.completed}}</span>
                                  <span class="sub-title"
                                    ><span
                                      class="change down text-danger"
                                    ></span
                                    >Completed Exchanges</span
                                  >
                                </div>
                                <div class="nk-sales-ck">
                                  <canvas
                                    class="sales-bar-chart"
                                    id="activeSubscription"
                                  ></canvas>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- .card -->
                        </div>
                        <!-- .col -->

                        <!-- .col -->
                      </div>
                      <!-- .row -->
                    </div>
                    <!-- .col -->
                  </div>
                  <!-- .row -->
                </div>
                <!-- .col -->

                <div class="col-xxl-12">
                  <div class="card card-bordered card-full">
                      <div class="card-inner">
                        <div class="card-title-group align-start gx-3 mb-3">
                        <div class="card-title">
                          <h6 class="title">Buy/Sell Currency</h6>
                          <p>
                            As a BDC you can buy or sell currency on our platform
                            <router-link to="money_post">View More</router-link>
                          </p>
                        </div>
                        <div class="card-tools">
                          <div class="dropdown">
                            <select class="form-control">
                              <option value="1">Select Currency</option>
                              <option value="2">NGN</option>
                              <option value="3">EUR</option>
                              <option value="4">USD</option>
                            </select>
                          </div>
                        </div>
                      </div>
                         <div class="grid-container">
                           
                              <div class="grid-flex" v-for="curr in getCurrency.data" :key="curr.div">
                                    <div class="g-card-body">
                                              <span class="badge badge-danger"></span>
                                              <h5 class="card-title">{{curr.user_id}}</h5>
                                              <div class="card-text">
                                                <div>{{curr.amount}} | {{curr.i_have}} </div>
                                                <em class="icon ni ni-arrow-to-right"></em>
                                                <div><span class="small" title="Rate">R</span>{{curr.my_rate}} | {{curr.i_need}} </div>
                                              </div>
                                                <span class="date-posted">DP: {{curr.time_ago}}</span><hr/>
                                                <button type="button" class="show_modal btn btn-xs btn-secondary" @click="viewDetails(curr.id)">View</button>&nbsp;&nbsp;&nbsp;
                                                <button v-if="curr.status === 'BOOOKED'" type="button" class="btn btn-xs btn-default">Booked</button>
                                                <button v-if="curr.status === 'Available'" type="button" class="btn btn-xs btn-primary" @click="openModel(curr.id, curr.user)">Buy</button>
                                      </div>
                                </div>

                          </div> 
                      </div>
                  </div>
                  <!-- .card -->
                </div>



   



                  <!-- .col -->
                  <div class="col-xxl-8">
                  <div class="card card-bordered card-full">
                    <div class="card-inner">
                      <div class="card-title-group">
                        <div class="card-title">
                          <h6 class="title">
                            <span class="mr-2">Currency Purchase</span>
                            <router-link to="" class="link d-none d-sm-inline">View More</router-link>
                          </h6>
                        </div>
                        
                      </div>
                    </div>

                    <table class="table table-responsive table-hovered table-bordered">
                      <thead>
                        <tr class="bg bg-secondary" style="color: white">
                          <th>ID</th>
                          <th>Date</th>
                          <th>Currency I Need</th>
                          <th>Seller Currency Amount</th>
                          <th>Amount Purchased</th>
                          <th>Rate</th>
                          <th>Payable</th>
                          <th>Seller</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="t in getBoughtCurrency" :key="t.id">
                          <td>{{ t.id }}</td>
                          <td>{{ t.created_at }}</td>
                          <td>{{ t.i_need }}</td>
                          <td>{{ t.i_have }} {{ t.amount }}</td>
                          <td>{{ t.i_have }}  {{ t.buyer_amount }}</td>
                          <td>{{ t.my_rate }} <br/>
                            <small style="color:red; font-size:8px; font-weight: bold;">@ 1 {{  t.i_have }}</small></td>
                          <td>{{ t.i_need }} {{( t.buyer_amount * t.my_rate)}}</td>
                          <td>{{ t.user_id }}</td>
                         
                          <td>
                            <span v-if="t.status == 'Available'">
                              <span class="bg bg-success">{{ t.status }}</span>
                            </span>
                            <span v-else-if="t.status == 'Booked'">
                              <span class="bg bg-warning">{{ t.status }}</span>
                            </span>
                            <span v-else-if="t.status == 'Cancelled'">
                              <span class="badge badge-danger">{{ t.status }}</span>
                            </span>

                            <span v-else-if="t.status == 'Completed'">
                              <span class="badge badge-success">{{ t.status }}</span>
                            </span>

                            <span v-else-if="t.status == 'Buyer_Paid'">
                              <span class="badge badge-success">{{ t.status }}</span>
                            </span>

                            <span v-else-if="t.status == 'Seller_Paid'">
                              <span class="badge badge-success">{{ t.status }}</span>
                            </span>

                            <span v-else-if="t.status == 'Confirmed'">
                              <span class="badge badge-secondary">{{ t.status }}</span>
                              <span>&nbsp;&nbsp;</span>
                              <button id="submitBtn" class="btn btn-xs btn-danger" @click="uploadpayment(t.id)">Upload Payment</button>
                            </span>

                            <span v-else-if="t.status == 'Buyer_Confirmed'">
                              <span class="badge badge-success">{{ t.status }}</span>
                              <span>&nbsp;&nbsp;</span>
                            <!-- <button class="btn btn-xs btn-secondary" @click="uploadpayment(t.id)">Upload Payment</button> -->

                          </span>

                            
                          </td>
                          <td class="tb-tnx-action">
                            <div class="dropdown">
                              <a
                                class="
                                  text-soft
                                  dropdown-toggle
                                  btn btn-icon btn-trigger
                                "
                                data-toggle="dropdown"
                                ><em class="icon ni ni-more-h"></em
                              ></a>
                              <div
                                class="
                                  dropdown-menu
                                  dropdown-menu-right
                                  dropdown-menu-xs
                                "
                              >
                                <ul class="link-list-plain">
                                  <li><a href="#">View More</a></li>
                                  <li><a href="#">View History</a></li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- .card -->
                </div>
                <!-- .col -->




                <!-- .col -->
                <div class="col-xxl-8">
                  <div class="card card-bordered card-full">
                    <div class="card-inner">
                      <div class="card-title-group">
                        <div class="card-title">
                          <h6 class="title">
                            <span class="mr-2">Recent Transaction</span>
                            <router-link to="all_transactions" class="link d-none d-sm-inline">View More</router-link>
                          </h6>
                        </div>
                        
                      </div>
                    </div>

                    <table class="table table-responsive table-hovered table-bordered">
                      <thead>
                        <tr class="bg bg-secondary" style="color: white">
                          <th>ID</th>
                          <th>Date</th>
                          <th>Amount Paid</th>
                          <th>Amount Collected</th>
                          <th>Pay Method</th>
                          <th>Pay Reference</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="tx in filteredTransactions" :key="tx.id">
                          <td>{{ tx.id }}</td>
                          <td>{{ tx.created_at }}</td>
                          <td>{{ tx.base }} {{ tx.amount }}</td>
                          <td>{{ tx.target }} {{ tx.iget }}</td>
                          <td>{{ tx.payment_method }}</td>
                          <td><div style="width: 150px" >{{ tx.payment_reference }}</div></td>

                          <td>
                            <span
                              :class="
                                tx.status == 'pending'
                                  ? 'btn btn-xs btn-primary'
                                  : 'btn btn-xs btn-secondary'
                              "
                              >{{ tx.status }}</span
                            >
                          </td>
                          <td class="tb-tnx-action">
                            <div class="dropdown">
                              <a
                                class="
                                  text-soft
                                  dropdown-toggle
                                  btn btn-icon btn-trigger
                                "
                                data-toggle="dropdown"
                                ><em class="icon ni ni-more-h"></em
                              ></a>
                              <div
                                class="
                                  dropdown-menu
                                  dropdown-menu-right
                                  dropdown-menu-xs
                                "
                              >
                                <ul class="link-list-plain">
                                  <li><a href="#">Send Mail</a></li>
                                  <li><a href="#">View More</a></li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- .card -->
                </div>
                <!-- .col -->


     

               

                <!-- .col -->
              </div>
              <!-- .row -->
            </div>
            <!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>


    <modal title="View Details" @close-modal="closeModal" v-if="viewdetails">
      <template #slotbody>
       <table class="table table-responsive table-hovered table-secondary">
        <tr>
          <th>Seller Name</th>
          <td> {{ sellerName }} </td>
        </tr>

        <tr>
          <th>Currency I want ot Sell</th>
          <td> {{ ihave }} </td>
        </tr>

        <tr>
          <th>Currency I Need</th>
          <td> {{ ineed }} </td>
        </tr>

        <tr>
          <th>Rate of the Currency Exchange (Have/Need)</th>
          <td> {{ myrate }} </td>
        </tr>

        <tr>
          <th>Status</th>
          <td> {{ status }} </td>
        </tr>

        <tr>
          <th>Date Posted</th>
          <td> {{ dateposted }} </td>
        </tr>
       </table>
      </template>
    </modal>


    <modal title="Upload Payment" @close-modal="closeModal" v-if="modalpopup">
      <template #slotbody>
        <label>Upload Payment Evidence {{ upload_id  }}</label>
         <input type="file" @change="onFileSelected" class="form-control" placeholder="UploadFile" />
         <hr/>
        <input type="button" class="btn btn-secondary btn-sm" value="Upload Payment" @click="submitUploadFile" />
      </template>
    </modal>
    <!-- <modal v-if="myModal" :dynamicID="dynamicID"  :userid="userId"  @close-modal="closeModal"></modal> -->

    <!--------------------- MODAL START ---------------------------->
    <div v-if="myModal">
        <transition name="model">
            <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Buy Currency</h4>
                        <button @click="closeModal" type="button" class="close">
                          <span area-hidden="true" class="">&times;</span>
                        </button>
                      </div>
                      
                     

                      <div class="modal-body">
                        <div class="form-group">
                          <label>Enter Amount </label>
                          <input type="number" v-model="amount" class="form-control" placeholder="Amount" />
                          <label>Add Comment </label>
                          <textarea class="form-control" v-model="comment"  rows="3"></textarea>
                          <hr/>
                          <input type="button" class="btn btn-success btn-sm" value="Process" @click="SubmitRequest" />
                        </div>
                      </div> 


                    </div>
                  </div>

                </div>
            </div>
        </transition>
      </div>
    <!------------------- MODAL END ---------------------------->


    <main-footer></main-footer>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      tx: 0,
      summaries: [],
      error: null,
      myModal: false,
      modalpopup: false,
      dynamicID: null,
      userId :  null,
      comment: '',
      amount: '',
      selectedFile: null,
      upload_id: '',
      viewdetails: false,
      sellerName: '',
      ihave: '',
      ineed: '',
      myrate: '',
      status: '',
      dateposted: '',
    };
  },

  provide() {
    return {
      closeModal: this.closeModal,
    };
  },

  computed: {
     filteredTransactions() {
       return this.$store.getters['trans/transactions']
     },

     filteredPayment() {
       return this.$store.getters['pay/payment'];
     },

     getDashboardValues(){
      return this.$store.getters['dash/dashboard'];
     },

     getCurrency(){
      console.log(this.$store);
      return this.$store.getters['dash/sell_currency'];
     },

     getBoughtCurrency(){
      return this.$store.getters['dash/bought_currency'];
     }

  },

  mounted() {
    this.loadPayments();
    this.loadTransactions();
    this.loadDashboard();
    this.loadCurrencies();
    this.boughtCurrencies();
  },

  methods: {

    viewDetails(id){
     this.viewdetails = true;
     this.$http.get(this.$appUrl + "get_exchange_details/" + id, this.$config)
     .then((response) => {
      console.log(response.data)
      this.sellerName = response.data.data[0].user_id;
      this.ihave = response.data.data[0].i_have;
      this.ineed = response.data.data[0].i_need;
      this.myrate = response.data.data[0].my_rate;
      this.status =  response.data.data[0].status;
      this.dateposted = response.data.data[0].time_ago;
     });
    },

    async loadTransactions(){
      try{
        await this.$store.dispatch('trans/getTransactions');
      }catch(err){
        this.error = err.message || 'Something went wrong';
      }
    },

    async loadDashboard(){
      try{
        await this.$store.dispatch('dash/getDashboardStats');
      }catch(err){
        this.error = err.message || 'Something went wrong';
      }
    },

    async loadPayments(){
      try{
        await this.$store.dispatch('pay/getPayments');
      }catch(err){
        this.error = err.message || 'Something went wrong';
      }
    },

    async loadCurrencies(){
      try{
        console.log("dashbaord currency loading..")
        await this.$store.dispatch('dash/getActiveCurrency');
      }catch(err){
        this.error = err.message || 'Something went wrong';
      }
    },


    async boughtCurrencies(){
      try{
        console.log("dashbaord currency bought currency..")
        await this.$store.dispatch('dash/getBoughtCurrency');
      }catch(err){
        this.error = err.message || 'Something went wrong';
      }
    },

    openModel(exchangeID, userId){
      this.myModal = true;
      this.actionButton = 'Insert';
      this.dynamicTitle = 'Add Comment';
      this.dynamicID = exchangeID;
      this.userId = userId
    },

    closeModal(){
      this.myModal = false;
      this.modalpopup = false;
      this.viewdetails = false;
    },
    
    uploadpayment(id){
      this.modalpopup = true;
      this.upload_id = id;
    },

    onFileSelected(event){
      this.selectedFile = event.target.files[0];
    },

    submitUploadFile(){
      this.$systoken.processing("Processing...", "submitBtn", true);
      const fdata = new FormData();
      fdata.append('image', this.selectedFile);
      fdata.append('id', this.upload_id);
      axios.post(this.$appUrl + "upload_file", fdata, this.$config)
      .then((response) => {
        if (response.data.status == 200) { 
          this.$swal("File Uploaded Successfully");
          this.modalpopup = false;
        }
      }).catch((err) => {
        this.$systoken.processing("Upload Payment", "submitBtn", false);
        this.$swal(err.message);
      });
    },


    SubmitRequest() {
      if(this.dynamicID == "" || this.userId == null){
        alert("Important variable to process page missing, contact admin or try later");
        return;
      }else if(this.comment == "" || !this.amount){
        alert("Please add comment");
        return;
      }else{
        this.$http
          .post(
            this.$appUrl + "buy_currency",
            {
              seller_id: this.userId,
              exchange_id: this.dynamicID,
              comment: this.comment,
              amount: this.amount
            },
            this.$config
          )
          .then((response) => {  
            if (response.data.status == 200){
              this.comment = "";
              this.$swal("Purchase Successfully Initiated. You will be contacted by seller in the next 20mins");
              this.myModal = false;
            }
          })
          .catch((err) => { 
            this.myModal = false;
            console.log(err.message);
            this.$swal("Error Processing Currency");
            setTimeout(() => {
              this.$router.go()
            }, "3000")
            //console.log(err.message);
          });
      }
    }

  },
};
</script>

<style scoped>
</style>