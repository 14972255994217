<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm"></div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">LIST OF MENUS</h4>
                    <p>
                      The following lists all the
                      <strong class="text-primary">menu</strong> both active and
                      inactive
                    </p>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <shimmer v-if="loading" class="paddingLoader"></shimmer>
                      <p class="makeSpace" v-else-if="!loading && error">
                        {{ error }}
                      </p>

                      <p
                        class="makeSpace"
                        v-else-if="
                          !loading && (!allMenus || allMenus.length === 0)
                        "
                      >
                        No Menu Found
                      </p>
                      <!-- class="datatable-init table" -->
                      <table class="table table-bordered" v-else>
                        <thead>
                          <tr class="bg bg-secondary" style="color: white">
                            <th>ID</th>
                            <th>Name</th>
                            <th>URL</th>
                            <th>Menu Type</th>
                            <th>Menu Class</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="mn in allMenus" :key="mn.id">
                            <td>{{ mn.id }}</td>
                            <td>{{ mn.name }}</td>
                            <td>{{ mn.url }}</td>
                            <td>{{ mn.menu_type }}</td>
                            <td>{{ mn.menu_class }}</td>
                            <td>
                              <span
                                :class="
                                  mn.status === 1
                                    ? 'btn btn-xs btn-success'
                                    : 'btn btn-xs btn-danger'
                                "
                                >{{
                                  mn.status === 1 ? "Active" : "Inactive"
                                }}</span
                              >
                            </td>
                            <td class="tb-tnx-action">
                              <div class="dropdown">
                                <a
                                  class="
                                    text-soft
                                    dropdown-toggle
                                    btn btn-icon btn-trigger
                                  "
                                  data-toggle="dropdown"
                                  ><em class="icon ni ni-more-h"></em
                                ></a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-xs
                                  "
                                >
                                  <ul class="link-list-plain">
                                    <li>
                                      <router-link to="no_link_yet">{{
                                        mn.status === 1
                                          ? "Deactivate"
                                          : "Activate"
                                      }}</router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      allMenus: [],
      error: null,
    };
  },

  methods: {
    getMenus() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "menu", this.$config)
        .then(({ data }) => {
          console.log(data.menu);
          this.loading = false;
          this.allMenus = this.$globeFunc.sortArray(data.menu);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Failed to fetch data, please try again or check your internet";
        });
    },
  },

  mounted() {
    this.getMenus();
  },
};
</script>

<style scoped>
</style>