<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Exchange Area</h4>
                    <p>
                      All Active and Available Exchange
                      <strong class="text-primary">are displayed</strong> here
                      for admin to query
                    </p>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <shimmer v-if="loading" class="paddingLoader"></shimmer>
                      <p class="makeSpace" v-else-if="!loading && error">
                        {{ error }}
                      </p>

                      <p
                        class="makeSpace"
                        v-else-if="
                          !loading &&
                          (!bdcurriencies || bdcurriencies.length === 0)
                        "
                      >
                        No Currency Found
                      </p>
                      <!-- class="datatable-init table" -->
                      <table class="table table-responsive table-bordered table-hovered" v-else>
                        <thead>
                          <tr class="bg bg-secondary" style="color: white">
                            <th>ID</th>
                            <th>Seller</th>
                            <th>I Have</th>
                            <th>Need</th>


                            <th>Rate</th>
                           
                            <th>Buyer Amount</th>
                            <th>Expected Amount</th>

                            <th>Date Created</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="bdc in bdcurriencies" :key="bdc.id">
                            <td>{{ bdc.id }}</td>
                            <td>{{ bdc.user_id }}</td>
                            <td>{{ bdc.i_have }} {{ bdc.amount }}</td>
                            <td>{{ bdc.i_need }}</td>

                            <td>{{ bdc.my_rate }}</td>
                            
                            <td>{{ bdc.i_have }} {{ returnAmount(bdc.buyer_details)  }}</td>

                            <td>{{ bdc.i_need }} {{ bdc.my_rate * returnAmount(bdc.buyer_details)  }}</td>

                            <td>{{ bdc.created_at }}</td>
                            <td>
                              <span>
            <span v-if="bdc.status == 'Available'">
              <span class="bg bg-success">{{ bdc.status }}</span>
            </span>
            <span v-else-if="bdc.status == 'Booked'">
              <span class="bg bg-warning">{{ bdc.status }}</span>
            </span>
            <span v-else-if="bdc.status == 'Cancelled'">
              <span class="badge badge-danger">{{ bdc.status }}</span>
            </span>

            <span v-else-if="bdc.status == 'Completed'">
              <span class="badge badge-success">{{ bdc.status }}</span>
            </span>

            <span v-else-if="bdc.status == 'Confirmed'">
              <span class="badge badge-secondary">{{ bdc.status }}</span>
              
            </span>

            <span v-else-if="bdc.status == 'Buyer_Paid'">
                <span class="badge badge-success">{{ bdc.status }}</span>
                &nbsp;&nbsp;
              <span style="cursor:pointer"><i class="fa fa-receipt"></i></span>
            </span>

            <span v-else-if="bdc.status == 'Seller_Paid'">
                <span class="badge badge-success">{{ bdc.status }}</span>
            </span>
            
          </span>
                            </td>
                            <td class="tb-tnx-action">
                              <div class="dropdown">
                                <a
                                  class="
                                    text-soft
                                    dropdown-toggle
                                    btn btn-icon btn-trigger
                                  "
                                  data-toggle="dropdown"
                                  ><em class="icon ni ni-more-h"></em
                                ></a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-xs
                                  "
                                >
                                  <ul class="link-list-plain">
                                    <li v-if="bdc.status != 'Completed'"><a href="#" @click="confirmSuspension(bdc.id, bdc.status)">Suspend Transaction</a></li>
                                    <li v-if="bdc.status ==  'Buyer_Paid'"><a href="#"  @click="confirmBuyerPayment(bdc.id, bdc.status)">Confirm Buyer Payment</a></li>
                                    <li v-if="bdc.status == 'Seller_Paid'"><a href="#" @click="confirmSellerPayment(bdc.id, bdc.status)">Confirm Seller Payment</a></li>
                                  
                                    
                                    <!-- <li><a href="#" @click="viewPayment(bdc.id, bdc.status)">View Payment</a></li> -->
                                    <li><a href="#" @click="viewDetails(bdc.id)">View Details</a></li>
                                    <li><a href="#" @click="sendMail(bdc.id, bdc.status)">Send Mail</a></li>
                                    
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <modal title="View Details" @close-modal="closeModal" v-if="modalpopup">
      <template #slotbody>
        <table class="table table-responsive table-bordered table-hover">
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
          <tr>
            <th>Buyer Amount</th>
            <td>{{ buyerName  }} </td>
          </tr>

          <tr>
            <th>Amount</th>
            <td>{{ buyerWant + ' ' + buyerAmount  }} </td>
          </tr>

          <tr>
            <th>Buyer Comment</th>
            <td>{{ buyerComment  }} </td>
          </tr>
         
         <tr>
            <th>Seller Name</th>
            <td>{{ sellerName  }} </td>
          </tr> 

          <tr>
            <th>Seller Rate</th>
            <td>{{ sellerRate  }} </td>
          </tr> 

          <tr>
            <th>Seller Needs</th>
            <td>{{ sellerNeeds  }} </td>
          </tr>
          
          <tr>
            <th>Seller Expected Amount</th>
            <td>{{ sellerRate *  buyerAmount }} </td>
          </tr>
         
        </table>
        <div>
         See Payment:
         <ul>  
            <li v-for="img in paymentImage" :key="img.id">
              <a target="_blank" :href="`http://localhost:8000/${img.link}`">{{ img.link }}</a>
            </li>
         </ul>
         
        </div>
        <div><small>Seller is Exchanging {{ buyerWant }} @ the rate of  {{ buyerWant }}{{ sellerRate }} per {{ buyerWant }} for his {{ sellerNeeds }}</small></div>
      </template>
    </modal>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      bdcurriencies: [],
      searchCurrencies: "",
      error: null,
      modalpopup: false,
      buyerName: '',
      buyerAmount: '',
      buyerWant: '',
      buyerComment: '',
      sellerName: '',
      sellerRate: '',
      sellerNeeds: '',
      paymentImage: [],
      invoiceid: '',
    };
  },

  provide() {
    return {
      closeModal: this.closeModal,
    };
  },

  methods: {
    getAllTransactions() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "post_bdc", this.$config)
        .then(({ data }) => {
          this.loading = false;
          this.bdcurriencies = this.$globeFunc.sortArray(data.data);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Failed to fetch data, please try again or check your internet";
          //this.$swal(error);
        });
    },

    returnAmount(object){
      return object.map((el) => {
        return el.amount;
      }).reduce((a, b) => a + b, 0);
    
    },

    confirmBuyerPayment(id, status){
      //alert(status)
      if(status == 'Confirmed' || status == 'Buyer_Paid'){
        this.$swal({
          title: "Are you sure?",
          text: "You want to confirm buyer payment",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((result) => {
          if(result){
              this.$http.post(this.$appUrl + "confirm_payment", { id: id, status: status }, this.$config)
              .then((response) => { 
                //console.log(response.data.status);
              if(response.data.status == 200){
                this.$swal('Confirmed!', 'Transaction has been confirmed.', 'success');
                this.getAllTransactions();
              } 
            }).catch((error) => {
              this.$swal(error.response.data.message);
              this.getAllTransactions();
              
            });
          }else{
            this.$swal("You can't confirm buyer payment");
          }
        });
      }else{
        this.$swal("You can't confirm buyer payment");
      }
    },

   

    confirmSellerPayment(id, status){
      if(status == 'Confirmed' || status == 'Seller_Paid'){
        this.$swal({
          title: "Are you sure?",
          text: "You want to confirm payment",
          icon: "warning",
          showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm it!'
        }).then((result) => {
          if(result.isConfirmed == true){
              this.$http.post(this.$appUrl + "seller_confirm_payment", { id: id, status: status }, this.$config)
              .then((response) => { 
                //console.log(response.data.status);
              if(response.data.status == 200){
                this.$swal('Confirmed!', 'Seller Transaction has been confirmed.', 'success');
                this.getAllTransactions();
              } 
            }).catch((error) => {
              this.$swal("Failed to fetch data, please try again or check your internet");
              this.getAllTransactions();
            });
          }else{
            this.$swal("You can't confirm buyer payment");
          }
        });
      }else{
        this.$swal("You can't confirm buyer payment");
      }
    },


    viewDetails(id){
     
      if(id){
        this.$http.post(this.$appUrl + "exchange_details", { id: id }, this.$config)
        .then((response) => { 
          this.modalpopup = true;
          console.log(response);
          this.buyerName = response.data.data.buyerDetail[0].buyer_id;
          this.buyerAmount = response.data.data.buyerDetail[0].amount;
          this.buyerWant = response.data.data.buyerDetail[0].exchange[0].i_have;
          this.buyerComment = response.data.data.buyerDetail[0].comment;
          this.sellerName = response.data.data.buyerDetail[0].seller_id;
          this.sellerRate = response.data.data.buyerDetail[0].exchange[0].my_rate;
          this.sellerNeeds = response.data.data.buyerDetail[0].exchange[0].i_need;
          this.paymentImage = response.data.data.paymentDetails
          return;
          
          }).catch((error) => {
              this.$swal("Failed to fetch data, please try again or check your internet");
              this.getAllTransactions();
              this.modalpopup = false;
            });
      } else {
        this.$swal("Important Variables to Render Page Is Missing");
        this.modalpopup = false;
      }
    },

    closeModal(){
      this.myModal = false;
      this.modalpopup = false;
    },
    

  },

  mounted() {
    this.getAllTransactions();
    //this.checkInvoice();
  },
};
</script>

<style scoped>
</style>