<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <div class="nk-block-head-sub">
                    <router-link class="back-to" to="all_users"
                      ><em class="icon ni ni-arrow-left"></em
                      ><span>Back</span></router-link
                    >
                  </div>
                  <h2 class="nk-block-title fw-normal">Add New Permission</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      Any New Permission Added will be displayed, please note
                      You can give roles or a user a permission
                    </p>
                  </div>
                </div>
              </div>

              <!-- .nk-block -->
              <div class="nk-block nk-block-lg">
                <div class="card card-bordered">
                  <div class="card-inner">
                    <div class="card-head">
                      <h5 class="card-title">Permission Setup</h5>
                    </div>
                    <form @submit.prevent="postPermission" class="gy-3">
                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Add Permission
                            </label>
                            <span class="form-note">create permission</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="plan_price"
                                v-model="form.permission_name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3">
                        <div class="col-lg-7 offset-lg-5">
                          <div class="form-group mt-2">
                            <button
                              type="submit"
                              class="btn btn-lg btn-primary"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- card -->
              </div>
              <!-- .nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: {},
      error: {},
      form: {
        permission_name: "",
      },
    };
  },

  mounted() {
    if (!this.$user.loggedIn()) {
      this.$router.push({ name: "login" });
    }
  },

  methods: {
    postPermission() {
      if (!this.form.permission_name) {
        this.$swal("Please create a permission");
      } else {
        this.$http
          .post(this.$appUrl + "post_permission", this.form, this.$config)
          .then((response) => {
            if (response.data.status == 200) {
              console.log(response.data.status);
              this.$swal("Permission Successfully Created");
              this.$router.push({ name: "all_users" });
            }
          })
          .catch((err) => {
            //console.log(err.response.data.data);
            this.error = err.response.data.message;
            this.$swal(err.response.data.message);
          });
      }
    },
  },
};
</script>

<style scoped>
</style>