<template>
  <div class="nk-header nk-header-fixed is-light">
    <div class="container-fluid">
      <div class="nk-header-wrap">
        <div class="nk-menu-trigger d-xl-none ml-n1">
          <a
            href="#"
            class="nk-nav-toggle nk-quick-nav-icon"
            data-target="sidebarMenu"
            ><em class="icon ni ni-menu"></em
          ></a>
        </div>
        <div class="nk-header-brand d-xl-none">
          <a href="html/crypto/index.html" class="logo-link">
            <img
              class="logo-light logo-img"
              src="template/demo1/src/images/payhaven/logo.png"
              srcset="template/demo1/src/images/payhaven/logo.png"
              alt="logo"
            />
            <img
              class="logo-dark logo-img"
              src="template/demo1/src/images/payhaven/logo.png"
              srcset="template/demo1/src/images/payhaven/logo.png"
              alt="logo-dark"
            />
            <span class="nio-version">General</span>
          </a>
        </div>
        <!-- .nk-header-brand -->
        <div class="nk-header-news d-none d-xl-block">
          <div class="nk-news-list">
            <a class="nk-news-item" href="#">
              <div class="nk-news-icon">
                <em class="icon ni ni-card-view"></em>
              </div>
              <div class="nk-news-textA">
                <p>
                  <profile-header>
                    <template v-slot:headerInfo="slotProps">
                      {{ slotProps.userItems.type }}

                      <span>
                        - &nbsp;&nbsp;{{ slotProps.userItems.name }}
                      </span>
                    </template>
                  </profile-header>
                </p>
              </div>
            </a>
          </div>
        </div>
        <!-- .nk-header-news -->
        <div class="nk-header-tools">
          <ul class="nk-quick-nav">
            <li class="dropdown user-dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <div class="user-toggle">
                  <div class="user-avatar sm">
                    <em class="icon ni ni-user-alt"></em>
                  </div>
                  <div class="user-info d-none d-md-block">
                    <profile-header>
                      <template v-slot:headerInfo="slotProps">
                        <div class="user-status">
                          {{ slotProps.userItems.type }}
                        </div>
                        <div class="user-name dropdown-indicator">
                          {{ slotProps.userItems.name }}
                        </div>
                      </template>
                    </profile-header>

                    <!-- <div class="user-status">Administrator</div>
                    <div class="user-name dropdown-indicator">
                      Abu Bin Ishityak
                    </div> -->
                  </div>
                </div>
              </a>
              <div
                class="
                  dropdown-menu
                  dropdown-menu-md
                  dropdown-menu-right
                  dropdown-menu-s1
                "
              >
                <div
                  class="
                    dropdown-inner
                    user-card-wrap
                    bg-lighter
                    d-none d-md-block
                  "
                >
                  <profile-header>
                    <template v-slot:headerInfo="slotProps">
                      <div class="user-card">
                        <div class="user-avatar">
                          <span>{{ slotProps.userItems.abb }}</span>
                        </div>

                        <div class="user-info">
                          <span class="lead-text">
                            {{ slotProps.userItems.name }}</span
                          >
                          <span class="sub-text">{{
                            slotProps.userItems.email
                          }}</span>
                        </div>
                      </div>
                    </template>
                  </profile-header>
                </div>

                <div class="dropdown-inner">
                  <ul class="link-list">
                    <li>
                      <a href="update_profile"
                        ><em class="icon ni ni-user-alt"></em
                        ><span>View Profile</span></a
                      >
                    </li>
                    
                    <li>
                      <a href="logs"
                        ><em class="icon ni ni-activity-alt"></em
                        ><span>Login Activity</span></a
                      >
                    </li>
                  </ul>
                </div>
                <div class="dropdown-inner">
                  <ul class="link-list">
                    <li>
                      <router-link to="logout"
                        ><em class="icon ni ni-signout"></em
                        ><span>Sign out</span></router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- .dropdown -->

            <li class="dropdown notification-dropdown mr-n1">
              <a
                href="#"
                class="dropdown-toggle nk-quick-nav-icon"
                data-toggle="dropdown"
              >
                <div class="icon-status icon-status-info">
                  <em class="icon ni ni-bell"></em>
                </div>
              </a>
              <div
                class="
                  dropdown-menu
                  dropdown-menu-xl
                  dropdown-menu-right
                  dropdown-menu-s1
                "
              >
                <div class="dropdown-head">
                  <span class="sub-title nk-dropdown-title">Notifications</span>
                </div>
                <div class="dropdown-body">
                  <div class="nk-notification">
                    <!-- <div class="nk-notification-item dropdown-inner">
                      <div class="nk-notification-icon">
                        <em
                          class="
                            icon icon-circle
                            bg-warning-dim
                            ni ni-curve-down-right
                          "
                        ></em>
                      </div>
                      <div class="nk-notification-content">
                        <div class="nk-notification-text">
                          You have requested to <span>Widthdrawl</span>
                        </div>
                        <div class="nk-notification-time">2 hrs ago</div>
                      </div>
                    </div> -->

                    <!-- <div class="nk-notification-item dropdown-inner">
                      <div class="nk-notification-icon">
                        <em
                          class="
                            icon icon-circle
                            bg-success-dim
                            ni ni-curve-down-left
                          "
                        ></em>
                      </div>
                      <div class="nk-notification-content">
                        <div class="nk-notification-text">
                          Your <span>Deposit Order</span> is placed
                        </div>
                        <div class="nk-notification-time">2 hrs ago</div>
                      </div>
                    </div> -->
                  </div>
                  <!-- .nk-notification -->
                </div>
                <!-- .nk-dropdown-body -->
                <div class="dropdown-foot center">
                  <a href="#">View All</a>
                </div>
              </div>
            </li>

            <!-- .dropdown -->
          </ul>
          <!-- .nk-quick-nav -->
        </div>
        <!-- .nk-header-tools -->
      </div>
      <!-- .nk-header-wrap -->
    </div>
    <!-- .container-fliud -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {
    //  let authenticated = this.$user.loggedIn();
    if (!this.$user.loggedIn()) {
      this.$router.push({ name: "login" });
    }
  },
};
</script>