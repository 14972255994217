<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content nk-content-lg nk-content-fluid">
      <div class="container-xl wide-lg">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="kyc-app wide-sm m-auto">
              <div class="nk-block-head nk-block-head-lg wide-xs mx-auto">
                <div class="nk-block-head-content text-center">
                  <h2 class="nk-block-title fw-normal">Exchange Currency</h2>
                  <div class="nk-block-des">
                    <p>
                      Payhaven currency exchange allows you to securing
                      exchange approved currencies in payment through our
                      <strong>Escrow Services</strong> that is worldclass.
                    </p>
                  </div>
                </div>
              </div>

              <form @submit.prevent="postCurrency">
                <!-- nk-block -->
                <div class="nk-block">
                  <div class="card card-bordered">
                    <div class="nk-kycfm">
                      <div class="nk-kycfm-head">
                        <div class="nk-kycfm-count">01</div>
                        <div class="nk-kycfm-title">
                          <h5 class="title">Currency Exchange</h5>
                          <p class="sub-title">
                            Process your transactions.
                          </p>
                        </div>
                      </div>
                      <!-- nk-kycfm-head -->
                      <div class="nk-kycfm-content">
                        <div class="nk-kycfm-note">
                          <em
                            class="icon ni ni-info-fill"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Tooltip on right"
                          ></em>
                          <p>
                            Please note our price rate are based on market rates
                            with cap, Use our currency converter today and get
                            the best experience ever.
                          </p>
                        </div>
                        <div class="row g-4">
                          <div class="col-md-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label"
                                  >You Have
                                  <span class="text-danger">*</span></label
                                >
                              </div>
                              <div class="form-control-group">
                                <select
                                  v-model="form.ihave"
                                  class="form-control form-control-lg"
                                  @change="haveCurrency($event)"
                                >
                                  <option value="">Select</option>
                                  <option
                                    v-for="cur in allCalculator"
                                    :key="cur.id"
                                    :value="cur.approved_currency"
                                  >
                                    {{ cur.approved_currency }}
                                  </option>
                                </select>
                                <small class="text-danger" v-if="errors.base">
                                  {{ errors.base[0] }}
                                </small>
                              </div>
                            </div>
                          </div>
                          <!-- .col -->
                          <div class="col-md-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label"
                                  >Amount
                                  <span class="text-danger">*</span></label
                                >
                              </div>
                              <div class="form-control-group">
                                <input
                                  type="number"
                                  class="form-control form-control-lg"
                                  v-model="form.amount"
                                  @input="processCurrency()"
                                />
                                <small class="text-danger" v-if="errors.amount">
                                  {{ errors.amount[0] }}
                                </small>
                              </div>
                            </div>
                          </div>
                          <!-- .col -->

                          <!-- .col -->
                          <div class="col-md-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label"
                                  >You Need
                                  <span class="text-danger">*</span></label
                                >
                              </div>
                              <div class="form-control-group">
                                <select
                                  v-model="form.ineed"
                                  class="form-control form-control-lg"
                                  @change="processCurrency()"
                                >
                                  <option value="">Select</option>
                                  <option
                                    v-for="cur in allCalculator"
                                    :key="cur.id"
                                    :value="cur.approved_currency"
                                  >
                                    {{ cur.approved_currency }}
                                  </option>
                                </select>
                                <small class="text-danger" v-if="errors.target">
                                  {{ errors.target[0] }}
                                </small>
                              </div>
                            </div>
                          </div>
                          <!-- .col -->
                          <div class="col-md-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label"
                                  >You Get
                                  <span class="text-danger">*</span></label
                                >
                              </div>
                              <div class="form-control-group">
                                <input
                                  type="text"
                                  class="form-control form-control-lg"
                                  v-model="form.iget"
                                  readonly
                                />
                                <small class="text-danger" v-if="errors.iget">
                                  {{ errors.iget[0] }}
                                </small>
                              </div>
                            </div>
                          </div>
                          <!-- .col -->

                          <div class="col-md-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label"
                                  ><button class="btn btn-xs btn-primary">
                                    Pay By
                                  </button>
                                </label>
                              </div>
                              <div class="form-control-group">&nbsp;</div>
                            </div>
                          </div>

                          <!-- .col -->
                          <div class="col-md-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label">Payment Method</label>
                              </div>
                              <div class="form-control-group">
                                <input
                                  type="radio"
                                  id="payment_method"
                                  name="payment_method"
                                  v-model="form.payment_model"
                                  value="cash"
                                  @change="showPayment($event)"
                                />
                                &nbsp; Cash &nbsp;&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  id="payment_method"
                                  name="payment_method"
                                  v-model="form.payment_model"
                                  value="transfer"
                                  @change="showPayment($event)"
                                />
                                &nbsp; Transfer &nbsp;&nbsp;&nbsp;
                                <!-- <input
                                  type="radio"
                                  id="payment_method"
                                  name="payment_method"
                                  v-model="form.payment_model"
                                  value="card"
                                  @change="showPayment($event)"
                                /> -->
                                <!-- &nbsp; Card &nbsp;&nbsp;&nbsp; -->
                              </div>
                              <small
                                class="text-danger"
                                v-if="errors.payment_method"
                              >
                                {{ errors.payment_method[0] }}
                              </small>
                            </div>
                          </div>

                          <!--- Beginning of Card Payment -->
                          <div
                            class="col-md-12 cardpayment"
                            id="cardpayment"
                            v-if="isCard"
                          >
                            <div class="form-group">
                              <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                  <div class="form-group">
                                    <label class="form-label"
                                      >Payment Method</label
                                    >
                                    <span class="form-note"
                                      >Choose Your Payment Method</span
                                    >
                                  </div>
                                </div>
                                <div class="col-lg-7">
                                  <ul
                                    class="
                                      custom-control-group
                                      g-3
                                      align-center
                                      flex-wrap
                                    "
                                  >
                                    <li>
                                      <div class="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          class="custom-control-input"
                                          name="reg-public"
                                          id="reg-enable"
                                          value="paystack"
                                          v-model="form.payment_channel"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="reg-enable"
                                        >
                                          <img
                                            class="logo-img"
                                            src="template/src/images/paystack.jpg"
                                            alt="logo"
                                        /></label>
                                      </div>
                                    </li>
                                    <li>
                                      <!--<div class="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          class="custom-control-input"
                                          name="reg-public"
                                          id="reg-disable"
                                          value="flutterwave"
                                          v-model="form.payment_channel"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="reg-disable"
                                        >
                                          <img
                                            class="logo-img"
                                            src="template/src/images/flutterwave.png"
                                            alt="logo"
                                        /></label>
                                      </div>-->
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- End of Card Payment -->

                          <!--- Beginning of Cash Payment -->
                          <!-- <div class="col-md-12 cashpayment" id="cashpayment" v-if="isCash"> -->
                          <div class="col-md-12 cashpayment" id="cashpayment">
                            <div class="form-group">
                              <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                  <div class="form-group">
                                    <label class="form-label"
                                      >Payment Method</label
                                    >
                                    <span class="form-note">Pay to Bank</span>
                                  </div>
                                </div>
                                <div class="col-lg-7">
                                  <ul
                                    class="
                                      custom-control-group
                                      g-3
                                      align-center
                                      flex-wrap
                                    "
                                  >
                                    <li>
                                      <div class="custom-control">
                                        <table class="table">
                                         
                                          <tr id="naira_account">
                                            <td>
                                              <b>Account Type: ₦‎ Naira Account</b> <br />
                                              Bank Name: GT Bank (GTB)<br />
                                              Account No: 0383719716 <br />
                                              Account Name: Payhaven Ltd <br />
                                              SWIFT: GTBINGLA<br />
                                            </td>
                                          </tr>

                                          <tr id="dollar_account">
                                            <td>
                                              <b>Account Type: $ Dollar Account</b> <br />
                                              Bank Name: GT Bank (GTB)<br />
                                              Account No: 0383719747 <br />
                                              Account Name: Payhaven Ltd <br />
                                              SWIFT: GTBINGLA<br />
                                            </td>
                                          </tr>


                                          <tr id="euro_account">
                                            <td>
                                              <b>Account Type: € Euro Account</b> <br />
                                              Bank Name: GT Bank (GTB)<br />
                                              Account No: 0383721528  <br />
                                              Account Name: Payhaven Ltd <br />
                                              SWIFT: GTBINGLA<br />
                                            </td>
                                          </tr>


                                          <tr id="pound_account">
                                            <td>
                                              <b>Account Type: £ Pounds Account</b> <br />
                                              Bank Name: GT Bank (GTB)<br />
                                              Account No: 0383721528  <br />
                                              Account Name: Payhaven Ltd <br />
                                              SWIFT: GTBINGLA<br />
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              <small>Please note that your payment is covered by our 100% money-back guarantee. We only release your payment when we confirm receipt of the currency you are exchanging for.
                                              <br/>You have 30 mins to upload a payment verification. Complete your transaction and earn a Payhaven Badge *star* *star*</small>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- End of Cash Payment -->

                          <!--- Beginning of Transfer -->
                          <div
                            class="col-md-12 transfer"
                            id="transfer"
                            v-if="isTransfer"
                          >
                            <div class="form-group">
                              <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                  <div class="form-group">
                                    <label class="form-label"
                                      >Upload Payment Evidence</label
                                    >
                                    <span class="form-note">With Transfer</span>
                                  </div>
                                </div>
                                <div class="col-lg-7">
                                  <table>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td><input type="file" @change="handleFileUpload"/>
                                      <div id="uploadprogress"></div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- End of Trasfer -->
                        </div>
                        <!-- .row -->
                      </div>

                      <!-- nk-kycfm-content -->
                      <div class="nk-kycfm-footer">
                        <div class="nk-kycfm-action pt-2">
                          <button
                            type="submit"
                            id="submitBtn"
                            class="btn btn-lg btn-primary"
                          >
                            Process
                          </button>
                        </div>
                      </div>
                      <!-- nk-kycfm-footer -->
                    </div>
                    <!-- nk-kycfm -->
                  </div>
                  <!-- .card -->
                </div>
              </form>
              <!-- nk-block -->
            </div>
            <!-- .kyc-app -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      bankinfo: '',
      isCard: false,
      isTransfer: false,
      isCash: false,
      allCalculator: {},
      errors: {},
      paymentData: [],
      form: {
        ihave: "",
        ineed: "",
        amount: "",
        iget: "",
        payment_model: "",
        invoiceNumber: "",
        nameSender: "",
        payment_channel: "",
      },
      selectedFile: null,
    };
  },

  methods: {

    haveCurrency(event){
     if(event.target.value == "USD"){
      document.getElementById("dollar_account").style.display = "inline";
      document.getElementById("naira_account").style.display = "none";
      document.getElementById("euro_account").style.display = "none";
      document.getElementById("pound_account").style.display = "none";
     }else if(event.target.value == "NGN"){
      document.getElementById("dollar_account").style.display = "none";
      document.getElementById("naira_account").style.display = "inline";
      document.getElementById("euro_account").style.display = "none";
      document.getElementById("pound_account").style.display = "none";
     }else if(event.target.value == "EUR") {
      document.getElementById("dollar_account").style.display = "none";
      document.getElementById("naira_account").style.display = "none";
      document.getElementById("euro_account").style.display = "inline";
      document.getElementById("pound_account").style.display = "none";
     }else if(event.target.value == "GBP"){
      document.getElementById("dollar_account").style.display = "none";
      document.getElementById("naira_account").style.display = "none";
      document.getElementById("euro_account").style.display = "none";
      document.getElementById("pound_account").style.display = "inline";
     }else {
      document.getElementById("dollar_account").style.display = "none";
      document.getElementById("naira_account").style.display = "none";
      document.getElementById("euro_account").style.display = "none";
      document.getElementById("pound_account").style.display = "none";
     }
    },


    showPayment(event) {
      if (this.form.payment_model == "cash") {
        //console.log(event.target.value);
        this.isCash = !this.isCash;
        this.isCard = false;
        this.isTransfer = false;
      } else if (this.form.payment_model == "card") {
        //console.log(event.target.value);
        this.isCard = !this.isCard;
        this.isCash = false;
        this.isTransfer = false;
      } else if (this.form.payment_model == "transfer") {
        //console.log(event.target.value);
        this.isTransfer = !this.isTransfer;
        this.isCard = false;
        this.isCash = false;
      }
    },

    processCurrency() {
      this.$http
        .get(
          this.$appUrl + "latest/" + this.form.ihave + "/" + this.form.ineed,
          this.$config
        )
        .then(({ data }) => {
          //console.log(data.data[0].market_rate);
          if(data.status == 200){
            this.form.iget = data.data[0].market_rate * this.form.amount;
          }
          
        })
        .catch((err) => {
            this.form.iget = '';
            //this.$swal("Currency Combination is not available on the platform at the moment");
          this.errors =
            "Failed to fetch data, please try again or check your internet";
          //this.$swal(error);
        });
    },

    getCurrency() {
      this.$http
        .get(this.$appUrl + "get_currency", this.$config)
        .then(({ data }) => {
          //console.log(data);
          this.allCalculator = this.$globeFunc.sortArray(data.data);
        })
        .catch((error) => {
          this.errors =
            "Failed to fetch data, please try again or check your internet";
          //this.$swal(error);
        });
    },

    handleFileUpload(event){
      //console.log(event);
      //this.form.file = this.$refs.file.files[0];
      this.selectedFile = event.target.files[0];
    },

    postCurrency() {
     
      if(this.form.payment_model == "card" && this.form.ihave !== "NGN"){ 
        this.$swal("Paystack or Flutterwave only accept NGN payment for now. Change ihave to NGN");
        return;
      }
       
      
      if (
        !this.form.ihave ||
        !this.form.ineed ||
        !this.form.payment_model ||
        !this.form.amount ||
        !this.form.iget
      ) {
        this.$systoken.processing("Process", "submitBtn", false);
        this.$swal("Please make sure all fields are filled");
      } else {
        this.$systoken.processing("Processing...", "submitBtn", true);
        
        const fdata = new FormData();
        fdata.append('image', this.selectedFile);
        fdata.append('base', this.form.ihave);
        fdata.append('target', this.form.ineed);
        fdata.append('amount', this.form.amount);
        fdata.append('iget', this.form.iget);
        fdata.append('payment_method', this.form.payment_model);
        fdata.append('invoiceNumber', this.form.invoiceNumber);
        fdata.append('nameSender', this.form.nameSender);
        fdata.append('payment_channel', this.form.payment_channel);

        //console.log(this.selectedFile)

        axios.post(this.$appUrl + "transactions", fdata, this.$config, {
          onUploadProgressHandler: (progressEvent) => {
            document.getElementById('uploadprogress').innerHTML = Math.round(progressEvent.loaded / progressEvent.total * 100) + '%';
            //console.log('Upload Progress' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%');
          }
        }).then((response) => {
          if (response.data.status == 200) {
               //console.log(response.data);
              this.$systoken.processing("Redirecting...", "submitBtn", true);
              let results = [];
              results.push({
                id: response.data.data.id,
                qid: response.data.data.uniq_identify,
                start_time: response.data.data.start_date_time,
                base: this.form.ihave,
                target: this.form.ineed,
                iget: this.form.iget,
                amount: this.form.amount,
                payment_method: this.form.payment_model,
              });
              //console.log(results);
              if (response.data.data.id) {
                //console.log(results[0]);
                this.$router.push({
                  name: "process_payment",
                  params: { payID: results[0].qid },
                  query: { mid: results[0].id },
                });
              }
            }else{
              console.log(response.data.data);
              this.$systoken.processing("Process", "submitBtn", false);
              this.$swal(response.data.data);
              this.$router.push({ name: "update_profile" });
            }
        })
        .catch((err) => {
          this.$systoken.processing("Process", "submitBtn", false);
            console.log(err.response.data.errors);
            for(let key in err.response.data.errors){
              this.$swal(err.response.data.errors[key][0]);
            }
        });
    

      }
    },
  },

  mounted() {
    this.getCurrency();
  },
};
</script>

<style scoped>
#naira_account, #dollar_account, #pound_account, #euro_account {
  display: none;
}
</style>