
export default {
    userObject(state) {
        return state.user;
    },

    token(state) {
        return state.token;
    },

    isAuthenticated(state){
        return !!state.token
    }
}


/*
export default {
    userId(state) {
        return state.userId;
    },

    token(state){
        return state.token;
    },
    isAuthenticated(state){
        return !!state.token
    }
}
*/