// import Vue from 'vue';
import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'

////////////VUE X IMPLEMENTATION ////////////
//# Create the store
import store from './store/index.js'

//axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'http://localhost:8000/api/';

//Sweet Alert
import Sweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

//Routes
import router from './routes';

import User from './components/helpers/user';
window.User = User;

import SystemAppToken from './components/helpers/appstorage';
window.SystemAppToken = SystemAppToken;

import globFunction from './components/helpers/functions';
window.globFunction = globFunction;

//Global Headers
let iconfig = {};
let token;
token = localStorage.getItem("AuthToken");
iconfig = {
  headers: {
    accept: "application/json",
    Authorization: "Bearer " + token,
    //Authorization: `${token}`,
  },
};


//Slots
import ProfileHeader from './components/slots/profile_header_info.vue';

//Global Configurations
import Header from './components/header/header.vue';
import Sidebar from "./components/sidebar/sidebare.vue";
import Footer from "./components/footer/footer.vue";

//Loading Spinner
import Loader from "./components/shimmer/loadertable.vue";

//Slots
import slotsInner from "./components/slots/header_slots.vue";

//Modal
import Modal from "./components/shimmer/modal.vue";




const app = createApp(App);
app.use(router);
app.use(store);
app.use(Sweetalert2, options);

//app.config.globalProperties.$appUrl = 'http://localhost:8000/api/';
app.config.globalProperties.$appUrl = 'https://payhaven.ng/api/';
app.config.globalProperties.$http = axios;
app.config.globalProperties.$config = iconfig;
app.config.globalProperties.$user = User;
app.config.globalProperties.$systoken = SystemAppToken;
app.config.globalProperties.$globeFunc = globFunction;







app.component('profile-header', ProfileHeader);
app.component('top-header', Header);
app.component('main-sidebar', Sidebar);
app.component('main-footer', Footer);
app.component('shimmer', Loader);

app.component('inner-slot', slotsInner);
app.component('modal', Modal);

app.mount('#app');


