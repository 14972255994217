<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm"></div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Role: {{ pageName }}</h4>
                    <p>
                      Assign Permissions and Menu Priviledges to
                      <strong class="text-primary">{{ pageName }} </strong> role
                      to enable them have access
                    </p>
                    <div class="nk-block-head-sub">
                      <router-link class="back-to" to="/view_role"
                        ><em class="icon ni ni-arrow-left"></em
                        ><span>Back</span></router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <h3>Assign Menu</h3>
                      <hr />

                      <form @submit.prevent="postMenus" class="gy-3">
                        <table class="table table-bordered table-hover">
                          <tr>
                            <th><input type="checkbox" />All</th>
                            <th>Name</th>
                            <th>Class</th>
                            <th>Type</th>
                            <th>URL</th>
                            <th>Status</th>
                          </tr>

                          <tr v-for="m in allMenus" :key="m.id">
                            <td>
                              <input
                                type="checkbox"
                                name="menuOptions"
                                id="checkbox"
                                :value="m.id"
                                v-model="menuOptions"
                              />
                            </td>
                            <td>{{ m.name }}</td>
                            <td>{{ m.menu_class }}</td>
                            <td>{{ m.menu_type }}</td>
                            <td>{{ m.url }}</td>
                            <td>
                              <!-- {{ checkStatus(pageId, m.id) }} -->
                            </td>
                          </tr>
                        </table>
                        <p class="pushDown">
                          <button
                            type="submit"
                            class="btn btn-sm btn-secondary"
                          >
                            <em class="icon ni ni-setting"></em>Add Menu
                          </button>
                        </p>
                      </form>

                      <hr />

                      <h3>Give Permission</h3>
                      <hr />

                      <form @submit.prevent="givePermissionToRole" class="gy-3">
                        <ul v-for="p in allPermissions" :key="p.id">
                          <li>
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="checkbox"
                              :value="p.name"
                              v-model="rolePermission"
                            />
                            {{ p.name }}
                          </li>
                        </ul>

                        <p>
                          <button
                            type="submit"
                            class="btn btn-sm btn-secondary"
                          >
                            <em class="icon ni ni-setting"></em>Add Permission
                          </button>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      allPermissions: [],
      allMenus: [],
      error: null,
      pageName: "",
      pageId: "",
      menuOptions: [],
      rolePermission: [],
    };
  },

  methods: {
    checkStatus(pID, menuID) {
      // this.$http
      //   .get(
      //     this.$appUrl + "get_active_menu_status/" + pID + "/" + menuID,
      //     this.$config
      //   )
      //   .then(({ data }) => {
      //     // console.log(data);
      //     if (data.changeStats == true) {
      //       return "Active";
      //     } else {
      //       return "Inactive";
      //     }
      //   });
    },

    givePermissionToRole() {
      let resultPermission = [];
      this.rolePermission.forEach((el) => {
        resultPermission.push(el);
        console.log(resultPermission);
        if (this.rolePermission.length > 0) {
          this.$http
            .post(
              this.$appUrl + "assign_permission_role",
              {
                role_id: this.$route.params.id,
                permissions: resultPermission,
              },
              this.$config
            )
            .then((response) => {
              if (response.data.status == 200) {
                console.log(response.data.status);
                this.$swal("Permissions Successfully Assigned");
                this.$router.push({ name: "view_role" });
              }
            })
            .catch((err) => {
              //console.log(err.response.data.message);
              this.error = err.response.data.message;
              this.$swal(err.response.data.message);
            });
        }
      });
    },
    postMenus() {
      let userdetails = JSON.parse(this.$user.getUserName());
      //console.log(userdetails.id);
      let result = [];
      this.menuOptions.forEach((el) => {
        result.push(el);
        console.log(result);
      });
      if (this.menuOptions.length > 0) {
        this.$http
          .post(
            this.$appUrl + "assign_menu_role",
            {
              role_id: this.$route.params.id,
              menus: result,
            },
            this.$config
          )
          .then((response) => {
            if (response.data.status == 200) {
              console.log(response.data.status);
              this.$swal("Menu Successfully Assigned");
              this.$router.push({ name: "view_role" });
            }
          })
          .catch((err) => {
            //console.log(err.response.data.message);
            this.error = err.response.data.message;
            this.$swal(err.response.data.message);
          });
      }
    },

    getPermission() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "post_permission", this.$config)
        .then(({ data }) => {
          // console.log(data.permission);
          this.loading = false;
          this.allPermissions = data.permission;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Unable to fetch permission, please check your internet or refresh. Alternatively, you can logout and login again";
          //this.$swal(error);
        });
    },

    getMenus() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "menu", this.$config)
        .then(({ data }) => {
          console.log(data.menu);
          this.loading = false;
          this.allMenus = data.menu;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Unable to fetch permission, please check your internet or refresh. Alternatively, you can logout and login again";
          //this.$swal(error);
        });
    },
  },

  mounted() {
    this.checkStatus();
    this.getPermission();
    this.getMenus();
    this.pageId = this.$route.params.id;
    this.pageName = this.$route.query.name;
  },
};
</script>

<style scoped>
.pushDown {
  margin-top: 20px;
}
</style>