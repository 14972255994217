import { createStore } from "vuex";

import AuthModule from './modules/auth/index.js';
import Transactions from './modules/transactions/index.js';
import Payments from './modules/payments/index.js';
import BDCs from './modules/bdc/index.js';
import Dashboard from './modules/dashboard/index.js';

const store = createStore({
    modules : {
        auth: AuthModule,
        trans: Transactions,
        pay: Payments,
        bdc: BDCs,
        dash: Dashboard
    }
})

export default store;