import axios from 'axios'
import initialLinks from '../../../constant';

export default {

       async getTransactions(context, payload){
            //const token = context.rootGetters.token;
           await axios.get(`${initialLinks[0].sourceLink}` + "get_dashboard", {
                headers: {
                accept: "application/json",
                Authorization: "Bearer " + context.rootGetters.token,
                }
           })
           .then((response) => {
                if (response.data.status == 200) {
                    context.commit('setTransaction', response.data.data);
                }
           })
           .catch((err) => {
                console.log(err)
           })

        }
}