<template>
  <div class="nk-content nk-content-lg nk-content-fluid">
    <div class="container-xl wide-sm">
      <div class="nk-content-inner">
        <div class="nk-content-body">
          <div class="kyc-app wide-sm m-auto">
            <!-- nk-block -->

            <form @submit.prevent="postBDCurrency">
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="nk-kycfm">
                    <div class="nk-kycfm-head">
                      <div class="nk-kycfm-count">00</div>
                      <profile-header>
                        <template v-slot:headerInfo="slotProps">
                          <div class="nk-kycfm-title">
                            <h5 class="title">
                              {{ slotProps.userItems.name }}
                            </h5>
                            <p class="sub-title">
                              {{ slotProps.userItems.email }}
                            </p>
                          </div>
                        </template>
                      </profile-header>
                    </div>
                    <!-- nk-kycfm-head -->
                    <div class="nk-kycfm-content">
                      <div class="nk-kycfm-note">
                        <em
                          class="icon ni ni-info-fill"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Tooltip on right"
                        ></em>
                        <p>
                          Please note based on your price list, buyers will
                          response to the post. if after 1hr of request, no
                          response from you based on your post, the transaction
                          will be cancelled and post disabled.
                        </p>
                      </div>
                      <div class="row g-4">
                        <div class="col-md-6">
                          <div class="form-group">
                            <div class="form-label-group">
                              <label class="form-label"
                                >I Have
                                <span class="text-danger">*</span></label
                              >
                            </div>
                            <div class="form-control-group">
                              <select
                                v-model="form.ihave"
                                class="form-control form-control-lg"
                              >
                                <option value="">Select</option>
                                <option
                                  v-for="cur in allBDCurrencies"
                                  :key="cur.id"
                                  :value="cur.approved_currency"
                                >
                                  {{ cur.approved_currency }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <!-- .col -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <div class="form-label-group">
                              <label class="form-label"
                                >Whats the Total Amount
                                <span class="text-danger">*</span></label
                              >
                            </div>
                            <div class="form-control-group">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                v-model="form.amount"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- .col -->

                        <div class="col-md-6">
                          <div class="form-group">
                            <div class="form-label-group">
                              <label class="form-label"
                                >I Need
                                <span class="text-danger">*</span></label
                              >
                            </div>
                            <div class="form-control-group">
                              <select
                                v-model="form.ineed"
                                class="form-control form-control-lg"
                              >
                                <option value="">Select</option>
                                <option
                                  v-for="cur in allBDCurrencies"
                                  :key="cur.id"
                                  :value="cur.approved_currency"
                                >
                                  {{ cur.approved_currency }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <!-- .col -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <div class="form-label-group">
                              <label class="form-label">At What Rate {{ this.form.ihave }}</label>
                            </div>
                            <div class="form-control-group">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                v-model="form.rate"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- .col -->
                      </div>
                      <!-- .row -->
                    </div>

                    <!-- nk-kycfm-content -->
                    <div class="nk-kycfm-footer">
                      <div class="nk-kycfm-action pt-2">
                        <button type="submit" class="btn btn-lg btn-primary">
                          POST
                        </button>
                      </div>
                    </div>
                    <!-- nk-kycfm-footer -->
                  </div>
                  <!-- nk-kycfm -->
                </div>
                <!-- .card -->
              </div>
            </form>
            <!-- nk-block -->
          </div>
          <!-- .kyc-app -->
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      allBDCurrencies: {},
      error: {},
      form: {
        ihave: "",
        ineed: "",
        amount: "",
        rate: "",
      },
    };
  },

  mounted() {
    this.getCurrency();
  },

  methods: {
    getCurrency() {
      this.$http
        .get(this.$appUrl + "get_currency", this.$config)
        .then(({ data }) => {
          console.log(data);
          this.allBDCurrencies = this.$globeFunc.sortArray(data.data);
        })
        .catch((error) => {
          this.error =
            "Failed to fetch data, please try again or check your internet";
          //this.$swal(error);
        });
    },

    postBDCurrency() {
      if (
        !this.form.ihave ||
        !this.form.ineed ||
        !this.form.amount ||
        !this.form.rate
      ) {
        this.$swal("Please fill out all fields");
      } else {
        this.$http
          .post(
            this.$appUrl + "post_bdc",
            {
              i_have: this.form.ihave,
              i_need: this.form.ineed,
              my_rate: this.form.rate,
              amount: this.form.amount,
            },
            this.$config
          )
          .then((response) => {
            //console.log(response.data)
            if (response.data.status == 200 && response.data.data != 'ERROR') {
              console.log(response.data);
              this.$swal("Currency Exchange Successfully Posted");
              this.$router.push({ name: "sell_currency" });
              this.form.ihave = "";
              this.form.ineed = "";
              this.form.rate = "";
              this.form.amount = "";
            }else{
               this.$swal(response.data.message + "<a href='buyplan'> <br/><hr/>click here</a> to upgrade");
              console.log(response.data.message)
            }
          })
          .catch((err) => {
           
            this.$swal("Error Posting Currency");
            console.log(err.message);
          });
      }
    },
  },
};
</script>