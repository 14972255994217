<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content nk-content-lg nk-content-fluid">
      <div class="container-xl wide-lg">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="kyc-app wide-sm m-auto">
              <div class="nk-block-head nk-block-head-lg wide-xs mx-auto">
                <div class="nk-block-head-content text-center">
                  <h2 class="nk-block-title fw-normal">Complete Payment</h2>
                  <div class="nk-block-des">
                    <p>
                      One last step to completing your transaction
                      <strong>You are</strong> almost there.
                    </p>
                  </div>
                </div>
              </div>

              <form @submit.prevent="postCurrency">
                <!-- nk-block -->
                <div class="nk-block">
                  <div class="card card-bordered">
                    <div class="nk-kycfm">
                      <!-- nk-kycfm-head -->
                      <div class="nk-kycfm-content">
                        <div class="nk-kycfm-note">
                          <em
                            class="icon ni ni-info-fill"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Tooltip on right"
                          ></em>
                          <p>
                            To complete your transaction, kindly ensure you
                            select the preferred bank. The bank selected must be
                            able to accept your base currency
                          </p>
                        </div>

                        <form @submit.prevent="saveTransaction">
                          <!-- nk-block -->
                          <div class="nk-block">
                            <div class="card card-bordered">
                              <div class="nk-kycfm">
                                <!-- nk-kycfm-head -->
                                <div class="nk-kycfm-content">
                                  <div class="row g-4">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <div class="form-label-group">
                                          <label class="form-label"
                                            >You Have
                                          </label>
                                        </div>
                                        <div class="form-control-group">
                                          <select
                                            class="form-control form-control-lg"
                                            disabled
                                          >
                                            <option value="" selected>
                                              {{ transact.base }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- .col -->
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <div class="form-label-group">
                                          <label class="form-label"
                                            >Amount
                                          </label>
                                        </div>
                                        <div class="form-control-group">
                                          <input
                                            type="text"
                                            class="form-control form-control-lg"
                                            :value="transact.amount"
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <!-- .col -->

                                    <!-- .col -->
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <div class="form-label-group">
                                          <label class="form-label"
                                            >You Need
                                          </label>
                                        </div>
                                        <div class="form-control-group">
                                          <select
                                            class="form-control form-control-lg"
                                            disabled
                                          >
                                            <option value="" selected>
                                              {{ transact.target }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- .col -->
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <div class="form-label-group">
                                          <label class="form-label"
                                            >You Get
                                          </label>
                                        </div>
                                        <div class="form-control-group">
                                          <input
                                            type="text"
                                            class="form-control form-control-lg"
                                            disabled
                                            :value="transact.iget"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <!-- .col -->

                                    <input
                                      type="hidden"
                                      :value="transact.uniq_identify"
                                      ref="uniqueID"
                                    />
                                    <input
                                      type="hidden"
                                      :value="transact.payment_method"
                                      ref="paymentType"
                                    />
                                    <input
                                      type="hidden"
                                      :value="transact.buyer_user_id"
                                      ref="userID"
                                    />
                                    <input
                                      type="hidden"
                                      :value="transact.payment_method"
                                      ref="payment_method"
                                    />
                                    <input
                                      type="hidden"
                                      :value="transact.payment_channel"
                                      ref="payment_channel"
                                    />

                                    <!--- Beginning of Transfer -->
                                    <div class="col-md-12">
                                      Select the bank you would like us to
                                      transfer fund to:
                                      <hr />

                                      <div class="form-control-group">
                                        <select
                                          class="form-control form-control-lg"
                                          v-model="form.selectBank"
                                        >
                                          <option value="" selected>
                                            Select Account
                                          </option>
                                          <option
                                            v-for="bx in myBank"
                                            :key="bx.id"
                                            :value="bx.id"
                                          >
                                            {{ bx.bank_name }} &nbsp; - &nbsp;{{
                                              bx.account_name
                                            }}
                                            &nbsp;- &nbsp;
                                            {{ bx.account_number }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <!-- End of Trasfer -->
                                  </div>
                                  <!-- .row -->
                                </div>

                                <!-- nk-kycfm-content -->
                                <div class="nk-kycfm-footer">
                                  <div class="nk-kycfm-action pt-2">
                                    <button
                                      type="submit"
                                      id="submitBtn"
                                      class="btn btn-lg btn-primary"
                                    >
                                      Pay Now
                                    </button>
                                  </div>
                                </div>
                                <!-- nk-kycfm-footer -->
                              </div>
                              <!-- nk-kycfm -->
                            </div>
                            <!-- .card -->
                          </div>
                        </form>

                        <!-- .row -->
                      </div>
                    </div>
                    <!-- nk-kycfm -->
                  </div>
                  <!-- .card -->
                </div>
              </form>
              <!-- nk-block -->
            </div>
            <!-- .kyc-app -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
      transact: [],
      myBank: [],
      form: {
        uniqueID: "",
        selectBank: "",
      },
    };
  },

  created() {
    console.log(this.$route.params.payID);
    this.getbankOptions();
    this.getMyBank();
  },

  methods: {
    saveTransaction() {
      this.$systoken.processing("Processing...", "submitBtn", true);
      if (!this.$refs.uniqueID) {
        this.$swal("Parameter needed to render page is missing");
      } else if (!this.form.selectBank) {
        this.$swal(
          "Please the bank you want to credit. Ensure it accept the target currency"
        );
        this.$systoken.processing("Pay Now", "submitBtn", false);
      } else {
        this.$http
          .post(
            this.$appUrl + "makepayment",
            {
              uniqueID: this.$refs.uniqueID.value,
              paymentType: this.$refs.paymentType.value,
              selectBank: this.form.selectBank,
              user_id: this.$refs.userID.value,
              payment_method: this.$refs.payment_method.value,
              payment_channel: this.$refs.payment_channel.value,
            },
            this.$config
          )
          .then((response) => {
            if (response.data.status == 200) {
              // alert(response.data.data.channel);
              //console.log(response.data.data);
              if (response.data.data.channel == "paystack") {
                this.$router.push({
                  name: "process_payment_paystack",
                  params: { uniqID: response.data.data.id },
                });
              } else {
                this.$swal(
                  "Payment Successfully Made. We will confirm and credit immediately"
                );
                this.$router.push({
                  name: "all_transactions",
                });
              }
            } else {
              console.log(response.data);
              this.$swal(response.data.data);
              this.$systoken.processing("Pay Now", "submitBtn", false);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$swal(err.message);
            this.$systoken.processing("Pay Now", "submitBtn", false);
          });
      }
    },

    getMyBank() {
      this.$http
        .get(this.$appUrl + "get_my_bank", this.$config)
        .then(({ data }) => {
          console.log(data.mbank);
          this.myBank = data.mbank;
        })
        .catch((error) => {
          // console.log(error.message);
          this.errors = error.message;
          this.$swal(this.errors);
        });
    },

    getbankOptions() {
      this.$http
        .get(
          this.$appUrl + "transactions/" + this.$route.params.payID,
          this.$config
        )
        .then(({ data }) => {
          //console.log(data);
          this.transact = data.data;
        })
        .catch((error) => {
          // console.log(error.message);
          this.errors = error.message;
          this.$swal(this.errors);
        });
    },
  },
};
</script>

<style scoped>
</style>