import { createRouter, createWebHistory } from "vue-router";

//------------------- DASHBOARD ROUTE -------------------------//
import Homepage from './components/dashboard/dashboard.vue';
import Login from './components/auth/login.vue';
import Register from './components/auth/register.vue';
import Thankyou from './components/auth/thankyou.vue';
import ForgotPass from './components/auth/forgotpass.vue';

//-------------------AUTHENTICATION ROUTE ---------------------//


//ALL ADMINISTRATIVE ROUTE -- Stored in the Administrative Folder --
import ViewCurrencies from './components/administrative/currencies/view_all_currencies.vue';
import AddCurrency from './components/administrative/currencies/add_new_currency.vue';
import ExchangeArea from './components/administrative/currencies/exchange_rate.vue';
import AllUser from './components/administrative/users/users.vue';

import AddRole from './components/administrative/users/add_roles.vue';
import AddPlan from './components/administrative/plans/add_plans.vue';

import ViewRole from './components/administrative/users/view_roles.vue';
import viewPlan from './components/administrative/plans/view_plan.vue';
import ViewRolePermission from './components/administrative/users/view_role_permission.vue';

/////////////////////////LOGOUT ROUTE //////////////////////////////////
import Logout from './components/auth/logout.vue';

/////////////////////////////BDC ROUTE ////////////////////////////////////
import Currencymain from './components/bdcs/currencies/currency_main.vue';

//////////////////////////CURRENCY CALCULATOR ///////////////////////////
import CurrencyCalculator from './components/calculator/currency_calculator.vue';
import ContactUs from './components/contact/contactus.vue';
import Exchanges from './components/administrative/transactions/all_exchange.vue';
import EditExchangeArea from './components/administrative/currencies/edit_exchange_area.vue';
import UpdateProfile from './components/account/update_account.vue';
import PaymentProcessor from './components/calculator/payment_processor.vue';
import Transactions from './components/administrative/transactions/all_transactions.vue';
import AddPermissions from './components/administrative/permissions/add_new_permission.vue';
import AllPermissions from './components/administrative/permissions/view_permission.vue';
import Complaints from './components/account/contact_us.vue';
import ExchangeListings from './components/administrative/listings/bdc_listings.vue';
import NewRole from './components/administrative/users/new_role.vue';
import AllMenu from './components/administrative/menu/menu_management.vue';

import PaymentProcessorPayStack from './components/calculator/payment_processor_paystack.vue';
import Payments from './components/payments/payments.vue';
import PaymentsComplete from './components/payments/payment_complete.vue';
import PaymentsPlanComplete from './components/payments/plan_payment_complete.vue';




////////////////////************** CURRENCY DETAILS ROUNT *****************/////////////////////////////////


import CurrencyDetailsBuy from './components/bdcs/currencies/currency_details_buy.vue';
import CurrencyDetails from './components/bdcs/currencies/currency_details.vue';


///////////////////////////////////////* UPGRADE PLAN **********************/////////////////////////////////

import UpgradePlan from './components/administrative/plans/buy_plan.vue';
import MoneyPost from './components/moneypost/exchange.vue';


import store from './store/index.js';

const routerHistory = createWebHistory();
 
const routesInfo = [
  { path: "/", component: Login, name: '/', meta: {requiresUnAuth: true} },
  { path: "/login", component: Login, name: 'login', meta: {requiresUnAuth: true} },
  { path: "/register", component: Register, name: 'register' },
  { path: "/thanks", component: Thankyou, name: 'thanks' },
  { path: "/forgot_pass", component: ForgotPass, name: 'forgotpass' },


////////////////////************** AUTHENTICATION ROUTE *****************/////////////////////////////////

   { path: "/logout", component: Logout, name: 'logout' },
   { path: "/dashboard", component: Homepage, name: 'dashboard', meta: {requiresAuth: true} },
   { path: "/view_currencies", component: ViewCurrencies, name: 'view_currencies' },
   { path: "/add_currency", component: AddCurrency, name: 'add_currency' },
   { path: "/exchange_area", component: ExchangeArea, name: 'exchange_area' },
   { path: "/all_users", component: AllUser, name: 'all_users' },
   { path: "/add_role", component: AddRole, name: 'add_role' },
   { path: "/add_plan", component: AddPlan, name: 'add_plan' },
   { path: "/view_role", component: ViewRole, name: 'view_role' },
   { path: "/view_plan", component: viewPlan, name: 'view_plan' },
   { path: "/role_permissions/:id", component: ViewRolePermission, name: 'role_permissions' },
   { path: "/all_exchange", component: Exchanges, name: 'all_exchange' },
   { path: '/exchange_areas/:id', component: EditExchangeArea, name: 'exchange_areas' },
    
//////////////************************BDCS *******************************/////////////////////////////////
{ path: "/sell_currency", component: Currencymain, name: 'sell_currency', meta: {requiresAuth: true}  },
{ path: "/process_payment/:payID", component: PaymentProcessor, name: 'process_payment' },
   
{ path: "/process_payment_paystack/:uniqID", component: PaymentProcessorPayStack, name: 'process_payment_paystack' },

//////////////************************CURRENCY CALCULATOR ****************************/////////////////
   { path: "/currency_calculator", component: CurrencyCalculator, name: 'currency_calculator' },
   { path: "/contactus", component: ContactUs, name: 'contactus' },
   { path: "/update_profile", component: UpdateProfile, name: 'update_profile' },
   { path: "/all_transactions", component: Transactions, name: 'all_transactions' },
   { path: "/permissions", component: AddPermissions, name: 'permissions' },
   { path: "/all_permissions", component: AllPermissions, name: 'all_permissions' },
   { path: "/contact_us", component: Complaints, name: 'contact_us' },
   { path: "/exchange_listings", component: ExchangeListings, name: 'exchange_listings' },


   { path: "/new_role/:id", component: NewRole, name: 'new_role' },
   { path: "/menu_management", component: AllMenu, name: 'menu_management' },


   { path: "/payments", component: Payments, name: 'payments' },
   { path: "/payment_complete/:reference", component: PaymentsComplete, name: 'payment_complete' },
   { path: "/plan_payment_complete/:reference", component: PaymentsPlanComplete, name: 'plan_payment_complete' },
   //////////////************************VIEW CURRENCY FOR BDC ****************************///////
  //{ path: "/sell_currency/:id", component: Currencymain, name: 'sell_currency' },
  { path: "/currency_details_buy/:cid", component: CurrencyDetailsBuy, name: 'currency_details_buy', meta: {requiresAuth: true}  },
  { path: "/currency_details/:cid", component: CurrencyDetails, name: 'currency_details', meta: {requiresAuth: true}  },
  { path: "/buyplan", component: UpgradePlan, name: 'buyplan' },
  { path: "/money_post", component: MoneyPost, name: 'money_post' },
   

  

];

const router = createRouter({
  history: routerHistory,
  routes: routesInfo
});


//Navigation guard
router.beforeEach(function(to, _, next){
   if(to.meta.requiresAuth && !store.getters.isAuthenticated){
       next('/login')
   }else if(to.meta.requiresUnAuth && store.getters.isAuthenticated){
       next('/dashboard')
   }else{
       next();
   }
});

export default router;
