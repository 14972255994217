import axios from 'axios'
import initialLinks from '../../../constant';

export default {

     // Get all dashboard data Statistic
       async getDashboardStats(context, payload){
          //console.log(initialLinks[0].sourceLink);
            //const token = context.rootGetters.token;
           await axios.get(`${initialLinks[0].sourceLink}` + "get_dashboard_summary", {
                headers: {
                accept: "application/json",
                Authorization: "Bearer " + context.rootGetters.token,
                }
           })
           .then((response) => {
               console.log(response.status);
                if (response.status == 200) {
                    console.log(response.data);
                    context.commit('setDashboard', response.data);
                }
           })
           .catch((err) => {
                console.log(err)
           })

        },

        // Get all latest active currincies  purchase_currency  |  get_buy_sell_currency
        async getActiveCurrency(context, payload){  
           await axios.get(`${initialLinks[0].sourceLink}` + "get_buy_sell_currency", {
                headers: {
                accept: "application/json",
                Authorization: "Bearer " + context.rootGetters.token,
                }
           })
           .then((response) => {
               console.log(response.status);
                if (response.status == 200) {
                    console.log(response.data);
                    context.commit('setSellCurrency', response.data);
                }
           })
           .catch((err) => {
                console.log(err)
           })

        },



         // Get all dashboard data Statistic
       async getBoughtCurrency(context, payload){
          //console.log(initialLinks[0].sourceLink);
            //const token = context.rootGetters.token;
           await axios.get(`${initialLinks[0].sourceLink}` + "purchase_currency", {
                headers: {
                accept: "application/json",
                Authorization: "Bearer " + context.rootGetters.token,
                }
           })
           .then((response) => {
               console.log(response.status);
                if (response.status == 200) {
                    console.log(response.data);
                    context.commit('boughtCurrency', response.data.data);
                }
           })
           .catch((err) => {
                console.log(err)
           })

        }


}