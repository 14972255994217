<template>
  <div class="nk-block nk-block-middle nk-auth-body">
    <div class="brand-logo pb-5">
      <a href="/" class="logo-link">
        <img
          class="logo-light logo-img logo-img-lg"
          src="template/demo1/src/images/payhaven/logo.png"
          srcset="template/demo1/src/images/payhaven/logo.png"
          alt="logo"
        />
        <img
          class="logo-dark logo-img logo-img-lg"
          src="template/demo1/src/images/payhaven/logo.png"
          srcset="template/demo1/src/images/payhaven/logo.png"
          alt="logo-dark"
        />
      </a>
    </div>
    <div class="nk-block-head">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title">Thank you for submitting form</h4>
        <div class="nk-block-des text-danger">
          <p>
            Activation email has been sent to you, kindly login to your email to
            activate your account.
          </p>
          <router-link to="login" class="btn btn-xs btn-secondary"
            >Sign In
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>