<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm"></div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Feedback / Complaints</h4>
                    <p>
                      The following lists all the
                      <strong class="text-primary">complaints</strong> from the
                      platform to be treated.
                    </p>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <shimmer v-if="loading" class="paddingLoader"></shimmer>
                      <p class="makeSpace" v-else-if="!loading && error">
                        {{ error }}
                      </p>

                      <p
                        class="makeSpace"
                        v-else-if="
                          !loading &&
                          (!allComplaints || allComplaints.length === 0)
                        "
                      >
                        No Feedback/Complaint Found
                      </p>
                      <!-- class="datatable-init table" -->
                      <table class="table table-bordered" v-else>
                        <thead>
                          <tr class="bg bg-secondary" style="color: white">
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>Date Created</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="cc in allComplaints" :key="cc.id">
                            <td>{{ cc.id }}</td>
                            <td>{{ cc.name }}</td>
                            <td>{{ cc.email }}</td>
                            <td>{{ cc.subject }}</td>
                            <td>{{ cc.message }}</td>
                            <td>{{ cc.created_at }}</td>

                            <td class="tb-tnx-action">
                              <div class="dropdown">
                                <a
                                  class="
                                    text-soft
                                    dropdown-toggle
                                    btn btn-icon btn-trigger
                                  "
                                  data-toggle="dropdown"
                                  ><em class="icon ni ni-more-h"></em
                                ></a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-xs
                                  "
                                >
                                  <ul class="link-list-plain">
                                    <li><a href="#">Treat</a></li>
                                    <li><a href="#">Send Email</a></li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      allComplaints: [],
      searchComplain: "",
      error: null,
    };
  },

  methods: {
    getUsers() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "complaints", this.$config)
        .then(({ data }) => {
          this.loading = false;
          this.allComplaints = data.data;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Failed to fetch data, please try again or check your internet";
        });
    },
  },

  mounted() {
    this.getUsers();
  },
};
</script>

<style scoped>
</style>