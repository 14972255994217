export default {
    setDashboard(state, payload){
        state.dashboard = payload
    },

    setSellCurrency(state, payload){
        state.sell_currency = payload
    },

    boughtCurrency(state, payload){
        state.bought_currency = payload
    }
};