<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

        <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm"></div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <inner-slot>
                      <template #header> Currency Details </template>

                      <template #tagline>
                        
                        <strong class="text-primary">CURRENCY DETAILS</strong>
                      
                      </template>
                    </inner-slot>
                  </div>
                </div>


                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <!-- class="datatable-init table" -->

                                <div class="nk-block">
                                    <div class="row gy-5">
                                        <div class="col-lg-5">
                                            <div class="nk-block-head">
                                                <div class="nk-block-head-content">
                                                    <h5 class="nk-block-title title">Seller Information</h5>
                                                    <p>Details of the seller of the curency.</p>
                                                </div>
                                            </div><!-- .nk-block-head -->
                                            <div class="card card-bordered">
                                                <ul class="data-list is-compact">
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Full Name</div>
                                                            <div class="data-value">{{ fullName }}</div>
                                                        </div>
                                                    </li>
                                                   
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Status</div>
                                                            <div class="data-value" v-if="u_status == 'Verified'">
                                                                <span class="badge badge-dim badge-sm badge-outline-success">{{ u_status }}</span>
                                                            </div>
                                                             <div class="data-value" v-if="u_status == '0'">
                                                                <span class="badge badge-dim badge-sm badge-outline-danger">{{ u_status }}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    
                                                    <!--<li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Last Checked At</div>
                                                            <div class="data-value">19 Dec, 2019 05:26 AM</div>
                                                        </div>
                                                    </li>-->
                                                </ul>
                                            </div><!-- .card -->
                                            <div class="nk-block-head">
                                                <div class="nk-block-head-content">
                                                    <h5 class="nk-block-title title">Buyers Information</h5>
                                                    <p>Details of the buyer of the Currency.</p>
                                                </div>
                                            </div><!-- .nk-block-head -->
                                            <div class="card card-bordered">
                                                <ul class="data-list is-compact">
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Document Type</div>
                                                            <div class="data-value">coming soon..</div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Front Side</div>
                                                            <div class="data-value">coming soon..</div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Back Side</div>
                                                            <div class="data-value">coming soon..</div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Proof/Selfie</div>
                                                            <div class="data-value">coming soon..</div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div><!-- .card -->
                                        </div><!-- .col -->
                                        <div class="col-lg-7">
                                            <div class="nk-block-head">
                                                <div class="nk-block-head-content">
                                                    <h5 class="nk-block-title title">Currency Information</h5>
                                                    <p>Currency Exchange Information and Price.</p>
                                                </div>
                                            </div>
                                            <div class="card card-bordered">
                                                <ul class="data-list is-compact">
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Available Currency</div>
                                                            <div class="data-value">{{ ihave }}</div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Target Currency</div>
                                                            <div class="data-value">{{ ineed }}</div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Selling Rate for {{ ihave }}</div>
                                                            <div class="data-value">{{ myrate }}</div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Amount</div>
                                                            <div class="data-value text-soft"><em>{{ amount }}</em></div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Total Amount </div>
                                                            <div class="data-value">{{ ineed }} {{ total }} </div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Currency Status</div>
                                                            <div class="data-value">{{ status }}</div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Date Posted</div>
                                                            <div class="data-value">{{ datePosted }}</div>
                                                        </div>
                                                    </li>
                                                    <li class="data-item">
                                                        <div class="data-col">
                                                            <div class="data-label">Date Updated</div>
                                                            <div class="data-value">{{ dateUpdated }}</div>
                                                        </div>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </div><!-- .col -->
                                    </div><!-- .row -->
                                </div><!-- .nk-block -->
                    
                    </div>
                  </div>
                </div>


                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>

import globalFunctions from '../../helpers/functions.js';

export default {
 
  data() {
      return {
          ihave : '',
          ineed : '',
          myrate: '',
          amount: '',
          total: '',
          status: '',
          datePosted: '',
          dateUpdated: '',
          user_name: '',
          u_status : '',
      }
  },

 computed: {
        fullName() {
            return this.bdcDetails.user_id;
        },

    },

  created() {
    this.bdcDetails = this.$store.getters['bdc/allBDC'].find((b) => b.cid === this.$route.params.cid);
    this.ihave = this.bdcDetails.i_have;
    this.ineed = this.bdcDetails.i_need;
    this.myrate = this.bdcDetails.my_rate;
    this.amount = globalFunctions.formatMoney(+this.bdcDetails.amount);
    this.total = globalFunctions.formatMoney(+this.bdcDetails.amount * +this.bdcDetails.my_rate);
    this.status = globalFunctions.checkStatus(this.bdcDetails.status);
    this.datePosted = this.bdcDetails.created_at;
    this.dateUpdated = this.bdcDetails.updated_at;
    this.user_name = this.bdcDetails.user_id;
    const { name, email_verified_at, status} = this.bdcDetails.user_details[0];
    this.u_status = status == 1 ? 'Verified' : 'Not Verified';
  },

 



}; 
</script>

<style scoped>
</style>