<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <div class="nk-block-head-sub">
                    <router-link class="back-to" to="/all_users"
                      ><em class="icon ni ni-arrow-left"></em
                      ><span>Back</span></router-link
                    >
                  </div>
                  <h2 class="nk-block-title fw-normal">Assign Role</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      You About to Assign Role to: {{ form.roleName }}, please
                      ensure you confirm role before assigning
                    </p>
                  </div>
                </div>
              </div>

              <!-- .nk-block -->
              <div class="nk-block nk-block-lg">
                <div class="card card-bordered">
                  <div class="card-inner">
                    <div class="card-head">
                      <h5 class="card-title">Assign Role Setup</h5>
                    </div>
                    <form @submit.prevent="changeRole" class="gy-3">
                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">User Name</label>
                            <span class="form-note">Name of the User</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="roleName"
                                v-model="form.roleName"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Role Name</label>
                            <span class="form-note">Add Roles</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <select
                                class="form-control"
                                v-model="form.userRole"
                              >
                                <option
                                  v-for="role in roles"
                                  :key="role.id"
                                  :value="role.name"
                                >
                                  {{ role.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3">
                        <div class="col-lg-7 offset-lg-5">
                          <div class="form-group mt-2">
                            <button
                              type="submit"
                              class="btn btn-lg btn-primary"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- card -->
              </div>
              <!-- .nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roles: [],
      form: {
        roleName: "",
        userRole: "",
      },
    };
  },

  methods: {
    getUserName() {
      let userdetails = JSON.parse(this.$user.getUserName());
      this.form.roleName = userdetails.name;
    },

    getAllRoles() {
      this.$http
        .get(this.$appUrl + "post_role", this.$config)
        .then(({ data }) => {
          this.roles = data.role;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Unable to fetch permission, please check your internet or refresh. Alternatively, you can logout and login again";
          //this.$swal(error);
        });
    },

    changeRole() {
      if (!this.form.userRole) {
        this.$swal("Please select a role");
      } else {
        this.$http
          .put(
            this.$appUrl +
              "post_role/" +
              JSON.parse(this.$user.getUserName()).id,
            this.form,
            this.$config
          )
          .then((response) => {
            if (response.data.status == 200) {
              console.log(response.data.role);
              this.$swal("Role Successfuly Assigned");
              this.$router.push({ name: "all_users" });
            }
          })
          .catch((err) => {
            //console.log(err.response.data.data);
            this.$swal(err.response.data.data);
            this.error = err.response.data;
          });
      }
    },
  },

  created() {
    this.getUserName();
    this.getAllRoles();
  },
};
</script>

<style scoped>
</style>