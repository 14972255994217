<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <div class="nk-block-head-sub">
                    <router-link class="back-to" to="exchange_area"
                      ><em class="icon ni ni-arrow-left"></em
                      ><span>Back</span></router-link
                    >
                  </div>
                  <h2 class="nk-block-title fw-normal">Edit Currency Rate</h2>
                  <div class="nk-block-des">
                    <p class="lead">You are editing NGH to DNL</p>
                  </div>
                </div>
              </div>

              <!-- .nk-block -->
              <div class="nk-block nk-block-lg">
                <div class="card card-bordered">
                  <div class="card-inner">
                    <div class="card-head">
                      <h5 class="card-title">Currency Setup</h5>
                    </div>
                    <form @submit.prevent="postCurrency" class="gy-3">
                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Country Name</label
                            >
                            <span class="form-note"
                              >Select the currency name</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <select
                                class="form-control"
                                data-search="on"
                                data-select2-id="4"
                                v-model="form.currencyName"
                                @change="selectedCurrency($event)"
                              >
                                <option value="">Select Country</option>
                                <option
                                  v-for="cout in countries"
                                  :key="cout.id"
                                  :value="cout.country_name"
                                >
                                  {{ cout.country_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Currency Code</label>
                            <span class="form-note">Select Currency Code</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="currencyCode"
                                v-model="form.currencyCode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Allow Currency</label>
                            <span class="form-note"
                              >Enable or disable currency.</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <ul
                            class="
                              custom-control-group
                              g-3
                              align-center
                              flex-wrap
                            "
                          >
                            <li>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="reg-public"
                                  id="reg-enable"
                                  value="1"
                                  v-model="form.status"
                                />
                                <label
                                  class="custom-control-label"
                                  for="reg-enable"
                                  >Enable</label
                                >
                              </div>
                            </li>
                            <li>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="reg-public"
                                  id="reg-disable"
                                  value="0"
                                  v-model="form.status"
                                />
                                <label
                                  class="custom-control-label"
                                  for="reg-disable"
                                  >Disable</label
                                >
                              </div>
                            </li>
                            <li>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="reg-public"
                                  id="reg-request"
                                  value="2"
                                  v-model="form.status"
                                />
                                <label
                                  class="custom-control-label"
                                  for="reg-request"
                                  >On Request</label
                                >
                              </div>
                            </li>
                          </ul>
                          <small class="text-danger" v-if="error.status">
                            {{ error.status[0] }}
                          </small>
                        </div>
                      </div>

                      <div class="row g-3">
                        <div class="col-lg-7 offset-lg-5">
                          <div class="form-group mt-2">
                            <button
                              type="submit"
                              class="btn btn-lg btn-primary"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- card -->
              </div>
              <!-- .nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: {},
      error: {},
      form: {
        currencyCode: "",
        currencyName: "",
        status: "",
      },
    };
  },

  mounted() {
    this.getCountries();
  },

  methods: {
    getCountries() {
      this.$http
        .get(this.$appUrl + "get_country", this.$config)
        .then((data) => {
          this.countries = data.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error =
            "Failed to fetch data, please try again or check your internet";
        });
    },

    selectedCurrency(event) {
      //console.log(this.countries);
      this.countries.forEach((el) => {
        // console.log(el);
        return this.getCode(
          el.country_name,
          el.country_code,
          event.target.value
        );
        // code = el.country_name.match(event.target.value) ? el.country_code : "";
        // console.log(code);
        // this.form.currencyCode = code;
      });
    },

    getCode(country_name, country_code, target_value) {
      if (target_value === country_name) {
        this.form.currencyCode = country_code;
      }
    },

    postCurrency() {
      console.log(this.form.currencyCode);
      if (!this.form.currencyName || !this.form.currencyCode) {
        this.$swal("Please all selection options are mandatory");
      } else {
        this.$http
          .post(
            this.$appUrl + "get_currency",
            {
              country_name: this.form.currencyName,
              approved_currency: this.form.currencyCode,
              status: this.form.status,
            },
            this.$config
          )
          .then((response) => {
            if (response.data.status == 200) {
              console.log(response.data.data.message);
              this.$swal(response.data.message);
              this.$router.push({ name: "view_currencies" });
            }
          })
          .catch((err) => {
            console.log(err.response.data.errors);
            this.error = err.response.data.errors;
          });
      }
    },
  },
};
</script>

<style scoped>
</style>