<template>
  <div class="nk-body bg-white npc-general pg-auth">
    <div class="nk-app-root">
      <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap nk-wrap-nosidebar">
          <!-- content @s -->
          <div class="nk-content">
            <div class="nk-block nk-block-middle nk-auth-body wide-xs">
              <div class="brand-logo pb-4 text-center">
                <a href="https://payhaven.ng" class="logo-link">
                  <img
                    class="logo-light logo-img logo-img-lg"
                    src="template/demo1/src/images/payhaven/logo.png"
                    srcset="template/demo1/src/images/payhaven/logo.png"
                    alt="logo"
                  />
                  <img
                    class="logo-dark logo-img logo-img-lg"
                    src="template/demo1/src/images/payhaven/logo.png"
                    srcset="template/demo1/src/images/payhaven/logo.png"
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div class="card card-bordered">
                <div class="card-inner card-inner-lg">
                  <div class="nk-block-head">
                    <div class="nk-block-head-content">
                      <h4 class="nk-block-title">Sign-In</h4>
                      <div class="nk-block-des">
                        <p>Access the cpanel using your email and password.</p>
                      </div>
                    </div>
                  </div>
                  <form action="#" @submit.prevent="login">
                    <div class="form-group">
                      <div class="form-label-group">
                        <label class="form-label" for="default-01"
                          >Email </label
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="form.email"
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                      />
                      <small class="text-danger" v-if="errors.email">
                        {{ errors.email[0] }}
                      </small>
                    </div>
                    <div class="form-group">
                      <div class="form-label-group">
                        <label class="form-label" for="password"
                          >Password</label
                        >
                        <router-link to="/forgot_pass">Forgot password</router-link>
                      </div>
                      <div class="form-control-wrap">
                        <a
                          href="#"
                          class="form-icon form-icon-right passcode-switch"
                          data-target="password"
                        >
                          <em
                            class="passcode-icon icon-show icon ni ni-eye"
                          ></em>
                          <em
                            class="passcode-icon icon-hide icon ni ni-eye-off"
                          ></em>
                        </a>
                        <input
                          type="password"
                          class="form-control form-control-lg"
                          v-model="form.password"
                          id="password"
                          placeholder="Enter your passcode"
                        />
                        <small class="text-danger" v-if="errors.password">
                          {{ errors.password[0] }}
                        </small>
                      </div>
                    </div>
                    <div class="form-group">
                      <button
                        class="btn btn-lg btn-primary btn-block"
                        id="submitBtn"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                  <div class="form-note-s2 text-center pt-4">
                    New on our platform?
                    <router-link to="/register">Create an account</router-link>
                  </div>
                  <div class="text-center pt-4 pb-3">
                    <h6 class="overline-title overline-title-sap">
                      <span>OR</span>
                    </h6>
                  </div>
                  <ul class="nav justify-center gx-4">
                    <li class="nav-item">
                      <!-- <router-link to="http://localhost:8000/social-auth/facebook">Facebook</router-link> -->
                       <a href="https://payhaven.ng/api/social-auth/facebook">Facebook2 </a>
                    </li>
                    <li class="nav-item">
                    <!-- <router-link to="https://localhost:8000/api/social-auth/google">Google</router-link> -->
                    <a href="https://payhaven.ng/api/social-auth/facebook">Google </a>
                    </li>

                    <li class="nav-item">
                      <!-- <router-link to="https://localhost:8000/api/social-auth/github">Github</router-link> -->
                      <a href="https://payhaven.ng/api/social-auth/facebook">Github </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- wrap @e -->
        </div>
        <!-- content @e -->
      </div>
      <!-- main @e -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      errors: {},
    };
  },

 
  methods: {
   async login() {

      this.$systoken.processing("Processing....", "submitBtn", false);
      //Check if not empty
      if (!this.form.email || !this.form.password === "") {
        this.$swal("Please enter email or password");
        this.$systoken.processing("Sign In", "submitBtn", false);
        return;
      }
      if(!this.form.email.includes('@')){
         this.$swal("Please enter a valid email address");
         this.$systoken.processing("Sign In", "submitBtn", false);
         return;
      }

      const actionPayload = {
          email : this.form.email,
          password: this.form.password
      }
      // Post data to the backend and retrieve token if successful
      try{
         await this.$store.dispatch('login', actionPayload, this.$config);
         this.$systoken.processing("Redirecting, please wait....", "submitBtn", false);
        // this.$router.push({ name: "dashboard" });
         //const redirectUrl = "/dashboard";
         //this.$router.replace(redirectUrl);
         this.$router.push({ path: '/dashboard' }).then(() => { this.$router.go() })
      }catch(err) {
       // this.$swal(err);
        if(err){
          this.$swal(err);
          this.$systoken.processing("Sign In", "submitBtn", false);
        }

        if(err.password[0]){
          this.$swal(err.password[0]);
          this.$systoken.processing("Register", "submitBtn", false);
        }else{
          this.$swal(err);
          this.$systoken.processing("Register", "submitBtn", false);
        }
        this.$systoken.processing("Sign In", "submitBtn", false);
      }
    }, 
 
  },


};
</script>