<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm"></div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Users</h4>
                    <p>
                      The following lists all the
                      <strong class="text-primary">users</strong> both active
                      and inactive
                    </p>
                    <router-link to="view_role">
                      <button class="btn btn-sm btn-secondary">
                        View Role
                      </button>
                    </router-link>
                    &nbsp;&nbsp;&nbsp;
                    <router-link to="add_role">
                      <button class="btn btn-sm btn-secondary">Add Role</button>
                    </router-link>
                    &nbsp;&nbsp;&nbsp;
                    <router-link to="add_plan">
                      <button class="btn btn-sm btn-danger">Add Plan</button>
                    </router-link>
                    &nbsp;&nbsp;&nbsp;
                    <router-link to="view_plan">
                      <button class="btn btn-sm btn-danger">View Plan</button>
                    </router-link>
                    &nbsp;&nbsp;&nbsp;
                    <router-link to="permissions">
                      <button class="btn btn-sm btn-primary">
                        Add Permissions
                      </button>
                    </router-link>
                    &nbsp;&nbsp;&nbsp;
                    <router-link to="all_permissions">
                      <button class="btn btn-sm btn-primary">
                        View Permissions
                      </button>
                    </router-link>
                    &nbsp;&nbsp;&nbsp;
                    <router-link to="menu_management">
                      <button class="btn btn-sm btn-success">
                        Menu Management
                      </button>
                    </router-link>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <shimmer v-if="loading" class="paddingLoader"></shimmer>
                      <p class="makeSpace" v-else-if="!loading && error">
                        {{ error }}
                      </p>

                      <p
                        class="makeSpace"
                        v-else-if="
                          !loading && (!allUsers || allUsers.length === 0)
                        "
                      >
                        No User Found
                      </p>
                      <!-- class="datatable-init table" -->
                      <table class="table table-responsive table-bordered" v-else>
                        <thead>
                          <tr class="bg bg-secondary" style="color: white">
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>IP Address</th>
                            <th>Date Created</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="user in allUsers" :key="user.id">
                            <td>{{ user.id }}</td>
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.role }}</td>
                            <td>{{ user.ipaddress }}</td>
                            <td>{{ user.created_at }}</td>
                            <td>
                              <span
                                :class="
                                  user.status === 1
                                    ? 'btn btn-xs btn-success'
                                    : 'btn btn-xs btn-danger'
                                "
                                >{{
                                  user.status === 1 ? "Active" : "Inactive"
                                }}</span
                              >
                            </td>
                            <td class="tb-tnx-action">
                              <div class="dropdown">
                                <a
                                  class="
                                    text-soft
                                    dropdown-toggle
                                    btn btn-icon btn-trigger
                                  "
                                  data-toggle="dropdown"
                                  ><em class="icon ni ni-more-h"></em
                                ></a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-xs
                                  "
                                >
                                  <ul class="link-list-plain">
                                    <li>
                                      <router-link
                                        :to="{
                                          name: 'new_role',
                                          params: { id: user.id },
                                        }"
                                        >Assign Role</router-link
                                      >
                                    </li>
                                    <li>
                                      <router-link to="no_link_yet">{{
                                        user.status === 1
                                          ? "Deactivate"
                                          : "Activate"
                                      }}</router-link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      allUsers: [],
      searchUsers: "",
      error: null,
    };
  },

  methods: {
    sortArray(arr) {
      return arr.sort((a, b) => b.id - a.id);
    },

    getUsers() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "all_users", this.$config)
        .then(({ data }) => {
          this.loading = false;
          results = [];
          let results = [];
          console.log(data.data);
          let irole = [];
          data.data.forEach((item) => {
            // if (item["role"]) {
            //   for (const role in item["role"]) {
            //     console.log(item["role"][0]['role'])
            //     //irole.push(item["role"][role]);
            //   }
            //   // console.log(irole);
            // }
            results.push({
              id: item["id"],
              name: item["name"],
              email: item["email"],
              ipaddress: item["ipaddress"],
              role: item["role"][0]['role'],
              //role: irole.toString(""),
              created_at: item["created_at"],
              status: item["status"],
            });
          });

          this.allUsers = results;
          
          // for (const mdata in data.data) {
          //   console.log("putting it back");
          //   console.log(mdata["email"]);

          //   // results.push({
          //   //   id: mdata[id],
          //   //   email: mdata[email],
          //   //   ipaddress: mdata[ipaddress],
          //   // });
          // }
        })
        .catch((error) => {
          if(error.response.data.message == "noaccess"){
            this.$swal(error.response.data.data);
            this.$router.push({ name: "dashboard" });
          }else  if (error.message !== "undefined") {
            // this.$systoken.clearItem();
            // this.$router.push({ name: "login" });
          }else{
            this.loading = false;
             this.error =
            "Failed to fetch data, please try again or check your internet";
           this.$swal(error);
          }
         
         
        });
    },
  },

  mounted() {
    this.getUsers();
  },
};
</script>

<style scoped>
</style>