<template>
  <h4>My Currencies <small class="currencyNum"><span class="badge badge-danger"> You have {{ filteredBDC.length }} currencies</span></small></h4>
  <hr />
  <table class="table table-responsive table-bordered">
    <thead>
      <tr class="bg bg-secondary" style="color: white">
        <th>ID</th>
        <th>I Have</th>
        <th>Buyer Amount</th>
        <th>My Rate</th>
       
        <th>I Need</th>
        <th>Expected Amount</th> 
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="bdc in filteredBDC" :key="bdc.id">
        <td>{{ bdc.id }}</td>
        <td>{{ bdc.i_have }} {{ bdc.amount }}</td>
        <td><strong>{{ bdc.i_have }} {{ returnAmount(bdc.buyer_details) }}</strong></td>
        <!-- <td>{{ bdc.amount }}</td> -->
        <td>{{ bdc.my_rate }}</td>
        <td>{{ bdc.i_need }}</td>
        <td><strong>{{ bdc.i_need }} {{ returnAmount(bdc.buyer_details) * bdc.my_rate }}</strong></td>
        <td>
          <span>
            <span v-if="bdc.status == 'Available'">
              <span class="bg bg-success">{{ bdc.status }}</span>
            </span>
            <span v-else-if="bdc.status == 'Booked'">
              <span class="bg bg-warning">{{ bdc.status }}</span>
            </span>
            <span v-else-if="bdc.status == 'Cancelled'">
              <span class="badge badge-danger">{{ bdc.status }}</span>
            </span>

            <span v-else-if="bdc.status == 'Completed'">
              <span class="badge badge-success">{{ bdc.status }}</span>
            </span>

            <span v-else-if="bdc.status == 'Confirmed'">
              <span class="badge badge-secondary">{{ bdc.status }}</span>
              <span>&nbsp;&nbsp;</span>
              <button class="btn btn-xs btn-danger" @click="uploadpayment(bdc.id)">Upload Payment</button>
            </span>

            <span v-else-if="bdc.status == 'Buyer_Paid'">
                <span class="badge badge-success">{{ bdc.status }}</span>
                <span>&nbsp;&nbsp;</span>
              <button class="btn btn-xs btn-success" @click="uploadpayment(bdc.id)">Upload Payment</button>
            </span>

            <span v-else-if="bdc.status == 'Buyer_Confirmed'">
                <span class="badge badge-success">{{ bdc.status }}</span>
                <span>&nbsp;&nbsp;</span>
              <button class="btn btn-xs btn-secondary" @click="uploadpayment(bdc.id)">Upload Payment</button>

            </span>

            <span v-else-if="bdc.status == 'Seller_Paid'">
                <span class="badge badge-success">{{ bdc.status }}</span>
              
            </span>
            
          </span></td>
        <td class="tb-tnx-action">
          <div class="dropdown">
            <a
              class="text-soft dropdown-toggle btn btn-icon btn-trigger"
              data-toggle="dropdown"
              ><em class="icon ni ni-more-h"></em
            ></a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-xs">
              <ul class="link-list-plain">
                <li v-if="returnAmount(bdc.buyer_details) != 0" ><a @click="seeBuyer(buyerID(bdc.buyer_details))" style="cursor: pointer;">See Buyer</a></li>
                <li v-if="bdc.status == 'Booked' " ><a @click="confirmCurrency(buyerID(bdc.buyer_details))" style="cursor: pointer;">Confirm Availability</a></li>
                
                <li><router-link :to="{ 
                  name: 'currency_details', 
                  params: {cid: bdc.cid} }" >View Details</router-link>
                </li>
                  <li v-if="bdc.status == 'Booked' " ><a href="#">Cancel</a></li>

              </ul>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <modal title="Upload Payment" @close-modal="closeModal" v-if="modalpopup">
      <template #slotbody>
        <label>Upload Payment Evidence {{ upload_id  }}</label>
         <input type="file" @change="onFileSelected" class="form-control" placeholder="UploadFile" />
         <hr/>
        <input type="button" class="btn btn-secondary btn-sm" value="Upload Payment" @click="submitUploadFile" />
      </template>
  </modal>


     <!--------------------- MODAL START ---------------------------->
     <div v-if="myModal">
        <transition name="model">
            <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Buyers Information</h4>
                        <button @click="closeModal" type="button" class="close">
                          <span area-hidden="true" class="">&times;</span>
                        </button>
                      </div>
                      
                     

                      <div class="modal-body">
                        <div class="form-group">
                          <div><strong>Buyer Name : {{ this.buyer_id }}</strong></div><hr/>
                          <div class="row">
                              <div class="col-md-6">
                                <label>Buyer Requested Amount</label>
                                <input type="text" v-model="amount" class="form-control" disabled />
                              </div>
                              <div class="col-md-6">
                                <label>Seller Available Amount</label>
                                <input type="text" v-model="exchange" class="form-control" disabled />
                              </div>

                              <div class="col-md-12">
                                <label>Expected Amount <strong><small>({{ this.amount  }} * {{ this.my_rate  }})</small></strong></label>
                                <input type="text" v-model="expectedAmount" class="form-control" disabled />
                              </div>
                          </div>
                          <label>Buyer's Comment </label>
                          <textarea class="form-control" v-model="comment"  disabled></textarea>
                          <small><i>Seller Name: {{ seller_id }}</i></small>
                          <hr/>
                        </div>
                      </div> 


                    </div>
                  </div>

                </div>
            </div>
        </transition>
      </div>
    <!------------------- MODAL END ---------------------------->

</template>

<script>
import axios from 'axios';

import globalFunctions from '../../helpers/functions.js';

export default {
  data() {
    return {
      bdcurriencies: [],
      error: null,
      myModal: false,
      amount: null,
      comment: null,
      buyer_id: null,
      seller_id: null,
      exchange: null,
      ihave: null,
      ineed:null,
      myrate: null,
      expectedAmount: null,
      modalpopup: false,
      selectedFile: null,
      upload_id: '',

    };
  },

  provide() {
    return {
      closeModal: this.closeModal,
    };
  },


  computed: {
     filteredBDC() {
       return globalFunctions.sortArray(this.$store.getters['bdc/allBDC']);
     },

    },

  mounted() {
    this.loadBDC();
  },

  methods: {

    async loadBDC(){
      try{
        await this.$store.dispatch('bdc/getBDC');
      }catch(err){
        this.error = err.message || 'Something went wrong';
      }
    },

    returnAmount(object){
      return object.map((el) => {
        return el.amount;
      }).reduce((a, b) => a + b, 0);
    
    },

    buyerID(object){
      return object.map((el) => {
        return el.id;
       // return el.buyer_id
      }).reduce((a, b) => a + b, 0);
    },

    seeBuyer(id){
      this.myModal = true;
      this.$http.get(this.$appUrl + "get_buyer_info/"+id, this.$config) .then(({ data }) => {
          this.buyer_id = data.data[0].buyer_id;
          this.seller_id = data.data[0].seller_id;
          this.amount = data.data[0].amount;
          this.comment = data.data[0].comment;
          this.exchange = data.data[0].exchange[0].amount;
          this.expectedAmount =  data.data[0].amount * data.data[0].exchange[0].my_rate;
          this.my_rate = data.data[0].exchange[0].my_rate;
        })
        .catch((error) => {
          this.error =
            "Failed to fetch data, please try again or check your internet";
          //this.$swal(error);
        });
      //this.$router.push({ name: 'user_details', params: {uid: id} });
    },

    closeModal(){
      this.myModal = false;
      this.modalpopup = false;
    }, 

    uploadpayment(id){
      this.modalpopup = true;
      this.upload_id = id;
    },

    onFileSelected(event){
      this.selectedFile = event.target.files[0];
    },


    submitUploadFile(){
      const fdata = new FormData();
      fdata.append('image', this.selectedFile);
      fdata.append('id', this.upload_id);
      axios.post(this.$appUrl + "upload_file", fdata, this.$config)
      .then((response) => {
        if (response.data.status == 200) { 
          this.$swal("File Uploaded Successfully");
          this.$router.go();
          this.modalpopup = false;
        }else if(response.data.status == 201){
          this.$swal("Please wait for Buyer to make payment");
          this.modalpopup = false;
        }
      }).catch((err) => {
        console.log(err);
        this.$swal(err.message);
      });
    },


    confirmCurrency(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to confirm this transaction!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm it!'
      }).then((result) => {
        if (result.value) {
          this.$http.post(this.$appUrl + "confirm_availability", { id: id }, this.$config)
          .then((response) => { 
            if(response.status == 200){
              this.$swal('Confirmed!', 'Transaction has been confirmed.', 'success');
              this.loadBDC();
            } 
          }).catch((error) => {
            this.error =
              "Failed to fetch data, please try again or check your internet";
            this.$swal(error);
          });

        }
      })
    }

      

  }

  
};
</script>

<style scoped>
.currencyNum{
  float: right;
}
</style>