<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">YOUR PAYMENT</h4>
                    <p>
                      All payment made using different channels
                      <strong class="text-primary">are displayed</strong> here
                    </p>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <!-- class="datatable-init table" -->
                      <table class="table table-bordered table-responsive">
                        <thead>
                          <tr class="bg bg-danger" style="color: white">
                            <th>ID</th>
                            <th>Pay Reference</th>
                            <!-- <th>Base Currency</th> -->
                            <th>Amount Paid</th>
                            <!--<th>Target Currency</th> -->
                            <th>Amount Collected</th>
                            <th>Date Paid</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="py in filteredPayment" :key="py.id">
                            <td>{{ py.transaction_id }}</td>
                            <td>{{ py.paymentRef }}</td>
                            <!-- <td>{{ py.base_currency }}</td> -->
                            <td>{{ py.base_currency }} {{ py.amount_sent }}</td>
                            <!-- <td>{{ py.target_currency }}</td> -->
                            <td>{{ py.target_currency }} {{ py.amount_paid }}</td>
                            <td>{{ py.date_paid }}</td>

                            <td class="tb-tnx-action">
                              <div class="dropdown">
                                <a
                                  class="
                                    text-soft
                                    dropdown-toggle
                                    btn btn-icon btn-trigger
                                  "
                                  data-toggle="dropdown"
                                  ><em class="icon ni ni-more-h"></em
                                ></a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-xs
                                  "
                                >
                                  <ul class="link-list-plain">
                                    <li><a href="#">View More</a></li>
                                    <li><a href="#">Send Mail</a></li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>


export default {
  data() {
    return {
      loading: false,
      error: null,
    };
  },

  computed: {
     filteredPayment() {
       return this.$store.getters['pay/payment'];
     }
  },

  created() {
    this.$store.dispatch('pay/getPayments');
  },
};
</script>

<style scoped>
</style>