<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Exchange Area(BDCs)</h4>
                    <p>
                      Money Transaction. BUY ANY CURRENCIES FROM ANY BDC HERE.
                      <strong class="text-primary">all exchanges are</strong>
                      verified and pass through our secured escrow system. We
                      are sure you can your money.
                    </p>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <shimmer v-if="loading" class="paddingLoader"></shimmer>
                      <p class="makeSpace" v-else-if="!loading && error">
                        {{ error }}
                      </p>

                      <p
                        class="makeSpace"
                        v-else-if="
                          !loading &&
                          (!bdcurriencies || bdcurriencies.length === 0)
                        "
                      >
                        No Currency Found
                      </p>
                      <!-- class="datatable-init table" -->
                      <table class="table table-responsive table-bordered" v-else>
                        <thead>
                          <tr class="bg bg-secondary" style="color: white">
                            <th>Date</th>
                            <th>Seller Name</th>
                            <th>Seller Currency</th>
                            <th>Total Amount</th>
                            <th>Rate Per Currency</th>
                            <th>Seller Needs</th>
                            <th>Expected Amount</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="bdc in bdcurriencies" :key="bdc.id">
                            <td>{{ bdc.created_at }}</td>
                            <td>{{ bdc.user_id }}</td>
                            <td>{{ bdc.i_have }}</td>
                            <td>
                              {{ bdc.amount }}
                            </td>
                            <td>{{ bdc.my_rate }}</td>
                            <td>{{ bdc.i_need }}</td>
                            <td>
                              {{
                                this.$globeFunc.formatMoney(
                                  bdc.my_rate * bdc.amount
                                )
                              }}
                            </td>

                            <td>
                              <span
                                :class="
                                  bdc.status === 1
                                    ? 'btn btn-xs btn-primary'
                                    : 'btn btn-xs btn-danger'
                                "
                                >{{
                                  bdc.status == 1 ? "Active" : "Inactive"
                                }}</span
                              >
                            </td>
                            <td class="tb-tnx-action">
                              <div class="dropdown">
                                <a
                                  class="
                                    text-soft
                                    dropdown-toggle
                                    btn btn-icon btn-trigger
                                  "
                                  data-toggle="dropdown"
                                  ><em class="icon ni ni-more-h"></em
                                ></a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-xs
                                  "
                                >
                                  <ul class="link-list-plain">
                                    <li><a href="#">Buy Currency</a></li>
                                    <li><a href="#">View More Details</a></li>
                                    <li>
                                      <a href="#">Contact Seller</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      bdcurriencies: [],
      searchCurrencies: "",
      error: null,
    };
  },

  methods: {
    getAllTransactions() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "post_bdc", this.$config)
        .then(({ data }) => {
          this.loading = false;
          this.bdcurriencies = this.$globeFunc.sortArray(data.data);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Failed to fetch data, please try again or check your internet";
          //this.$swal(error);
        });
    },
  },

  mounted() {
    this.getAllTransactions();
  },
};
</script>

<style scoped>
</style>