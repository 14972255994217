<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <h2 class="nk-block-title fw-normal">Contact Us</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      Send us any details or complaint about your issues or
                      transaction
                    </p>
                  </div>
                </div>
              </div>

              <!-- .nk-block -->
              <div class="nk-block nk-block-lg">
                <div class="card card-bordered">
                  <div class="card-inner">
                    <div class="card-head">
                      <h5 class="card-title">Contact Support</h5>
                    </div>
                    <form @submit.prevent="postCurrency" class="gy-3">
                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Your Full Name</label>
                            <span class="form-note">Enter FullName</span>
                          </div>
                        </div>
                        <profile-header>
                          <template v-slot:headerInfo="slotProps">
                            <div class="col-lg-7">
                              <div class="form-group">
                                <div class="form-control-wrap">
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    id="fullName"
                                    name="fullName"
                                    :value="slotProps.userItems.name"
                                    style="width: 300px"
                                  />
                                </div>
                              </div>
                            </div>
                          </template>
                        </profile-header>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Email</label>
                            <span class="form-note">Your registered email</span>
                          </div>
                        </div>

                        <profile-header>
                          <template v-slot:headerInfo="slotProps">
                            <div class="col-lg-7">
                              <div class="form-group">
                                <div class="form-control-wrap">
                                  <input
                                    disabled
                                    type="text"
                                    class="form-control"
                                    id="fullEmail"
                                    name="fullEmail"
                                    :value="slotProps.userItems.email"
                                    style="width: 300px"
                                  />
                                </div>
                              </div>
                            </div>
                          </template>
                        </profile-header>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Subject</label>
                            <span class="form-note"
                              >Subject of your complaint</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="subject"
                                v-model="form.subject"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Message</label>
                            <span class="form-note"
                              >Enter message or complaint</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <textarea
                                v-model="form.message"
                                class="form-control"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3">
                        <div class="col-lg-7 offset-lg-5">
                          <div class="form-group mt-2">
                            <button
                              type="submit"
                              class="btn btn-lg btn-primary"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- card -->
              </div>
              <!-- .nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: {},
      image: "",
      form: {
        message: "",
        subject: "",
        status: "",
      },
    };
  },

  mounted() {},

  methods: {
    postCurrency() {
      let userdetails = JSON.parse(this.$user.getUserName());
      if (!this.form.message || !this.form.subject) {
        this.$swal("Please make sure all fields are field");
      } else {
        this.$http
          .post(
            this.$appUrl + "contact_us",
            {
              subject: this.form.subject,
              message: this.form.message,
              name: userdetails.name,
              email: userdetails.email,
            },
            this.$config
          )
          .then((response) => {
            if (response.data.status == 200) {
              console.log(response.data.data.message);
              this.$swal(response.data.message);
              this.$router.push({ name: "contact_us" });
              this.form.subject = "";
              this.form.message = "";
            }
          })
          .catch((err) => {
            console.log(err.response.data.errors);
            this.error = err.response.data.errors;
          });
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 10%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
.pushrigt {
  float: right;
}
</style>