<template>
  <div class="nk-footer">
    <div class="container-fluid">
      <div class="nk-footer-wrap">
        <div class="nk-footer-copyright">
          &copy; 2022 Designed By
          <a href="https://softnio.com" target="_blank">Payhaven Team</a>
        </div>
        <div class="nk-footer-links">
          <ul class="nav nav-sm">
            <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
            <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
            <li class="nav-item"><a class="nav-link" href="#">Help</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>