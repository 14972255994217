<template>
  <div class="nk-body bg-white npc-general pg-auth">
    <div class="nk-app-root">
      <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap nk-wrap-nosidebar">
          <!-- content @s -->
          <div class="nk-content">
            <div class="nk-block nk-block-middle nk-auth-body wide-xs">
              <div class="brand-logo pb-4 text-center">
                <a href="https://payhaven.ng" class="logo-link">
                  <img
                    class="logo-light logo-img logo-img-lg"
                    src="template/demo1/src/images/payhaven/logo.png"
                    srcset="template/demo1/src/images/payhaven/logo.png"
                    alt="logo"
                  />
                  <img
                    class="logo-dark logo-img logo-img-lg"
                    src="template/demo1/src/images/payhaven/logo.png"
                    srcset="template/demo1/src/images/payhaven/logo.png"
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div class="card card-bordered">
                <div class="card-inner card-inner-lg">
                  <div class="nk-block-head">
                    <div class="nk-block-head-content">
                      <h4 class="nk-block-title">Reset Password</h4>
                      <div class="nk-block-des">
                        <p>To reset your password, provide your email.</p>
                      </div>
                    </div>
                  </div>
                  <form action="#" @submit.prevent="reset">
                    <div class="form-group">
                      <div class="form-label-group">
                        <label class="form-label" for="default-01"
                          >Email </label
                        >
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        v-model="form.email"
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                      />
                      <small class="text-danger" v-if="errors.email">
                        {{ errors.email[0] }}
                      </small>
                    </div>
                    
                    <div class="form-group">
                      <button
                        class="btn btn-lg btn-primary btn-block"
                        id="submitBtn"
                      >
                       Reset
                      </button>
                    </div>
                  </form>
                  <div class="form-note-s2 text-center pt-4">
                    Already a member, Sign in?
                    <router-link to="/login">Login</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- wrap @e -->
        </div>
        <!-- content @e -->
      </div>
      <!-- main @e -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: null,
      },
      errors: {},
    };
  },

  mounted() { },

  methods: {

    async reset() {
     this.$systoken.processing("Processing....", "submitBtn", false);
     const btnResponse = this.$systoken.processing("Reset Password", "submitBtn", false);
      if (!this.form.email) {
        this.$swal("Please enter email");
        btnResponse;
        return;
      }

      if(!this.form.email.includes('@')){
         this.$swal("Please enter a valid email address");
         btnResponse;
         return;
      }

      const actionPayload = {  email : this.form.email }
      
      // Post data to the backend and retrieve token if successful
      // this.$http
      // .get(this.$appUrl + "sanctum/csrf-cookie")
      //  .then((response) => {
      this.$http
        .post(this.$appUrl + "auth/forgot_pass", this.form, this.$config)
        .then((response) => {
          if (response.data.status == 200) {
            this.$systoken.processing("Redirecting...", "submitBtn", true);
            this.$swal(
              "Password Reset Mails Sent to your email address",
            );
            this.form.email = "";
            this.$router.push({ name: "thanks" });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message == "Network Error") {
            this.$swal(
              "Something went wrong, please try again later or check your internet"
            );
            btnResponse;
          }
          if (err) {
            //console.log(err.message);
            this.errors = err.response.data.errors;
            btnResponse;
          }
        });

     // });
    },

  },
};
</script>