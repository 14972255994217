<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <div class="nk-block-head-sub">
                    <router-link class="back-to" to="/exchange_area"
                      ><em class="icon ni ni-arrow-left"></em
                      ><span>Back</span></router-link
                    >
                  </div>
                  <h2 class="nk-block-title fw-normal">Edit Currency</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      You are about to update
                      <strong>{{ form.base_currency }}</strong> to
                      <strong>{{ form.target_currency }}</strong> with market
                      rate of
                      <strong>{{ form.market_rate }}</strong>
                    </p>
                  </div>
                </div>
              </div>

              <!-- .nk-block -->
              <div class="nk-block nk-block-lg">
                <div class="card card-bordered">
                  <div class="card-inner">
                    <div class="card-head">
                      <h5 class="card-title">Currency Setup</h5>
                    </div>
                    <form @submit.prevent="updateCurrencyRate" class="gy-3">
                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Base Currency</label
                            >
                            <span class="form-note">The Primary Currency</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                readonly
                                type="text"
                                class="form-control"
                                id="currencyCode"
                                v-model="form.base_currency"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Target Currency</label
                            >
                            <span class="form-note">The Primary Currency</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                readonly
                                type="text"
                                class="form-control"
                                id="currencyCode"
                                v-model="form.target_currency"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Market Rate</label
                            >
                            <span class="form-note">API Market Rate</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                readonly
                                type="text"
                                class="form-control"
                                id="currencyCode"
                                v-model="form.market_rate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Additional Rate</label>
                            <span class="form-note"
                              >Additional Capup for Payhaven</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                id="currencyCode"
                                v-model="form.additional_rate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3">
                        <div class="col-lg-7 offset-lg-5">
                          <div class="form-group mt-2">
                            <button
                              type="submit"
                              class="btn btn-lg btn-primary"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- card -->
              </div>
              <!-- .nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        base_currency: "",
        target_currency: "",
        market_rate: "",
        additional_rate: "",
      },
    };
  },

  mounted() {
    this.editCurrencyRates();
  },

  methods: {
    editCurrencyRates() {
      let id = this.$route.params.id;
      this.$http
        .get(this.$appUrl + "get_exchange_rates/" + id, this.$config)
        .then((response) => {
          console.log(response.data.data.base_currency);
          (this.form.base_currency = response.data.data.base_currency),
            (this.form.market_rate = response.data.data.market_rate),
            (this.form.target_currency = response.data.data.target_currency),
            (this.form.additional_rate = response.data.data.additional_rate);
        })
        .catch((err) => {
          if (err.message) {
            this.$swal(
              "<small>The resource you are looking for does not exist <br/>" +
                err.message +
                "</small>"
            );
            this.$router.push({ name: "exchange_area" });
          }
          console.log(err.message);
        });
    },

    updateCurrencyRate() {
      if (this.form.additional_rate == "") {
        this.$swal("Please Add Additional Rate");
      } else {
        this.$http
          .post(
            this.$appUrl + "update_exhcnage_rates",
            {
              additional_rate: this.form.additional_rate,
              rand_link: this.$route.params.id,
            },
            this.$config
          )
          .then((response) => {
            console.log(response.data.status);
            if (response.data.status == 200) {
              this.$swal("Successfully Update");
              this.$router.push({ name: "exchange_area" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
</style>