<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm"></div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">All Roles</h4>
                    <p>
                      The following lists all the
                      <strong class="text-primary">roles</strong> both active
                      and inactive
                    </p>
                    <div class="nk-block-head-sub">
                      <router-link class="back-to" to="all_users"
                        ><em class="icon ni ni-arrow-left"></em
                        ><span>Back</span></router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <shimmer v-if="loading" class="paddingLoader"></shimmer>
                      <p class="makeSpace" v-else-if="!loading && error">
                        {{ error }}
                      </p>

                      <p
                        class="makeSpace"
                        v-else-if="
                          !loading && (!allRoles || allRoles.length === 0)
                        "
                      >
                        No Role Found
                      </p>
                      <!-- class="datatable-init table" -->
                      <table class="table table-bordered" v-else>
                        <thead>
                          <tr class="bg bg-secondary" style="color: white">
                            <th>ID</th>
                            <th>Role</th>
                            <th>Authentication</th>
                            <th>Date Created</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="role in allRoles" :key="role.id">
                            <td>{{ role.id }}</td>
                            <td>{{ role.name }}</td>
                            <td>{{ role.guard_name }}</td>
                            <td>{{ role.created_at }}</td>

                            <td class="tb-tnx-action">
                              <div class="dropdown">
                                <a
                                  class="
                                    text-soft
                                    dropdown-toggle
                                    btn btn-icon btn-trigger
                                  "
                                  data-toggle="dropdown"
                                  ><em class="icon ni ni-more-h"></em
                                ></a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-xs
                                  "
                                >
                                  <ul class="link-list-plain">
                                    <li>
                                      <router-link
                                        :to="{
                                          name: 'role_permissions',
                                          params: { id: role.id },
                                          query: { name: role.name },
                                        }"
                                        >View</router-link
                                      >
                                    </li>
                                    <li><a href="#">Edit</a></li>
                                    <li><a href="#">Delete</a></li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      allRoles: [],
      searchRoles: "",
      error: null,
    };
  },

  methods: {
    getRoles() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "post_role", this.$config)
        .then(({ data }) => {
          this.loading = false;
          this.allRoles = this.$globeFunc.sortArray(data.role);
        })
        .catch((error) => {
          
          if(error.response.data.message == "noaccess"){
            this.$swal(error.response.data.data);
            this.$router.push({ name: "dashboard" });
          }else{
            this.loading = false;
             this.error =
            "Failed to fetch data, please try again or check your internet";
           this.$swal(error);
          }
        });
    },
  },

  mounted() {
    this.getRoles();
  },
};
</script>

<style scoped>
</style>