class AppStorage {

    urldata(){
        "https://payhaven.ng/api/";
    }

    storeToken(token) {
        localStorage.setItem('AuthToken', token);
    }

    authenticatedUser(userdata){
        localStorage.setItem('user', JSON.stringify(userdata));
    }

    store(token){
        this.storeToken(token);
      
    }

    storeUser(userdata) {
         this.authenticatedUser(userdata);
    }

    clearItem(){ 
        localStorage.removeItem('AuthToken'); 
        localStorage.removeItem('user'); 
    }

    getToken(token){
        localStorage.getItem(token)
    }

    getUser(user){
        localStorage.getItem(user)
    }

    processing(vars, butnClass, btnStatus = false) {
      var btn = document.getElementById(`${butnClass}`)
      btn.disabled = btnStatus;
      btn.innerHTML = vars;
    }

    getValidToken() {
        return localStorage.getItem('AuthToken');
    }

     getUserDetails() {
        return localStorage.getItem('user');
    }

   


}

export default AppStorage = new AppStorage();