<template>
  <div>
    <h4 class="nk-block-title">
      <slot name="header">
        {{ title }}
      </slot>
    </h4>

    <p>
      <slot name="tagline">
        {{ description }}
      </slot>
    </p>
    <hr />
    <slot name="buttons"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: false,
    },
  },
};
</script>