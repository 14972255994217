<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    let token = this.$systoken.getValidToken();
    if (!token) {
      this.$router.push({ name: "login" });
    } else {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
          //"Access-Control-Allow-Origin": "*",
        },
      };

      //   this.$http.get(this.$appUrl + "user", config).then((response) => {
      //     console.log(response);
      //   });

      this.$http
        .post(this.$appUrl + "logout", { token: token }, config)
        .then((response) => {
          if (response) {
            this.$store.dispatch('logout');
            //this.$systoken.clearItem();
            this.$router.push({ name: "login" });
          }
        })
        .catch((err) => {
          this.$systoken.clearItem();
          this.$router.push({ name: "login" });
        });
    }
  },
};
</script>