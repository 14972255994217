import AppStorage from './appstorage';

class User {


    responseAfterLogin(res){
     
        if(res.data.status == 200){
            const status =  res.data.status;
            const token = res.data.token;
            const user = res.data.user;

            AppStorage.store(token);
            AppStorage.storeUser(user);

        }
        
    }

    responseUserDetails(userdata) {
        if (this.loggedIn()) {
             AppStorage.storeUser(userdata);
        }
    }

    hasToken(){
        //const storeToken = AppStorage.getToken();
       const storeToken = localStorage.getItem('AuthToken');
       if(storeToken){
           return storeToken;
       }else{
           return false;
       }
    }

    loggedIn(){
       return this.hasToken() 
    }

    getUserName(){
        if(this.loggedIn()){
            return AppStorage.getUserDetails(); 
            //return  localStorage.getItem('user')
        }
    }
  
}

export default User = new User();