<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">All Transactions</h4>
                    <p>
                      All Active and Available Transactions
                      <strong class="text-primary">are disployed</strong> here
                      based on your role.
                    </p>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <div class="card card-preview">
                    <div class="card-inner">
                      <shimmer v-if="loading" class="paddingLoader"></shimmer>
                      <p class="makeSpace" v-else-if="!loading && error">
                        {{ error }}
                      </p>

                      <p
                        class="makeSpace"
                        v-else-if="
                          !loading &&
                          (!allTransactions || allTransactions.length === 0)
                        "
                      >
                        No Transactions Found
                      </p>
                      <!-- class="datatable-init table" -->
                      <table
                        class="table table-bordered table-responsive table-hover"
                        v-else
                      >
                        <thead>
                          <tr class="bg bg-secondary" style="color: white">
                            <th>ID</th>
                            <th>User Name</th>
                            <th>Base Currency</th>
                            <th>Target Currency</th>
                            <th>Amount Paid</th>
                            <th>Amount to Recieve</th>
                            <th>Pay Method</th>
                            <th>Date Created</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="tc in allTransactions" :key="tc.id">
                            <td>{{ tc.id }}</td>
                            <td>{{ tc.buyer_user_id }}</td>
                            <td>{{ tc.base }}</td>
                            <td>{{ tc.target }}</td>
                            <td>{{ tc.amount }}</td>
                            <td>{{ tc.iget }}</td>
                            <td>
                              <span
                                :class="
                                  tc.payment_method === 'cash'
                                    ? 'btn btn-xs btn-secondary'
                                    : 'btn btn-xs btn-success'
                                "
                              >
                                {{ tc.payment_method }}</span
                              >
                            </td>

                            <td>{{ tc.created_at }}</td>
                            <td>{{ tc.status }}</td>
                            <td class="tb-tnx-action">
                              <div class="dropdown">
                                <a
                                  class="
                                    text-soft
                                    dropdown-toggle
                                    btn btn-icon btn-trigger
                                  "
                                  data-toggle="dropdown"
                                  ><em class="icon ni ni-more-h"></em
                                ></a>
                                <div
                                  class="
                                    dropdown-menu
                                    dropdown-menu-right
                                    dropdown-menu-xs
                                  "
                                >
                                  <ul class="link-list-plain">
                                    <li v-if="this.checkRole(tc.role) == 'Admin' && tc.status != 'paid' ">
                                      <a @click="confirmPayment(tc.id)" link="#"
                                        >Confirm Payment</a
                                      >
                                    </li>
                                    <li><a href="#">View More</a></li>
                                    <li v-if="tc.status == 'initiated'">
                                      <a href="#">Resend Transaction</a>
                                    </li>
                                    <li
                                      v-if="this.checkRole(tc.role) == 'Admin'"
                                    >
                                      <a href="#">Send Mail</a>
                                    </li>
                                    <li
                                      v-if="this.checkRole(tc.role) == 'Admin' && tc.status != 'paid' "
                                    >
                                      <a href="#">Cancel Transaction</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      all_transactions: [],
      searchTransactions: "",
      error: null,
    };
  },

  computed: {
     allTransactions() {
       return this.$store.getters['trans/transactions'];
     },

    },

    created(){
      return this.$store.dispatch('trans/getTransactions');
    },

  methods: {
    checkRole(...roleArray) {
     // console.log(roleArray[0]);
      if (roleArray == "user") {
        return "User";
      }else if(roleArray == "admin"){
        return "Admin"
      }else{
        return roleArray[0];
      }
    },

    confirmPayment(tid) {
      if (tid == "") {
        this.$swal(
          "Important parameter to process page is missing, contact administrator"
        );
      }
      this.$http
        .post(
          this.$appUrl + "payment_confirmation",
          {
            tid: tid,
          },
          this.$config
        )
        .then((response) => {
          if (response.data.status == 200) {
            // this.$systoken.processing("Redirecting...", "submitBtn", true);
            this.$swal("Transaction succesfully confirmed");
            this.$router.go();
            this.allTransactions();
            //this.$router.push({ name: "all_transactions" });
          } else {
            console.log(response.data.data);
            this.$swal(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err.message.data);
          console.log(err.message);
          this.errors = err.response.data.errors;
        });
    },

   /* getAllTransactions() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "transactions", this.$config)
        .then(({ data }) => {
          console.log(data);
          this.loading = false;
          this.all_transactions = this.$globeFunc.sortArray(data.data);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error =
            "Failed to fetch data, please try again or check your internet";
          //this.$swal(error);
        });
    }, */
  },

  // mounted() {
  //   this.getAllTransactions();
  // },
};
</script>

<style scoped>
</style>