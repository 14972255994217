<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <h2 class="nk-block-title fw-normal">Update Account</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      Ensure you update your account for better performance
                    </p>
                  </div>
                </div>
              </div>

              <!-- .nk-block -->
              <div class="nk-block nk-block-lg">
                <div class="card card-bordered">
                  <div class="card-inner">
                    <div class="card-head">
                      <h5 class="card-title">Account Setup</h5>
                    </div>
                    <form @submit.prevent="postUpdateProfile" class="gy-3">
                      <profile-header>
                        <template v-slot:headerInfo="slotProps">
                          <div class="row g-3 align-center">
                            <div class="col-lg-5">
                              <div class="form-group">
                                <label class="form-label">Your Full Name</label>
                                <span class="form-note">Enter FullName</span>
                              </div>
                            </div>
                            <div class="col-lg-7">
                              <div class="form-group">
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="fullName"
                                    disabled
                                    :value="slotProps.userItems.name"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row g-3 align-center">
                            <div class="col-lg-5">
                              <div class="form-group">
                                <label class="form-label">Upload Phone</label>
                                <span class="form-note">Profile Photo</span>
                              </div>
                            </div>
                            <div class="col-lg-7" v-if="!image">
                              <div class="form-group">
                                <div class="form-control-wrap">
                                  <input
                                    type="file"
                                    class="form-control"
                                    id="fileUpload"
                                    @change="onFileChange"
                                  />
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <img :src="image" />
                              <button
                                @click="removeImage"
                                class="pushrigt btn btn-sm btn-danger"
                              >
                                Remove Image
                              </button>
                            </div>
                          </div>

                          <div class="row g-3 align-center">
                            <div class="col-lg-5">
                              <div class="form-group">
                                <label class="form-label">Email</label>
                                <span class="form-note"></span>
                              </div>
                            </div>
                            <div class="col-lg-7">
                              <div class="form-group">
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    disabled
                                    :value="slotProps.userItems.email"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </profile-header>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Address</label>
                            <span class="form-note">Enter Full Address</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <textarea
                                class="form-control"
                                v-model="form.address"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Your Phone</label>
                            <span class="form-note">Enter Phone Number</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="my_phone"
                                v-model="form.my_phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Next of Kin Name</label>
                            <span class="form-note">Enter Next of Kin</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="kin_name"
                                v-model="form.kin_name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Next of Kin Phone</label>
                            <span class="form-note"
                              >Enter Next of Kin Phone</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="kin_phone"
                                v-model="form.kin_phone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Country</label
                            >
                            <span class="form-note">Select your country</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <select
                                class="form-control"
                                data-search="on"
                                data-select2-id="4"
                                v-model="form.country_name"
                                @change="selectedCurrency($event)"
                              >
                                <option value="">Select Country</option>
                                <option
                                  v-for="cout in countries"
                                  :key="cout.id"
                                  :value="cout.country_name"
                                >
                                  {{ cout.country_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Currency Code</label>
                            <span class="form-note"
                              >Your Base Currency Code</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="currencyCode"
                                v-model="form.currency_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3">
                        <div class="col-lg-7 offset-lg-5">
                          <div class="form-group mt-2">
                            <button type="submit" class="btn btn-md btn-danger">
                              Update Account
                            </button>
                          </div>
                        </div>
                      </div>

                      <hr />
                    </form>

                    <form @submit.prevent="updateBankInfo" class="gy-3">
                      <h3>ADD BANK INFORMATION</h3>

                      <table class="table table-responsive">
                        <tr>
                          <th>Bank Name</th>
                          <th>Account Name</th>
                          <th>Account Number</th>
                          <th>Additional Info</th>
                          <!-- <th><em class="icon ni ni-plus-fill-c"></em></th> -->
                        </tr>

                        <tr>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              id="BankName"
                              v-model="form.bankName"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              id="bankAccount"
                              v-model="form.bankAccount"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              id="accountNo"
                              v-model="form.accountNo"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              id="add_info"
                              v-model="form.add_info"
                            />
                          </td>
                          <!-- <td>
                            <em class="icon ni ni-cross-circle-fill"></em>
                          </td> -->
                        </tr>
                      </table>

                      <div class="row g-3">
                        <div class="col-lg-7 offset-lg-5">
                          <div class="form-group mt-2">
                            <button
                              type="submit"
                              class="btn btn-md btn-secondary"
                              id="submitBtn"
                            >
                              Save Bank
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>



                    <hr/>

                    <table class="table table-hovered table-default table-bordered">
                      <tr>
                        <th>ID</th>
                        <th>Bank</th>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Created At</th>
                      </tr>
                      <tr v-for="bk in my_bank" :key="bk.id">
                        <td>{{ bk.id }}</td>
                        <td>{{ bk.bank_name }}</td>
                        <td>{{ bk.account_name }}</td>
                        <td>{{ bk.account_number }}</td>
                        <td>{{ bk.created_at }}</td>
                      </tr>

                    </table>


                  </div>
                </div>
                <!-- card -->
              </div>
              <!-- .nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: {},
      error: {},
      image: "",
      form: {
        my_phone: "",
        address: "",
        status: "",
        kin_name: "",
        kin_phone: "",
        currency_code: "",
        country_name: "",
        bankName: "",
        bankAccount: "",
        accountNo: "",
        add_info: "",
        my_bank: [],
      },
    };
  },

  mounted() {
    this.getBankInfo();
    this.getCountries();
  },

  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    removeImage: function (e) {
      this.image = "";
    },

    getCountries() {
      this.$http
        .get(this.$appUrl + "get_country", this.$config)
        .then((data) => {
          this.countries = data.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error =
            "Failed to fetch data, please try again or check your internet";
        });
    },

    selectedCurrency(event) {
      //console.log(this.countries);
      this.countries.forEach((el) => {
        // console.log(el);
        return this.getCode(
          el.country_name,
          el.country_code,
          event.target.value
        );
        // code = el.country_name.match(event.target.value) ? el.country_code : "";
        // console.log(code);
        // this.form.currencyCode = code;
      });
    },

    getCode(country_name, country_code, target_value) {
      if (target_value === country_name) {
        this.form.currency_code = country_code;
      }
    },

    postUpdateProfile() {
      console.log(this.form.currency_code);
      if (!this.form.country_name || !this.form.currency_code) {
        this.$swal("Please all fields are mandatory");
      } else {
        this.$http
          .post(
            this.$appUrl + "post_other_user_info",
            {
              phone: this.form.my_phone,
              address: this.form.address,
              next_of_kin_name: this.form.kin_name,
              next_of_kin_phone: this.form.kin_phone,
              country: this.form.country_name,
              country_code: this.form.currency_code,
              picture: this.image,
            },
            this.$config
          )
          .then((response) => {
            if (response.data.status == 200) {
              console.log(response.data.data.message);
              this.$swal(response.data.message);
              this.getBankInfo();
              //this.$router.push({ name: "update_profile" });
            }
          })
          .catch((err) => {
            console.log(err.response.data.errors);
            this.error = err.response.data.errors;
          });
      }
    },


    getBankInfo() {
      this.$http
        .get(this.$appUrl + "get_my_bank", this.$config)
        .then((response) => {
          this.my_bank = response.data.mbank;
          //console.log(response.data.mbank);
        })
        .catch((error) => {
          this.$swal("Error Fetching Banking Information");
        });
    },

    updateBankInfo() {
      if (
        !this.form.bankName ||
        !this.form.bankAccount ||
        !this.form.accountNo
      ) {
        this.$swal("Please all bank information are mandatroy");
      } else {
        this.$systoken.processing("Processing", "submitBtn", true);
        this.$http
          .post(
            this.$appUrl + "post_bank_info",
            {
              bank_name: this.form.bankName,
              account_name: this.form.bankAccount,
              account_number: this.form.accountNo,
              add_info: this.form.add_info,
            },
            this.$config
          )
          .then((response) => {
            if (response.data.status == 200) {
              this.$swal(response.data.message);
              this.$systoken.processing("Submit", "submitBtn", false);
              this.$router.go();
              this.getBankInfo();
              //this.$router.push({ name: "update_profile" });
             // console.log(response.data.data.message);
            }
          })
          .catch((err) => {
            console.log(err.response.data.errors);
            this.error = err.response.data.errors;
            this.$systoken.processing("Submit", "submitBtn", false);
          });
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 10%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
.pushrigt {
  float: right;
}
</style>