<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <div class="nk-block-head-sub">
                    <router-link class="back-to" to="all_users"
                      ><em class="icon ni ni-arrow-left"></em
                      ><span>Back</span></router-link
                    >
                  </div>
                  <h2 class="nk-block-title fw-normal">Add New Plans</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      Any New Plans Added will be displayed both in the front
                      end and in the backend of the application
                    </p>
                  </div>
                </div>
              </div>

              <!-- .nk-block -->
              <div class="nk-block nk-block-lg">
                <div class="card card-bordered">
                  <div class="card-inner">
                    <div class="card-head">
                      <h5 class="card-title">Plan Setup</h5>
                    </div>
                    <form @submit.prevent="postPlans" class="gy-3">
                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Plan Name</label>
                            <span class="form-note">Select Plan Name</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <select
                                class="form-control"
                                data-search="on"
                                data-select2-id="4"
                                v-model="form.plan_name"
                              >
                                <option value="">Select Plan</option>
                                <option
                                  v-for="plan in valid_plans"
                                  :key="plan.id"
                                  :value="plan"
                                >
                                  {{ plan }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Plan Currencies</label
                            >
                            <span class="form-note">New Available Plans</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="plan_currencies"
                                v-model="form.plan_currencies"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Plan Price</label
                            >
                            <span class="form-note">Whats The Price</span>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="plan_price"
                                v-model="form.plan_price"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label" for="site-name"
                              >Number of Post</label
                            >
                            <span class="form-note"
                              >Free Money/Transaction Post</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                id="plan_price"
                                v-model="form.plan_count"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-center">
                        <div class="col-lg-5">
                          <div class="form-group">
                            <label class="form-label">Allow Plan</label>
                            <span class="form-note"
                              >Enable or disable plans.</span
                            >
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <ul
                            class="
                              custom-control-group
                              g-3
                              align-center
                              flex-wrap
                            "
                          >
                            <li>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="reg-public"
                                  id="reg-enable"
                                  value="1"
                                  v-model="form.status"
                                />
                                <label
                                  class="custom-control-label"
                                  for="reg-enable"
                                  >Active</label
                                >
                              </div>
                            </li>
                            <li>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  class="custom-control-input"
                                  name="reg-public"
                                  id="reg-disable"
                                  value="0"
                                  v-model="form.status"
                                />
                                <label
                                  class="custom-control-label"
                                  for="reg-disable"
                                  >Inactive</label
                                >
                              </div>
                            </li>
                          </ul>
                          <small class="text-danger" v-if="error.status">
                            {{ error.status[0] }}
                          </small>
                        </div>
                      </div>

                      <div class="row g-3">
                        <div class="col-lg-7 offset-lg-5">
                          <div class="form-group mt-2">
                            <button
                              type="submit"
                              class="btn btn-lg btn-primary"
                              id="submitBtn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- card -->
              </div>
              <!-- .nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid_plans: ["Free", "Standard", "Gold", "Platinum"],

      countries: {},
      error: {},
      form: {
        plan_name: "",
        plan_currencies: "",
        plan_price: "",
        status: "",
        plan_count: "",
      },
    };
  },

  mounted() {
    if (!this.$user.loggedIn()) {
      this.$router.push({ name: "login" });
    }
  },

  methods: {
    postPlans() {
      if (
        !this.form.plan_name ||
        !this.form.plan_currencies ||
        !this.form.plan_price
      ) {
        this.$swal("Please select a plan");
      } else {
        this.$systoken.processing("Redirecting...", "submitBtn", true);
        this.$http
          .post(this.$appUrl + "add_plan", this.form, this.$config)
          .then((response) => {
            if (response.data.status == 200) {
              console.log(response.data.data.message);
              this.$swal(response.data.message);
              this.$router.push({ name: "all_users" });
            }
          })
          .catch((err) => {
            //console.log(err.response.data.data);
            this.$systoken.processing("Add Plan", "submitBtn", false);
            this.$swal(err.response.data.data);
            this.error = err.response.data;
          });
      }
    },
  },
};
</script>

<style scoped>
</style>