<template>
  <span v-for="items in user_info" :key="items">
    <slot name="headerInfo" :userItems="items"></slot>
  </span>
</template>

<script>
export default {
  data() {
    return {
      //user_info: ["Administrator", "Nurudeen Abokedi"],
      user_info: [
        {
          type: "",
          name: "",
          email: "",
          lastlogin: "",
          abbr: "",
        },
      ],
      user_button: [],
      user_detail: [],
    };
  },

  mounted() {
    if (this.$user.getUserName()) {
      let userdetails = JSON.parse(this.$user.getUserName());
      const result = [];
      let userRole = "";
      // if (userdetails.role) {
      //   userdetails.role.map((el) => {
      //     userRole = el;
      //   });
      // }
      result.push({
        // type: userRole.charAt(0).toUpperCase() + userRole.slice(1),
         type: userdetails.role[0]['role'].toUpperCase(),
        name: userdetails.name,
        email: userdetails.email,
        lastlogin: userdetails.lastlogin,
        abb: this.getAbbreviation(userdetails.name),
      });
      this.user_info = result;
    } else {
      this.getUserProfileInfo();
    }
  },

  methods: {
    getUserProfileInfo() {
      const result = [];
      let userRole = "";
      this.$http
        .get(this.$appUrl + "profile", this.$config)
        .then(({ data }) => {
          console.log(data.data)
          // if (data.data.role) {
          //   data.data.role.map((el) => {
          //     userRole = el;
          //   });
          // } .charAt(0).toUpperCase() + userRole.slice(1)
          result.push({
            // type: userRole.charAt(0).toUpperCase() + userRole.slice(1),
            type: data.data.role[0]['role'].charAt(0).toUpperCase() + userRole.slice(1),
            name: data.data.name,
            email: data.data.email,
            lastlogin: data.data.lastlogin,
            abb: this.getAbbreviation(data.data.name),
          });

          this.user_info = result;
        })
        .catch();
    },

    getAbbreviation(name) {
      return name
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    },
  },
};
</script>