<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="components-preview wide-md mx-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm">
                <div class="nk-block-head-content">
                  <h2 class="nk-block-title fw-normal">All Currencies</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      Show all currency that the application supports<br />
                      <button class="btn btn-sm btn-primary">View All</button
                      >&nbsp;&nbsp;
                      <router-link to="add_currency">
                        <button class="btn btn-sm btn-secondary">
                          Add New
                        </button>
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>
              <!-- nk-block-head -->
              <div class="nk-block nk-block-lg">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h4 class="nk-block-title">Currency List - With Action</h4>
                    <p>
                      The following lists all the
                      <strong class="text-primary">currencies</strong> with
                      their related symbols
                    </p>
                  </div>
                </div>
                <div class="card card-bordered card-preview">
                  <shimmer v-if="loading" class="paddingLoader"></shimmer>
                  <p class="makeSpace" v-else-if="!loading && error">
                    {{ error }}
                  </p>
                  <p
                    class="makeSpace"
                    v-else-if="
                      !loading && (!allCurrencies || allCurrencies.length === 0)
                    "
                  >
                    No Currency Found
                  </p>

                  <table class="table table-tranx" v-else>
                    <thead>
                      <tr class="tb-tnx-head">
                        <th class="tb-tnx-id"><span class="">#</span></th>
                        <th class="tb-tnx-info">
                          <span class="tb-tnx-desc d-none d-sm-inline-block">
                            <span>Country Name</span>
                          </span>
                          <span class="tb-tnx-date d-md-inline-block d-none">
                            <span class="d-md-none">Date</span>
                            <span class="d-none d-md-block">
                              <span>Currency Code</span>
                              <span>Added By</span>
                            </span>
                          </span>
                        </th>

                        <th class="tb-tnx-amount is-alt">
                          <span class="tb-tnx-total">Date Created</span>
                          <span class="tb-tnx-status d-none d-md-inline-block"
                            >Status</span
                          >
                        </th>
                        <th class="tb-tnx-action">
                          <span>&nbsp;</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        class="tb-tnx-item"
                        v-for="currency in allCurrencies"
                        :key="currency.id"
                      >
                        <td class="tb-tnx-id">
                          <a href="#"
                            ><span>{{ currency.id }}</span></a
                          >
                        </td>
                        <td class="tb-tnx-info">
                          <div class="tb-tnx-desc">
                            <span class="title">{{
                              currency.country_name
                            }}</span>
                          </div>
                          <div class="tb-tnx-date">
                            <span class="date">{{
                              currency.approved_currency
                            }}</span>
                            <span class="date">{{ currency.user_id }}</span>
                          </div>
                        </td>
                        <td class="tb-tnx-amount is-alt">
                          <div class="tb-tnx-total">
                            <span class="amount">{{
                              currency.created_at
                            }}</span>
                          </div>
                          <div class="tb-tnx-status">
                            <span
                              :class="
                                currency.status === 1
                                  ? 'badge badge-dot badge-warning'
                                  : 'badge badge-dot badge-warning'
                              "
                              >{{ currency.status }}</span
                            >
                          </div>
                        </td>
                        <td class="tb-tnx-action">
                          <div class="dropdown">
                            <a
                              class="
                                text-soft
                                dropdown-toggle
                                btn btn-icon btn-trigger
                              "
                              data-toggle="dropdown"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="
                                dropdown-menu
                                dropdown-menu-right
                                dropdown-menu-xs
                              "
                            >
                              <ul class="link-list-plain">
                                <li><a href="#">Remove</a></li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- .card-preview -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .components-preview -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      allCurrencies: [],
      searchCurrencies: "",
      error: null,
    };
  },

  methods: {
    getCurrencies() {
      this.loading = true;
      this.error = null;
      this.$http
        .get(this.$appUrl + "get_currency", this.$config)
        .then(({ data }) => {
          this.loading = false;
          this.allCurrencies = this.$globeFunc.sortArray(data.data);
        })
        .catch((error) => {
          if(error.response.data.message == "noaccess"){
            this.$swal(error.response.data.data);
            this.$router.push({ name: "dashboard" });
          }else{
            this.loading = false;
             this.error =
            "Failed to fetch data, please try again or check your internet";
           this.$swal(error);
          }
        });
    },
  },

  mounted() {
    this.getCurrencies();
  },
};
</script>

<style scoped>
</style>