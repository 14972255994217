<template>
    <main-sidebar></main-sidebar>
  
<div class="nk-wrap">
      <top-header></top-header>
  
      <div class="nk-content">
        <div class="container-fluid">
          <div class="nk-content-inner">
           

            <div class="nk-content-body">
                                <div class="nk-content-wrap">
                                    <div class="nk-block-head nk-block-head-lg">
                                        <div class="nk-block-head-sub"><span>Pricing</span></div>
                                        <div class="nk-block-between-md g-4">
                                            <div class="nk-block-head-content">
                                                <h2 class="nk-block-title fw-normal">Choose Suitable Plan</h2>
                                                <div class="nk-block-des">
                                                    <p>You can change your plan any time by upgrade your plan</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- .nk-block-head -->
                                    <div class="nk-block">
                                        <div class="row g-gs">
                                            <div class="col-md-4">
                                                <div class="price-plan card card-bordered text-center">
                                                    <div class="card-inner">
                                                        <div class="price-plan-media">
                                                            <img src="template/demo1/src/images/icons/plan-s1.svg" alt="">
                                                        </div>
                                                        <div class="price-plan-info">
                                                            <h5 class="title">Standard</h5>
                                                            <span>perfect fit for individuals that want to use payhaven to currency exchange</span>
                                                        </div>
                                                        <div class="price-plan-amount">
                                                            <div class="amount">$79 </div>
                                                            <span class="bill">Billed Monthly</span>
                                                        </div>
                                                        <div class="price-plan-action">
                                                            <profile-header>
                                                            <template v-slot:headerInfo="slotProps">

                                                               <paystack
                                                                buttonClass="button-class btn btn-primary"
                                                                buttonText="Pay Now"
                                                                :publicKey="publicKey"
                                                                :email="slotProps.userItems.email"
                                                                :amount="79 * 100"
                                                                :currency="USD"
                                                                :reference="reference"
                                                                :onSuccess="onSuccessfulPayment"
                                                                :onCanel="onCancelledPayment"
                                                                
                                                                >
                                                                </paystack>
                                                                
                                                                </template>
                                                                </profile-header>
                                                            <!-- <a href="#" class="btn btn-primary" @click="processplan(79)">Select Plan</a> -->
                                                        </div>
                                                    </div>
                                                </div><!-- .price-item -->
                                            </div><!-- .col -->
                                            <div class="col-md-4">
                                                <div class="price-item card card-bordered text-center">
                                                    <div class="card-inner">
                                                        <div class="price-plan-media">
                                                            <img src="template/demo1/src/images/icons/plan-s2.svg" alt="">
                                                        </div>
                                                        <div class="price-plan-info">
                                                            <h5 class="title">Gold</h5>
                                                            <span>Perfect for small business with smaller staff strength</span>
                                                        </div>
                                                        <div class="price-plan-amount">
                                                            <div class="amount">$129 </div>
                                                            <span class="bill">Billed Monthly</span>
                                                        </div>
                                                        <div class="price-plan-action">
                                                            <profile-header>
                                                            <template v-slot:headerInfo="slotProps">
                                                                <paystack
                                                                buttonClass="button-class btn btn-primary"
                                                                buttonText="Pay Now"
                                                                :publicKey="publicKey"
                                                                :email="slotProps.userItems.email"
                                                                :amount="129 * 100"
                                                                :currency="USD"
                                                                :reference="reference"
                                                                :onSuccess="onSuccessfulPayment"
                                                                :onCanel="onCancelledPayment"
                                                                >
                                                                </paystack>
                                                                
                                                                </template>
                                                                </profile-header>
                                                            <!-- <a href="#" class="btn btn-primary" @click="processplan(129)">Select Plan</a> -->
                                                        </div>
                                                    </div>
                                                </div><!-- .price-item -->
                                            </div><!-- .col -->
                                            <div class="col-md-4">
                                                <div class="price-item card card-bordered text-center">
                                                    <div class="card-inner">
                                                        <div class="price-plan-media">
                                                            <img src="template/demo1/src/images/icons/plan-s3.svg" alt="">
                                                        </div>
                                                        <div class="price-plan-info">
                                                            <h5 class="title">Platinum</h5>
                                                            <span>Perfect for big and large business with millions of users</span>
                                                        </div>
                                                        <div class="price-plan-amount">
                                                            <div class="amount">$199</div>
                                                            <span class="bill">Billed Monthly</span>
                                                        </div>
                                                        <div class="price-plan-action">

                                                            <profile-header>
                                                            <template v-slot:headerInfo="slotProps">

                                                                <paystack
                                                                buttonClass="button-class btn btn-primary"
                                                                buttonText="Pay Now"
                                                                :publicKey="publicKey"
                                                                :email="slotProps.userItems.email"
                                                                :amount="199 * 100"
                                                                :currency="USD"
                                                                :reference="reference"
                                                                :onSuccess="onSuccessfulPayment"
                                                                :onCanel="onCancelledPayment"
                                                                >
                                                                </paystack>
                                                                
                                                                </template>
                                                                </profile-header>
                                                    
                                                     <!-- <a href="#" class="btn btn-primary" @click="processplan(199)">Select Plan</a> -->
                                                        </div>
                                                    </div>
                                                </div><!-- .price-item -->
                                            </div><!-- .col -->
                                        </div><!-- .row -->
                                    </div><!-- .nk-block -->
                                    <div class="nk-block">
                                        <div class="card card-bordered">
                                            <table class="table table-features">
                                                <thead class="tb-ftr-head thead-light">
                                                    <tr class="tb-ftr-item">
                                                        <th class="tb-ftr-info">Features</th>
                                                        <th class="tb-ftr-plan">Standard</th>
                                                        <th class="tb-ftr-plan">Gold</th>
                                                        <th class="tb-ftr-plan">Platinum</th>
                                                    </tr><!-- .tb-ftr-item -->
                                                </thead>
                                                <tbody class="tb-ftr-body">
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Free Money/Transacton  Post</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Email Support</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Priority Phone Support</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Live Chart</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni  ni-check-thick"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Video Onboarding</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni  ni-check-thick"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Exclusive Invitation to currency deals</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni  ni-check-thick"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Waiver on Escrow Fee</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni  ni-check-thick"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Prime View in Market Place</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni  ni-check-thick"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                    <tr class="tb-ftr-item">
                                                        <td class="tb-ftr-info">Custom Request</td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-cross"></em> <span class="plan-name">Starter</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Pro</span></td>
                                                        <td class="tb-ftr-plan"><em class="icon ni ni-check-thick"></em> <span class="plan-name">Enterprise</span></td>
                                                    </tr><!-- .tb-ftr-item -->
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                    </div><!-- .nk-block -->
                                </div>
                               
                            </div>


          </div>
        </div>
      </div>
  
      <main-footer></main-footer>
    </div>
  </template>
  
  <script>
  
  import paystack from "vue3-paystack";

  export default {

    name: "Payment",
    components: {
        paystack,
    },

    data() {
      return {
        loading: false,
        plans: [],
        error: null,
        transact: [],
        errors: {},
        amount: 0,
        full_name: "",
        currency: "",
        email: "",
        publicKey: "pk_test_be2605d3d3471e94e1a827e0178c2425010ce08f",
      };
    },

    computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  
    methods: {

        onSuccessfulPayment: (response) => {
        // console.log(response);
      
         window.location.replace(
            "http://localhost:8080/plan_payment_complete/" +
            response.reference + '|' + response.trans + '|' + response.status + '|' + response.message +
            "?ref=" +
            response.reference +
            "&message=" +
            response.message +
            "&status=" +
            response.status +
            "&trxref=" +
            response.trans 
        );
        
   },

    onCancelledPayment: () => {
        alert("Error processing Payment")
      console.log("Payment Cancelled");
    },


    },
  
    mounted() {
        
    },
  };
  </script>
  
  <style scoped>
  </style>