<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content nk-content-lg nk-content-fluid">
      <div class="container-xl wide-lg">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="kyc-app wide-sm m-auto">
              <div class="nk-block-head nk-block-head-lg wide-xs mx-auto">
                <div class="nk-block-head-content text-center">
                  <h2 class="nk-block-title fw-normal">PAYMENT COMPLETE</h2>
                  <div class="nk-block-des">
                    <p>YOU HAVE SUCCESSFULLY COMPLETED YOUR PAYMENT.</p>
                  </div>
                </div>
              </div>

              <!-- nk-block -->
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="nk-kycfm">
                    <!-- nk-kycfm-head -->
                    <div class="nk-kycfm-content">
                      <div class="nk-kycfm-note">
                        <em
                          class="icon ni ni-info-fill"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Tooltip on right"
                        ></em>
                        <p>Thank you for patronizing payhaven</p>
                      </div>

                      <!-- nk-block -->
                      <div class="nk-block">
                        <div class="card card-bordered">
                          <div class="nk-kycfm">
                            <!-- nk-kycfm-head -->
                            <div class="nk-kycfm-content">
                              <div class="row g-4">
                                <div class="col-md-6">
                                  You have Successfully completed your payment
                                </div>
                                <!-- .col -->
                              </div>
                              <!-- .row -->
                            </div>
                          </div>
                          <!-- nk-kycfm -->
                        </div>
                        <!-- .card -->
                      </div>

                      <!-- .row -->
                    </div>
                  </div>
                  <!-- nk-kycfm -->
                </div>
                <!-- .card -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .kyc-app -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errors: {},
    };
  },

  created() {
   // console.log(this.$route.params.reference);
          this.$http
        .post(
          this.$appUrl + "plan_confirm_paystack_payment",
          {
            reference: this.$route.params.reference,
          },
          this.$config
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == 200) {
              this.$swal(response.data.data.message);
              window.location.replace("http://localhost:8080/");
          }else{
             this.$swal(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$swal(err.message);
        });
  },

  methods: {
   
  },
};
</script>

<style scoped>
</style>