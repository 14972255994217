<template>
  <!-- sidebar @s -->
  <div class="nk-sidebar nk-sidebar-fixed is-dark" data-content="sidebarMenu">
    <div class="nk-sidebar-element nk-sidebar-head">
      <div class="nk-sidebar-brand">
        <a href="html/general/index.html" class="logo-link nk-sidebar-logo">
          <img
            class="logo-light logo-img"
            src="template/demo1/src/images/payhaven/logo.png"
            srcset="template/demo1/src/images/payhaven/logo.png"
            alt="logo"
          />
          <img
            class="logo-dark logo-img"
            src="template/demo1/src/images/payhaven/logo.png"
            srcset="template/demo1/src/images/logo-dark2x.png 2x"
            alt="logo-dark"
          />
          <!--<span class="nio-version">General</span>-->
        </a>
      </div>
      <div class="nk-menu-trigger mr-n2">
        <a
          href="#"
          class="nk-nav-toggle nk-quick-nav-icon d-xl-none"
          data-target="sidebarMenu"
          ><em class="icon ni ni-arrow-left"></em
        ></a>
      </div>
    </div>
    <!-- .nk-sidebar-element -->
    <div class="nk-sidebar-element">
      <div class="nk-sidebar-content">
        <div class="nk-sidebar-menu" data-simplebar>
          <ul class="nk-menu">
            <!-- .nk-menu-item -->
            <li class="nk-menu-item">
              <router-link to="/" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-bitcoin-cash"></em
                ></span>
                <span class="nk-menu-text">Dashboard</span>
              </router-link>
            </li>

            <li class="nk-menu-item">
              <router-link to="/payments" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-coins"></em
                ></span>
                <span class="nk-menu-text">Payments</span>
              </router-link>
            </li>
            <!-- .nk-menu-item -->
            <li class="nk-menu-heading">
              <h6 class="overline-title text-primary-alt">Exclusive Panel</h6>
            </li>

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <a
                href="html/subscription/index.html"
                class="nk-menu-link"
                target="_blank"
              >
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-calendar-booking"></em
                ></span>
                <span class="nk-menu-text">Manage Currencies</span>
              </a>
            </li> -->

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <a
                href="html/invest/index.html"
                class="nk-menu-link"
                target="_blank"
              >
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-invest"></em
                ></span>
                <span class="nk-menu-text">My BDC</span>
                <span class="nk-menu-badge bg-danger">New</span>
              </a>
            </li> -->
            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <a
                href="html/apps/messages/index.html"
                class="nk-menu-link"
                target="_blank"
              >
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-chat"></em
                ></span>
                <span class="nk-menu-text">My Messages</span>
              </a>
            </li> -->

            <!-- .nk-menu-item -->

            <li class="nk-menu-item" v-for="ex in mExclusive" :key="ex.id">
              <router-link :to="ex.url" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em :class="ex.menu_icon"></em
                ></span>
                <span class="nk-menu-text">{{ ex.name }}</span>
                <!-- <span class="nk-menu-badge bg-danger">New</span> -->
              </router-link>
            </li>

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <router-link to="sell_currency" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-calendar-booking"></em
                ></span>
                <span class="nk-menu-text">Sell Currency</span>
              </router-link>
            </li> -->
            <!-- .nk-menu-heading -->

            <!-- .nk-menu-item -->
            <li class="nk-menu-heading">
              <h6 class="overline-title text-primary-alt">Main Menu</h6>
            </li>

            <li class="nk-menu-item" v-for="mA in mMainMenu" :key="mA.id">
              <router-link :to="mA.url" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em :class="mA.menu_icon"></em
                ></span>
                <span class="nk-menu-text">{{ mA.name }}</span>
              </router-link>
            </li>

            <!-- <li class="nk-menu-item">
              <a href="exchange_listings" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-help-alt"></em
                ></span>
                <span class="nk-menu-text">Exchange Listings(BDCs)</span>
              </a>
            </li> -->

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item has-sub">
              <router-link
                to="currency_calculator"
                class="nk-menu-link nk-menu-toggle"
              >
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-file-docs"></em
                ></span>
                <span class="nk-menu-text">Currency Calculator</span>
              </router-link>
            </li> -->
            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item has-sub">
              <router-link
                to="all_transactions"
                class="nk-menu-link nk-menu-toggle"
              >
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-tranx"></em
                ></span>
                <span class="nk-menu-text">My Transactions</span>
              </router-link>
              <ul class="nk-menu-sub">
                <li class="nk-menu-item">
                  <a
                    href="html/general/transaction-basic.html"
                    class="nk-menu-link"
                    ><span class="nk-menu-text">Pending</span></a
                  >
                </li>
                <li class="nk-menu-item">
                  <a
                    href="html/general/transaction-crypto.html"
                    class="nk-menu-link"
                    ><span class="nk-menu-text">Tranx List - Crypto</span></a
                  >
                </li>
              </ul>
              
            </li> -->

            <!-- .nk-menu-item -->
            <li class="nk-menu-heading">
              <h6 class="overline-title text-primary-alt">
                Profile Information
              </h6>
            </li>

            <!-- .nk-menu-item -->
            <li class="nk-menu-item has-sub">
              <router-link
                to="update_profile"
                class="nk-menu-link nk-menu-toggle"
              >
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-card-view"></em
                ></span>
                <span class="nk-menu-text">Account</span>
              </router-link>
              <ul class="nk-menu-sub">
                <li class="nk-menu-item">
                  <router-link to="update_profile" class="nk-menu-link"
                    ><span class="nk-menu-text"
                      >Update Account</span
                    ></router-link
                  >
                </li>
              </ul>
              <!-- .nk-menu-sub -->
            </li>

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <a
                href="html/general/components/misc/toastr.html"
                class="nk-menu-link"
              >
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-alert-circle"></em
                ></span>
                <span class="nk-menu-text">Your Reviews</span>
              </a>
            </li> -->
            <!-- .nk-menu-item -->
            <li class="nk-menu-item">
              <a href="contact_us" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-caution"></em
                ></span>
                <span class="nk-menu-text">Contact Us</span>
              </a>
            </li>

            <!-- .nk-menu-item -->
            <li class="nk-menu-heading" v-if="roleType == 'admin'">
              <h6 class="overline-title text-primary-alt">
                Administrative Panel
              </h6>
            </li>

            <li class="nk-menu-item" v-for="ad in mAdmin" :key="ad.id">
              <router-link :to="ad.url" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em :class="ad.menu_icon"></em
                ></span>
                <span class="nk-menu-text">{{ ad.name }}</span>
              </router-link>
            </li>

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <router-link to="view_currencies" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-card-view"></em
                ></span>
                <span class="nk-menu-text">Currencies</span>
              </router-link>
            </li> -->
            <!-- .nk-menu-item -->

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <router-link to="exchange_area" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-card-view"></em
                ></span>
                <span class="nk-menu-text">Exchange Rate(Market)</span>
              </router-link>
            </li> -->
            <!-- .nk-menu-item -->

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <router-link to="all_users" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-alert-circle"></em
                ></span>
                <span class="nk-menu-text">Users</span>
              </router-link>
            </li> -->
            <!-- .nk-menu-item -->

            <!-- <li class="nk-menu-item">
              <router-link to="all_exchange" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-caution"></em
                ></span>
                <span class="nk-menu-text">All Exchanges</span>
              </router-link>
            </li> -->
            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <router-link to="contactus" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-text-rich"></em
                ></span>
                <span class="nk-menu-text">Complaints</span>
              </router-link>
            </li> -->

            <!-- .nk-menu-item -->
            <!-- <li class="nk-menu-item">
              <router-link to="all_transactions" class="nk-menu-link">
                <span class="nk-menu-icon"
                  ><em class="icon ni ni-text-rich"></em
                ></span>
                <span class="nk-menu-text">All Transactions</span>
              </router-link>
            </li> -->
          </ul>
          <!-- .nk-menu -->
        </div>
        <!-- .nk-sidebar-menu -->
      </div>
      <!-- .nk-sidebar-content -->
    </div>
    <!-- .nk-sidebar-element -->
  </div>
  <!-- sidebar @e -->
</template>

<script>

export default {

  data() {
    return {
      allMenus: [],
      error: {},
      mExclusive: [],
      mMainMenu: [],
      mAdmin: [],
      roleType: 'user'
    };
  },

  created() {
    let userdetails = JSON.parse(this.$user.getUserName());
    this.roleType = userdetails.role[0]['role'] 
    
    // console.log(userdetails.role[0]['role']);
    this.exclusiveMenu(userdetails.menus);
    this.mainMenu(userdetails.menus);
    this.administrativeMenu(userdetails.menus);
  },

  methods: {
    administrativeMenu(exMenu) {
      let adminItem = [];
      exMenu.filter((item) => {
        if (item.menu_class == "administrative_panel") {
          adminItem.push(item);
          this.mAdmin = adminItem;
        }
      });
    },

    mainMenu(exMenu) {
      let mitem = [];
      exMenu.filter((item) => {
        if (item.menu_class == "main_menu") {
          mitem.push(item);
          this.mMainMenu = mitem;
        }
      });
    },

    exclusiveMenu(exMenu) {
      let iitem = [];
      exMenu.filter((item) => {
        if (item.menu_class == "exclusive_panel") {
          iitem.push(item);
          this.mExclusive = iitem;
          // console.log(iitem);
        }
      });
    },
  },
};
</script>