class globalFunctions {

    //The Sort Function
     static sortArray(arr) {
      return arr.sort((a, b) => b.id - a.id);
    }


    //Filter Function any value
    static filterValues(arr, value) {
    return arr.filter(item => item.value == value);
  }
  
  static status(stat) {
    if (stat == "initiated") {
      return <span class="btn btn-xs btn-secondary">stat</span>
    }
  }

  static formatMoney = (num) => {
   // return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
    return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
  
  static checkStatus  = (status) => {
    if(status == "Available"){
      return status;
    }else if(status == "Booked"){
      return status;
    }else if(status == "Processing"){
      return status;
    }else if(status == "Completed"){
      return status;
    }else if(status == "Confirmed"){
      return status;
    }else if(status == "Cancelled"){
      return status;
    }else{
      return status;
    }
  }

}

export default globalFunctions;