<template>
    <!--------------------- MODAL START This file is not used yet. We need to find a way to manage this ---------------------------->
    <div>
        <transition name="model">
            <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">{{ title }}</h4>
                        <button @click="closeModal" type="button" class="close">
                          <span area-hidden="true" class="">&times;</span>
                        </button>
                      </div>
                      
                     

                      <div class="modal-body">
                        <div class="form-group">
                          <slot name="slotbody"></slot>
                          
                        </div>
                      </div> 


                    </div>
                  </div>

                </div>
            </div>
        </transition>
      </div>
    <!--------------------- MODAL END ---------------------------->

</template>

<script>

export default {
  emits: ['close-modal'],
  props:{
    title: {
      type: String,
      required: true,
      default: 'Title of the modal'
    },
  },
  
  data() {
    return {
      error: null,
      modalpopup: false,
      comment: '',
    };
  },

  
  mounted() { },

  methods: { 
   
    // openModel(exchangeID, userId){
    //   this.myModal = true;
    //   this.actionButton = 'Insert';
    //   this.dynamicTitle = 'Add Comment';
    //   this.dynamicID = exchangeID;
    //   this.userId = userId
    // },

    closeModal(){
     this.$emit('close-modal', false);
    // this.modalpopup = false;
    }, 

  
  }


}
</script>