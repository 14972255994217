<template>
  <div class="nk-block nk-block-middle nk-auth-body wide-xs">
    <div class="brand-logo pb-4 text-center">
      <a href="https://payhaven.ng" class="logo-link">
        <img
          class="logo-light logo-img logo-img-lg"
          src="template/demo1/src/images/payhaven/logo.png"
          srcset="template/demo1/src/images/payhaven/logo.png"
          alt="PayHaven"
        />
        <img
          class="logo-dark logo-img logo-img-lg"
          src="template/demo1/src/images/payhaven/logo.png"
          srcset="template/demo1/src/images/payhaven/logo.png"
          alt="payhaven-dark"
        />
      </a>
    </div>
    <div class="card card-bordered">
      <div class="card-inner card-inner-lg">
        <div class="nk-block-head">
          <div class="nk-block-head-content">
            <h4 class="nk-block-title">Register</h4>
            <div class="nk-block-des">
              <p>Create New Payhaven Account</p>
            </div>
          </div>
        </div>
        <form action="#" @submit.prevent="register">
          <div class="form-group">
            <label class="form-label" for="name">Full Names</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="name"
              v-model="form.name"
              placeholder="Enter your name"
            />
            <small class="text-danger" v-if="errors.name">
              {{ errors.name[0] }}
            </small>
          </div>
          <div class="form-group">
            <label class="form-label" for="email">Email </label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="email"
              v-model="form.email"
              placeholder="Enter your email address or username"
            />
            <small class="text-danger" v-if="errors.email">
              {{ errors.email[0] }}
            </small>
          </div>
          <div class="form-group">
            <label class="form-label" for="password">Password</label>
            <div class="form-control-wrap">
              <a
                href="#"
                class="form-icon form-icon-right passcode-switch"
                data-target="password"
              >
                <em class="passcode-icon icon-show icon ni ni-eye"></em>
                <em class="passcode-icon icon-hide icon ni ni-eye-off"></em>
              </a>
              <input
                type="password"
                class="form-control form-control-lg"
                id="password"
                placeholder="Enter your passcode"
                v-model="form.password"
              />
              <small class="text-danger" v-if="errors.password">
                {{ errors.password[0] }}
              </small>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-control-xs custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="checkbox"
                v-model="form.agree"
              />
              <label class="custom-control-label" for="checkbox"
                >I agree to Payhaven <a href="#">Privacy Policy</a> &amp;
                <a href="#"> Terms.</a></label
              >
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-lg btn-primary btn-block" id="submitBtn">
              Register
            </button>
          </div>
        </form>
        <div class="form-note-s2 text-center pt-4">
          Already have an account? &nbsp;&nbsp;
          <router-link to="login"><strong>Sign in </strong></router-link>
        </div>
        <div class="text-center pt-4 pb-3">
          <h6 class="overline-title overline-title-sap"><span>OR</span></h6>
        </div>
        <ul class="nav justify-center gx-8">
          <li class="nav-item"><a class="nav-link" href="#">Facebook</a></li>
          <li class="nav-item"><a class="nav-link" href="#">Google</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      form: {
        email: null,
        password: null,
        agree: null,
        name: null,
      },
      errors: {},
    };
  },

  mounted() {
    if (this.$user.loggedIn()) {
      this.$router.push({ name: "dashboard" });
    }
  },

  methods: {
    async register() {
      //this.$systoken.processing("Processing....", "submitBtn", true);
      if (!this.form.name || !this.form.email || !this.form.password) {
        this.$swal("Please make sure all fields are filled");
        this.$systoken.processing("Register", "submitBtn", false);
        return;
      }

      if(!this.form.agree){
        this.$swal("Please you must agree to our Privacy Policy");
        this.$systoken.processing("Register", "submitBtn", false);
        return;
      }
      this.$systoken.processing("Processing...", "submitBtn", true);

      const actionPayload = {
          email : this.form.email,
          password: this.form.password,
          name: this.form.name,
          agree: this.form.agree
      }
       // Post data to the backend and retrieve token if successful
       try{
         await this.$store.dispatch('register', actionPayload, this.$config);
         this.$systoken.processing("Redirecting, please wait....", "submitBtn", false);
         this.$swal("Account Successfully Registered, Check Your Email To Activate");
            this.form.email = "";
            this.form.password = "";
            this.form.name = "";
        // this.$router.push({ name: "thanks" });
         const redirectUrl = "/thanks";
         this.$router.replace(redirectUrl);
      }catch(err) {
        //console.log(err.email[0]);
        //this.$swal(err);
        
        if(err.email[0]){
          this.$swal(err.email[0]);
          this.$systoken.processing("Register", "submitBtn", false);
        }
        if (err.message == "Network Error") {
            this.$swal(
              "Something went wrong, please try again later or check your internet"
            );
            this.$systoken.processing("Register", "submitBtn", false);
        }
        if (err) {
            console.log(err.message);
            this.errors = err.response.data.errors;
            this.$systoken.processing("Register", "submitBtn", false);
          }
      }

      //Post data to backend if everything is good
      //this.$http
      //.get(this.$appUrl + "sanctum/csrf-cookie")
      // .then((response) => {
     /* this.$http
        .post(this.$appUrl + "auth/register", this.form, this.$config)
        .then((response) => {
          if (response.data.status == 200) {
            this.$systoken.processing("Redirecting...", "submitBtn", true);
            this.$swal(
              "Account Successfully Registered, Check Your Email To Activate"
            );
            this.form.email = "";
            this.form.password = "";
            this.form.name = "";
            this.$router.push({ name: "thanks" });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message == "Network Error") {
            this.$swal(
              "Something went wrong, please try again later or check your internet"
            );
            this.$systoken.processing("Register", "submitBtn", false);
          }
          if (err) {
            console.log(err.message);
            this.errors = err.response.data.errors;
            this.$systoken.processing("Register", "submitBtn", false);
          }
        }); */
      //});
    },
  },
};
</script>