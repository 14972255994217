<template>
  <main-sidebar></main-sidebar>

  <div class="nk-wrap">
    <top-header></top-header>

    <div class="nk-content nk-content-lg nk-content-fluid">
      <div class="container-xl wide-lg">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="kyc-app wide-sm m-auto">
              <div class="nk-block-head nk-block-head-lg wide-xs mx-auto">
                <div class="nk-block-head-content text-center">
                  <h2 class="nk-block-title fw-normal">
                    Complete Payment with Paystack
                  </h2>
                  <div class="nk-block-des">
                    <p>
                      One last step to completing your transaction
                      <strong>You are</strong> almost there.
                    </p>
                  </div>
                </div>
              </div>

              <!-- nk-block -->
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="nk-kycfm">
                    <!-- nk-kycfm-head -->
                    <div class="nk-kycfm-content">
                      <div class="nk-kycfm-note">
                        <em
                          class="icon ni ni-info-fill"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Tooltip on right"
                        ></em>
                        <p>
                          To complete your transaction, kindly ensure you select
                          the preferred bank. The bank selected must be able to
                          accept your base currency
                        </p>
                        <br />
                        <p>
                          <img
                            class="logo-img"
                            src="http://localhost:8080/template/src/images/paystack.jpg"
                            alt="logo"
                          />
                        </p>
                      </div>

                      <!-- nk-block -->
                      <div class="nk-block">
                        <div class="card card-bordered">
                          <div class="nk-kycfm">
                            <!-- nk-kycfm-head -->
                            <div class="nk-kycfm-content">
                              <div class="row g-4">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <div class="form-label-group">
                                      <label class="form-label"
                                        >FULL NAME
                                      </label>
                                    </div>
                                    <div class="form-control-group">
                                      <input
                                        v-model="full_name"
                                        required
                                        type="text"
                                        :placeholder="transact.buyer_user_id"
                                        name="uname"
                                        class="form-control form-control-lg"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!-- .col -->
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <div class="form-label-group">
                                      <label class="form-label">EMAIL </label>
                                    </div>
                                    <div class="form-control-group">
                                      <input
                                        v-model="email"
                                        required
                                        type="text"
                                        :placeholder="transact.buyer_email"
                                        name="email"
                                        class="form-control form-control-lg"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!-- .col -->

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <div class="form-label-group">
                                      <label class="form-label"
                                        >CURRENCY (Base Currency)
                                      </label>
                                    </div>
                                    <div class="form-control-group">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        readonly
                                        :placeholder="transact.base"
                                        v-model="currency"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <!-- .col -->
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <div class="form-label-group">
                                      <label class="form-label">AMOUNT </label>
                                    </div>
                                    <div class="form-control-group">
                                      <input
                                        v-model="amount"
                                        type="hidden"
                                        step="0.01"
                                        class="form-control form-control-lg"
                                        :placeholder="transact.amount"
                                        name="amount"
                                        readonly
                                      />
                                      <input
                                        v-model="newamount"
                                        type="float"
                                        class="form-control form-control-lg"
                                        :placeholder="transact.amount"
                                        name="amount"
                                        required
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <!-- .col -->

                                <!-- .col -->
                              </div>
                              <!-- .row -->
                            </div>
                            

                            <!-- nk-kycfm-content -->
                            <paystack
                              buttonClass="button-class btn btn-primary"
                              buttonText="Pay Online Now"
                              :publicKey="publicKey"
                              :email="email"
                              :amount="amount * 100"
                              :currency="currency"
                              :reference="transact.uniq_identify"
                              :onSuccess="onSuccessfulPayment"
                              :onCanel="onCancelledPayment"
                            >
                            </paystack>
                            <!-- nk-kycfm-footer -->
                          </div>
                          <!-- nk-kycfm -->
                        </div>
                        <!-- .card -->
                      </div>

                      <!-- .row -->
                    </div>
                  </div>
                  <!-- nk-kycfm -->
                </div>
                <!-- .card -->
              </div>

              <!-- nk-block -->
            </div>
            <!-- .kyc-app -->
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
import paystack from "vue3-paystack";

export default {
  name: "Payment",
  components: {
    paystack,
  },

  data() {
    return {
      transact: [],
      errors: {},
      amount: 0,
      full_name: "",
      currency: "",
      email: "",
      publicKey: "pk_test_be2605d3d3471e94e1a827e0178c2425010ce08f",
    };
  },

  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },

  created() {
    this.getDetails();
  },

  methods: {
    onSuccessfulPayment: (response) => {
      //console.log(response);
      window.location.replace(
        "https://localhost:8000/payment_complete/" +
          response.reference + '|' + response.trans + '|' + response.status + '|' + response.message +
          "?ref=" +
          response.reference +
          "&message=" +
          response.message +
          "&status=" +
          response.status +
          "&trxref=" +
          response.trans
      );

      // alert(response.reference);
    },

    onCancelledPayment: () => {
      console.log("Payment Cancelled");
    },

    getDetails() {
      this.$http
        .get(
          this.$appUrl + "trans_pay/" + this.$route.params.uniqID,
          this.$config
        )
        .then(({ data }) => {
          console.log(data);
          this.amount = data.data[0].amount;
          this.email = data.data[0].buyer_email;
          this.currency = data.data[0].base;
          this.transact = data.data[0];
        })
        .catch((error) => {
          // console.log(error.message);
          this.errors = error.message;
          this.$swal(this.errors);
        });
    },
  },
};
</script>

<style scoped>
</style>